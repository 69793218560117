import React from "react";
import PropTypes from "prop-types";
import View from "components/comercial/crm/ResultSync.view";

function ResultSync({ logs }) {

  const createdLogs = logs.filter(log => log.type == "CREATED");
  const invalidLogs = logs.filter(log => log.type == "NOT_CREATED");

  return <View createdLogs={createdLogs} invalidLogs={invalidLogs} />;
}

ResultSync.propTypes = {
  logs: PropTypes.array.isRequired
};

export default ResultSync;