import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Main from "components/Main";
import Stores from "components/information/Stores"
import ArissVerify from "components/arris/verify/Verify"
import Support from "components/information/Support";
import ScheduleEmbedded from "components/comercial/schedule/ScheduleEmbedded";
import Validation from "components/arris/binnacles_validation/Validation";
import ResidentValidation from "components/preventive_maintenances/Validation";

import "styles/css/App.css";
import "styles/css/General.css";

const App = () =>
  <div id="app">
    <BrowserRouter>
      <Switch>
        <Route path="/support" component={Support}/>
        <Route path="/stores" component={Stores}/>
        <Route path="/ariss_verify" component={ArissVerify}/>
        <Route path="/binnacles_validation" component={Validation}/>
        <Route path="/preventive_validation" component={ResidentValidation}/>
        <Route path="/schedule_embedded" component={ScheduleEmbedded} />
        <Route path="/" component={Main} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </div>;

App.propTypes = {};

export default App;