import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { craneFamilies } from "components/utils/constants"
import AnalyticList from "components/comercial/analytics/List";
import Dashboard from "components/comercial/analytics/Dashboard";

const AnalyticsView = ({ filters = {}, isMobile = false, onSelectFamilyTab }) => (

  <BrowserRouter basename="/commercial/analytics">

    <div class={`content container-fluid ${!isMobile ? "p-7" : "p-3"}`}
      style={{ maxHeight: "100vh", overflow: "auto" }}>

      {/* Header */}
      <div class={`page-header ${!isMobile && "pt-4"}`}>
        <div class="row align-items-end">
          <div class="col-sm">
            <div className="d-flex justify-content-between">
              <h1 class={`page-header-title ${isMobile && "m-0 p-0"}`}>
                Analítica del cronograma logístico
              </h1>
              {isMobile && (
                <button class={`btn btn-sm btn-primary ml-3`} type="button"
                  style={{ lineHeight: 1.3 }}
                  onClick={() => window.location.replace("/commercial/schedule")}
                >
                  <i class="fas fa-directions mr-1"></i> Ir al cronograma
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mx-1 mb-5">
        <div className="d-flex flex-wrap">
          {craneFamilies.map((family, idx) => {
            const checked = filters.family == family || (family == "Todos" && filters.family == "all");
            return (
              <button type="button" key={idx}
                onClick={() => onSelectFamilyTab(family)}
                // eslint-disable-next-line max-len
                className={`btn btn-sm btn-${checked ? "" : "outline-"}primary ${isMobile ? "ml-1 mt-2 py-1" : "mx-1 py-1"}`}
              >
                {family}
              </button>
            );
          })}
        </div>
      </div>

      {/* List */}
      <div className={`card ${isMobile ? "mb-5" : "p-5"}`}>
        <AnalyticList filters={filters} isMobile={isMobile} />
      </div>

      {/* Dashboard */}
      {/* <Dashboard filters={filters} /> */}

    </div>

  </BrowserRouter>
);

AnalyticsView.propTypes = {
  filters: PropTypes.object,
  isMobile: PropTypes.bool,
  onSelectFamilyTab: PropTypes.func.isRequired
};

export default AnalyticsView;