import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import ModalRoute from "components/helpers/ModalRoute";
import OperationList from "components/binnacle/List";
import OperationDetails from "components/binnacle/Details";
import { Typeahead } from "react-bootstrap-typeahead";
import { uniqBy } from "lodash";

const Operations = ({ 
  craneId, 
  cranes, 
  searchRef,
  search,
  handleSearchByChange,
  handleChange,
  searchBy,
  orders
}) =>
  <BrowserRouter basename="/binnacles">
    <div class="content container-fluid p-7">
    
    {/* Header */}
    <div class="page-header pt-4">
      <div class="row align-items-end">
        <div class="col-sm">
          <h1 class="page-header-title">Bitácora</h1>
        </div>
      </div>
    </div>

    <div className="mb-3 mx-1">
      <div className="row">
        <div className="col-md-6">
          <h4>Registros</h4>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-row justify-content-end align-items-center">
            <div className="form-group mr-3">
              <label htmlFor="searchBySelect">Buscar por:</label>
              <select
                id="searchBySelect"
                className="form-control"
                value={searchBy}
                onChange={handleSearchByChange}
              >
                <option value="Grua">Grúa</option>
                <option value="Obra">Obra</option>
                <option value="Cliente">Cliente</option>
              </select>
            </div>

            <div className="">
              {searchBy === "Grua" ? (
                <Typeahead
                  id="menu"
                  ref={searchRef}
                  placeholder={`Buscar ${searchBy}`}
                  onInputChange={handleChange}
                  labelKey={(crane) => `${crane.series} - ${crane.model} ${crane.number}`}
                  onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].series) : "")}
                  options={[
                    ...cranes
                  ]}
                >
                  <div className="rbt-aux">
                    {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                    {search.length > 0 && (
                      <i
                        className="fa fa-times rbt-close text-danger"
                        style={{ paddingTop: "4px" }}
                        role="button"
                        onClick={() => {
                          searchRef.current.clear();
                          handleChange("");
                        }}
                      ></i>
                    )}
                  </div>
                </Typeahead>
              ) : null}
              {searchBy === "Obra" ? (
                <Typeahead
                  id="menu"
                  ref={searchRef}
                  placeholder={`Buscar ${searchBy}`}
                  onInputChange={handleChange}
                  labelKey={(order) => `${order.buildingName}`}
                  onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].buildingName) : "")}
                  options={uniqBy(orders, "buildingName")}
                >
                  <div className="rbt-aux">
                    {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                    {search.length > 0 && (
                      <i
                        className="fa fa-times rbt-close text-danger"
                        style={{ paddingTop: "4px" }}
                        role="button"
                        onClick={() => {
                          searchRef.current.clear();
                          handleChange("");
                        }}
                      ></i>
                    )}
                  </div>
                </Typeahead>
              ) : null}
              {searchBy === "Cliente" ? (
                <Typeahead
                  id="menu"
                  ref={searchRef}
                  placeholder={`Buscar ${searchBy}`}
                  onInputChange={handleChange}
                  labelKey={(order) => `${order.clientName}`}
                  onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].clientName) : "")}
                  options={uniqBy(orders, "clientName")}
                >
                  <div className="rbt-aux">
                    {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                    {search.length > 0 && (
                      <i
                        className="fa fa-times rbt-close text-danger"
                        style={{ paddingTop: "4px" }}
                        role="button"
                        onClick={() => {
                          searchRef.current.clear();
                          handleChange("");
                        }}
                      ></i>
                    )}
                  </div>
                </Typeahead>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>

    <OperationList 
      craneId={craneId} 
      search={search}
      />

    <ModalRoute 
      path="/:shippingId(\d+)"
      width={1000}
      height={550}
      component={OperationDetails} />

    </div>
  </BrowserRouter>;

Operations.propTypes = {
  searchRef: PropTypes.object,
  craneId: PropTypes.string,
  setCraneId: PropTypes.func,
  cranes: PropTypes.array
};

export default Operations;