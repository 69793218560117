import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useGetCall, usePost } from "seed/api";
import { useSet, useDetail } from "seed/gql";
import { SET_CRM } from "seed/gql/queries";
import swal from "sweetalert";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error"
import View from "components/comercial/crm/Sync.view";

function Sync({ match }) {

  const [logs, setLogs] = useState([]);
  const [cranes, setCranes] = useState([]);
  const [filters, setFilters] = useState({
    "free": "all",
    "dismounting": "all",
    "busy": "all"
  });
  const [typeSelection, setTypeSelection] = useState({});
  const [showResultSync, setShowResultSync] = useState(false);

  const crmId = match.params.crmId;
  const [callCranes, reqCranes] = useGetCall("/crms/get_availables_cranes", { crm_id: crmId }, {
    onCompleted: (data) => {
      setCranes(data);
    },
    onError: (err) => {
      console.error(err);
    }
  })

  const [callUpdate, reqUpdate] = useSet(SET_CRM);

  const [callSync, reqSync] = usePost("/crms/sync_crm_schedule", {
    onCompleted: (data) => {
      setLogs(data)
      setShowResultSync(true);
      callUpdate({ id: crmId, status: "SYNC" });
    },
    onError: (err) => {
      console.error(err);
      swal("No es posible enviar esta grúa",
        "No fue posible enviar esta grúa al cronograma logístico, por favor asegurese que no " +
        "exista ya un cronograma con esta grúa en el periodo de la fecha inicial e intente de nuevo mas tarde",
        "error");
    }
  });

  useEffect(() => {
    callCranes();
  }, [crmId])

  const reqCrm = useDetail(`{
    crm {
      startMonth
      startYear
      months
    }
  }`, crmId);

  if (reqCranes.loading || reqCrm.loading) return <Loading />;
  if (reqCranes.error || reqCrm.error) return <Error />;

  const { crm = {} } = reqCrm.data;
  console.log("crm", crm)


  const onChangeSelect = (craneId, value) => {
    typeSelection[craneId] = value;
    setTypeSelection({...typeSelection});
  }

  const onChangeFilter = (type, value) => {
    const newFilters = {...filters}
    newFilters[type] = value;
    setFilters(newFilters);
  }

  const onSelectCrane = (id) => {
    swal({
      title: "Sincronizar grúa",
      icon: "warning",
      text: "Los datos de la grúa en CRM serán sincronizados en el cronograma logístico " +
        "¿Está seguro que desea continuar?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-primary",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callSync({ 
          crane_id: id, 
          crm_id: crmId, 
          status: typeSelection[id]??"BUSY"
        })
      } 
    })
  }

  const onClose = () => {
    window.location.replace("/commercial/crm");
    setShowResultSync(false);
  }

  return (
    <View
      crm={crm}
      logs={logs}
      cranes={cranes}
      filters={filters}
      showResultSync={showResultSync}
      onChangeFilter={onChangeFilter}
      onClose={onClose}
      onChangeSelect={onChangeSelect}
      onSelectCrane={onSelectCrane}
    />
  );
}

Sync.propTypes = {
  match: PropTypes.object
};

export default Sync;