import React, { useState } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { usePagination, useSet } from "seed/gql";
import { Loading } from "seed/helpers";
import { SET_CRM } from "seed/gql/queries";
import Error from "components/helpers/Error";
import { getCommercialCrmFilter } from "components/utils/filters";
import View from "components/comercial/crm/List.view";

function List({ filters, orderDate }) {

  const pageSize = 40;
  const [pageNum, setPageNum] = useState(1);
  const filterString = getCommercialCrmFilter(filters)

  const [callUpdate, reqUpdate] = useSet(SET_CRM, {
    onCompleted: () => {
      reqCrms.refetch();
    },
    onError: (error) => {
      swal("Error al cambiar estatus de grúa",
        "Ha ocurrido un error inesperado, por favor vuelva a intentar mas tarde",
        "error")
      console.error(error);
    }
  })

  const reqCrms = usePagination(`{
    crmPagination {
      totalPages
      crms {
        clientName
        buildingName
        buildingState
        priceDate
        startMonth
        startYear
        status
        crmId
        craneModel
      }
    }
  }`, pageNum, pageSize, filterString, { orderBy: orderDate + "price_date" })

  if (reqCrms.loading) return <Loading />;
  if (reqCrms.error) return <Error />;

  const { crms = [], totalPages = 0 } = reqCrms.data.crmPagination;
  const onChangePageNum = (page) => setPageNum(page);

  const updateCrmStatus = (event, id) => {
    const value = event.target.value;
    callUpdate({ id: id, status: value })
  }

  return (
    <View
      crms={crms}
      pageNum={pageNum}
      totalPages={totalPages}
      updateCrmStatus={updateCrmStatus}
      onChangePageNum={onChangePageNum}
    />
  );
}

List.propTypes = {};

export default List;