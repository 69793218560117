import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { craneFamilies } from "components/utils/constants"
import WorkedHoursTable from "components/arris_binnacle/analytics/WorkedHoursTable";
import WorkedHoursLineChart from "components/arris_binnacle/analytics/WorkedHoursLineChart";
import ExtraWorkedHoursTable from "components/arris_binnacle/analytics/ExtraWorkedHoursTable";
import ExtraWorkedHoursLineChart from "components/arris_binnacle/analytics/ExtraWorkedHoursLineChart";


const AnalyticsView = ({ filters = {}, grouped, onSelectFamilyTab }) => (
  <BrowserRouter basename="arris_binnacle/analytics">

    <div class="content container-fluid p-7" style={{ maxHeight: "100vh", overflow: "auto" }}>

      {/* Header */}
      <div class="page-header pt-4">
        <div class="row align-items-end">
          <div class="col-sm">
            <div className="d-flex justify-content-between">
              <h1 class="page-header-title">
                Analítica de bitácora
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-1 mb-5">
        <div className="d-flex flex-wrap">
          {craneFamilies.map((family, idx) => {
            const checked = filters.family == family || (family == "Todos" && filters.family == "all");
            return (
              <button type="button" key={idx}
                onClick={() => onSelectFamilyTab(family)}
                // eslint-disable-next-line max-len
                className={`btn btn-sm btn-${checked ? "" : "outline-"}primary mx-1 py-1`}
              >
                {family}
              </button>
            );
          })}
        </div>
      </div>

      <div className="my-5">

        <div className="d-flex my-5" style={{ height: "60vh" }}>
          <div className="card p-5" style={{ width: "50%", border: "1px solid #CDCDCD" }}>
            <WorkedHoursTable filters={filters} grouped={grouped}/>
          </div>
          <div className="card p-5 ml-5" style={{ width: "50%", border: "1px solid #CDCDCD" }}>
            <ExtraWorkedHoursTable filters={filters} grouped={grouped}/>
          </div>
        </div>
        
        <div className="d-flex my-5" style={{ maxHeight: "80vh" }}>
          <div className="card p-5" style={{ width: "50%", border: "1px solid #CDCDCD" }}>
            <WorkedHoursLineChart filters={filters} grouped={grouped}/>
          </div>
          <div className="card p-5 ml-5" style={{ width: "50%", border: "1px solid #CDCDCD" }}>
            <ExtraWorkedHoursLineChart filters={filters} grouped={grouped}/>
          </div>
        </div>
      </div>


    </div>

  </BrowserRouter>
);

AnalyticsView.propTypes = {
  filters: PropTypes.object,
  grouped: PropTypes.string.isRequired,
  onSelectFamilyTab: PropTypes.func.isRequired
};

export default AnalyticsView;