import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import dayjs from 'dayjs';
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import swal from "sweetalert";
import Error from "components/helpers/Error";
import { Loading } from "seed/helpers";
import View from "components/comercial/schedule/Form.view";

function FormSave({ location, isMobile }) {

  const searchRef = useRef(null);
  const formikRef = useRef(null);
  const [search, setSearch] = useState("");
  const [elevations, setElevations] = useState([]);
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.has("startMonth") && params.has("startYear") && params.has("crane")) {
      const startMonth = params.get("startMonth");
      const startYear = params.get("startYear");
      const crane = params.get("crane");
      setSearch(parseInt(crane))

      if (formikRef.current) {
        formikRef.current.setFieldValue("start_date",
          dayjs(`${startYear}-${startMonth}-01`));
      }
    }
  }, [params])

  const [callSave, reqSave] = usePost('/schedules/create_schedule', {
    onCompleted: (data) => {
      swal("Cronograma creado exitosamente", "Se creó la reservación de la grúa correctamente.", "success")
        .then((response) => {
          if (response) {
            window.location.replace("/commercial/schedule");
          }
        })

    },
    onError: (response) => {
      swal("Cronograma no creado",
        "El periodo en el que se requiere esta grua no está totalmente disponible.", "error");
    }
  })

  const qCranes = useQuery(`{
    craneCommercials {
      series
      number
      model
    }
  }`, "in_use=true");

  if (qCranes.loading) return <Loading />;
  if (qCranes.error) return <Error />;

  const cranes = qCranes.data.craneCommercials;

  const handleChange = (value) => setSearch(value);

  const addElevation = () => {
    const newElevations = [...elevations];
    newElevations.push({
      months: null,
      start_date: null
    })
    setElevations(newElevations)
  }

  const onChangeElevation = (value, idx, type = "") => {
    let newElevations = [...elevations];
    if (type == "start_date") {
      newElevations[idx][type] = value;
    } else if (type == "months") {
      newElevations[idx][type] = parseInt(value);
    }
    setElevations(newElevations);
  }

  const onDeleteElevation = (idx) => {
    const newElevations = [...elevations];
    newElevations.splice(idx, 1);
    setElevations(newElevations);
  }

  const onSubmit = (values) => {

    if (search == "" || search == null) {
      swal("Grúa no seleccionada", "Debe seleccionar una grúa para registrar un cronograma.", "error");
      return;
    }

    if (values.months <= 0) {
      swal("Duración inválida", "Los meses de duración debe ser un número mayor que cero.", "error");
      return;
    }

    if (values.months > 120) {
      swal("Duración inválida", "Los meses de duración no pueden supererar los 10 años.", "error");
      return;
    }

    const start_date = values.start_date.toISOString().split('T')[0];
    values.start_month = parseInt(start_date.split("-")[1]);
    values.start_year = parseInt(start_date.split("-")[0]);

    callSave({
      crane_id: search,
      elevations: elevations.map((elevation) => {
        const date = elevation.start_date.toISOString().split('T')[0];
        const year = date.split("-")[0];
        const month = date.split("-")[1];
        return {
          "start_month": parseInt(month),
          "start_year": parseInt(year),
          "months": elevation.months
        }
      }),
      ...values
    })
  }

  return (
    <View
      cranes={cranes}
      search={search}
      isMobile={isMobile}
      formikRef={formikRef}
      elevations={elevations}
      searchRef={searchRef}
      onSubmit={onSubmit}
      handleChange={handleChange}
      addElevation={addElevation}
      onChangeElevation={onChangeElevation}
      onDeleteElevation={onDeleteElevation}
    />
  );
}

FormSave.propTypes = {};

export default FormSave;