import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { useGet } from "seed/api";
import { useHistory } from "react-router";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/comercial/crm/Crm.view";

function Crm() {

  const history = useHistory();
  const [models, setModels] = useState([]);
  const [orderDate, setOrderDate] = useState("-");
  const [filters, setFilters] = useState({
    crane_model: "",
    status: {
      "CLOSED": true,
      "WAITING": true,
      "NEGOTIATION": true,
      "SYNC": true
    }
  });

  const reqModels = useGet('/schedules/get_models', {}, {
    onCompleted: (data) => {
      setModels(data)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  if (reqModels.loading) return <Loading />;
  if (reqModels.error) return <Error />;

  const handleChange = (value) => {
    const newFilters = { ...filters };
    newFilters.crane_model = value;
    setFilters(newFilters);  
  }

  const onChangeStatus = (name, checked) => {
    const newFilters = { ...filters };
    newFilters.status[name] = checked;
    setFilters(newFilters);
  }

  const onClose = () => {
    history.goBack();
    document.location.href = "/commercial/crm";
  }

  return (
    <View
      models={models}
      filters={filters}
      onClose={onClose}
      orderDate={orderDate}
      handleChange={handleChange}
      setOrderDate={setOrderDate}
      onChangeStatus={onChangeStatus}
    />
  );
}

Crm.propTypes = {};

export default Crm;