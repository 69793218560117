import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PaginationFooter from "components/helpers/PaginationFooter";
import NotResults from "components/helpers/NotResults";
import { mapShippingStatusColor } from "components/utils/enum_mapper";
import { countActiveFilters } from "components/utils/filters";

const ShipingList = ({ 
  shippings, 
  isReadOnly,
  statusTranslations,
  statusFilters,
  pageNum = 1, 
  totalPages = 0, 
  onClickPage = () => {}, 
  onDelete, 
  onSetStatus,
  calculateCompletionPercentage
}) => 
  <>
    {countActiveFilters(statusFilters) == 0 || shippings.length == 0
      ? <NotResults />
      : <>
          <div style={{height: "60vh", overflowY: "auto", overflowX: "hidden"}}>
            {shippings.map((shipping) => 
              <div className="row border mx-1" key={shipping.id} style={{ backgroundColor: shipping.isEnabled ? "#eeeeee" : "transparent" }}>
                <div className="col-md-2 p-2">
                  {shipping?.order?.crane ? 
                  <>
                    <b>{shipping?.order?.crane?.series}</b>
                    {" / " + shipping?.order?.crane?.model + " " + shipping?.order?.crane?.number}
                  </>
                  : "SIN ASIGNAR / SIN ASIGNAR"}
                </div>
                <div className="col-md-1 p-2">
                  {(new Date(shipping.deliveryDate)).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                </div>
                {window.location.pathname === "/shippings/enabled" ? 
                  <>
                    <div className="col-md-2 p-2 text-center">
                      {shipping?.order?.buildingName}
                    </div>
                    <div className="col-md-2 p-2 text-center">
                      {`${calculateCompletionPercentage(shipping.contents).toFixed(2)}%`}
                    </div>
                  </>
                : <>
                  <div className="col-md-2 p-2">
                    <div>
                      {shipping.type == "TO_BUILDING" ? "Obra" : "Taller"}
                    </div>
                    <h5>
                      {shipping?.order?.clientName}
                    </h5>
                  </div>
                  <div className="col-md-2 p-2">
                    {shipping.isEnabled ? "N/A" :
                      shipping.company ? shipping.company.name + " - " + shipping.transportPhone + "(" + shipping.transportPlate + ")" : 
                        shipping.transportCarrierName + " - " + shipping.transportPhone + "(" + shipping.transportPlate + ")"}
                  </div>
                  <div className="col-md-1 p-2">
                    {shipping.transportPlate}
                  </div>
                </>}
                <div className="col-md-2 p-2">
                  <div className="d-flex justify-content-center">
                    {isReadOnly ? (
                      <select 
                        className="custom-badge-select border-0" 
                        style={mapShippingStatusColor(shipping.status)}
                        defaultValue={shipping.status} 
                      >
                        <option value={shipping.status}>
                          {statusTranslations[shipping.status] || shipping.status}
                        </option>
                      </select>
                    ) : (
                      <select 
                        className="custom-badge-select border-0" 
                        style={mapShippingStatusColor(shipping.status)}
                        defaultValue={shipping.status} 
                        onChange={(e) => onSetStatus(shipping, e.target.value)}
                        disabled={shipping.status === "CREATED_ENTRY"}
                      >
                        {function(){
                          switch (shipping.status) {
                            case "SECURITY_REVISION":
                              return <>
                                <option value="SECURITY_REVISION">Revisión de seguridad</option>
                                <option value="SECURITY_COMPLETED">Revisión completada</option>
                              </>;
                            case "IN_RECOLLECTION":
                              return <>
                                <option value="IN_RECOLLECTION">En recolección</option>
                                <option value="RECOLLECTED">Recolectado</option>
                              </>;
                            case "SUPERVISOR_REVIEW":
                              return <>
                                <option value="SUPERVISOR_REVIEW">Revisión de supervisor</option>
                                <option value="REVIEWED">Revisado</option>
                              </>;
                            case "IN_PROCESS":
                              return <>
                                <option value="IN_PROCESS">En proceso de carga</option>
                                <option value="COMPLETED">Carga completada</option>
                              </>;
                            case "REVIEWED_WITH_ERRORS":
                              return <>
                                <option value="REVIEWED_WITH_ERRORS">Revisado con errores</option>
                              </>;
                            case "IN_PROCESS_ENTRY":
                              return <>
                                <option value="IN_PROCESS_ENTRY">Entrada en proceso</option>
                                <option value="COMPLETED_ENTRY">Entrada completada</option>
                              </>;
                            default:
                              return <>
                                <option value="CREATED">Creada</option>
                                <option value="SECURITY_COMPLETED">Revisión completada</option>
                                <option value="COMPLETED">Carga completada</option>
                                <option value="FINISHED">En destino</option>
                                <option value="REVIEWED">Revisado</option>
                                <option value="RECOLLECTED">Recolectado</option>
                                <option value="CREATED_ENTRY" disabled={shipping.status === "CREATED_ENTRY"}>Entrada creada</option>
                                <option value="COMPLETED_ENTRY" disabled={shipping.status === "COMPLETED_ENTRY"}>Entrada completada</option>
                              </>;
                          }
                        }()}
                      </select>
                    )}
                  </div>
                </div>
                <div className="col-md-2 p-2">
                  <div className="row">
                    <div className={(isReadOnly ? "ml-9" : "col")}>
                      <Link to={`/${shipping.id}/print`}>
                        <i className="fa fa-print text-success"></i>
                      </Link>
                    </div>
                    {isReadOnly ? null :
                      <>
                        <div className="col">
                          <a onClick={() => {
                              window.location.href = "/binnacles?crane=" + 
                              shipping?.order?.crane?.series + " / " + shipping?.order?.crane?.model + "" + 
                              shipping?.order?.crane?.number
                          }} role="button">
                              <i className="fa fa-clipboard text-warning"></i>
                          </a>
                        </div>
                        <div className="col">
                          <Link to={{pathname: `/${shipping.id}/edit`, state: { shipping }}}>
                            <i className="fa fa-edit"></i>
                          </Link>
                        </div>
                        <div className="col">
                          <a className="text-danger" role="button" onClick={() => onDelete(shipping)}>
                            <i className="fa fa-trash"></i>
                          </a>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
          <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />
        </>
    }
  </>;

ShipingList.propTypes = {
    shippings: PropTypes.array,
    statusFilters: PropTypes.object,
    pageNum: PropTypes.number,
    totalPages: PropTypes.number,
    onClickPage: PropTypes.func,
    onDelete: PropTypes.func,
    onSetStatus: PropTypes.func
};

export default ShipingList;