import React from "react";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

const FormView = ({ error, users, orders, onSubmit }) => (
  <div class="card">

    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">Nuevo Mantenimiento</h3>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik initialValues={{ technician: "", start_date: "", zone: "", order: "" }} onSubmit={onSubmit}>
            {({ setFieldValue }) =>
              <Form>
                <div class="mb-3">
                  {/* Crane */}
                  <div class="form-group">
                    <div>
                      <label class="input-label">Orden</label>
                      <Typeahead
                        id="menu"
                        placeholder="Buscar órden"
                        labelKey={(order) => `${order?.crane?.model} ${order?.crane?.series} - ${order?.crane?.number}` }
                        onChange={(selected) => selected.length > 0 ? setFieldValue("order", selected[0].id) : ""}
                        options={orders}
                        required
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div>
                      <label class="input-label">Técnico asignado</label>
                      <Typeahead
                        id="menu"
                        placeholder="Buscar técnico"
                        labelKey={(user) => `${user.firstName} ${user.lastName}`}
                        onChange={(selected) => selected.length > 0 ? setFieldValue("technician", selected[0].id) : ""}
                        options={users}
                        required
                      />
                    </div>
                  </div>

                  <div class="row mb-3">

                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">Fecha de inicio</label>
                        <Field type="date" required name="start_date" class="form-control"/>
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">Zona de obra</label>
                        <Field as="select" required name="zone" class="form-control">
                            <option value="">Selecciona una zona</option>
                            <option value="NORTH">Norte</option>
                            <option value="CENTRE">Centro</option>
                            <option value="SOUTH">Sur</option>
                            <option value="SHALLOWS">Bajío</option>
                        </Field>
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>

                  </div>

                </div>
                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                <button type="submit" class="btn btn-block btn-primary">Crear mantenimiento</button>
              </Form>}
          </Formik>
        </div>
      </div>
    </div>

  </div>
);

FormView.propTypes = {};

export default FormView;