
function addMonths(date, months) {
    let yearChange = Math.floor((date.month + months - 1) / 12);
    let newMonth = ((date.month + months - 1) % 12) + 1;

    return {
        year: date.year + yearChange,
        month: newMonth
    };
}

function gt(date1, date2) {
    if (date1.year > date2.year) {
        return true;
    } else if (date1.year === date2.year) {
        return date1.month > date2.month;
    } else {
        return false;
    }
}

function lt(date1, date2) {
    if (date1.year < date2.year) {
        return true;
    } else if (date1.year === date2.year) {
        return date1.month < date2.month;
    } else {
        return false;
    }
}

function gte(date1, date2) {
    if (date1.year > date2.year) {
        return true;
    } else if (date1.year === date2.year) {
        return date1.month >= date2.month;
    } else {
        return false;
    }
}

function lte(date1, date2) {
    if (date1.year < date2.year) {
        return true;
    } else if (date1.year === date2.year) {
        return date1.month <= date2.month;
    } else {
        return false;
    }
}

function eq(date1, date2) {
    return date1.month == date2.month && date1.year == date2.year;
}

function difference(date1, date2) {
    let difference = (date2.year - date1.year) * 12 + (date2.month - date1.month);
    return Math.abs(difference);
}


export { addMonths, difference, gt, lt, gte, lte, eq }