import React from "react";
import PropTypes from "prop-types";

const DetailsView = ({ stockGroup }) => (
    <div className="card shadow-lg rounded-lg overflow-hidden">
        <div className="card-header text-white">
            <h3 className="card-header-title text-center">Detalles del Grupo de Piezas</h3>
        </div>

        <div className="card-body p-4">
            <div className="group-info text-center">
                <h4 className="text-secondary">{stockGroup.name}</h4>
                <p className="text-muted">{stockGroup.description}</p>
            </div>

            <div className="crane-info mb-2 p-3 bg-light rounded text-center">
                <h5 className="font-weight-bold">Grúa Asociada:</h5>
                <p>{stockGroup.crane.series} / {stockGroup.crane.model} {stockGroup.crane.number}</p>
            </div>

            <div className="item-images">
                <h5 className="font-weight-bold text-center">Total de Ítems: {stockGroup.items.length}</h5>
                <div className="row mt-3">
                    {stockGroup.items.map((item, index) => (
                        <div key={index} className="col-md-4 mb-3">
                            <div className="card shadow-lg">
                                <img
                                    src={item.item.url}
                                    alt={`Item ${index + 1}`}
                                    className="card-img-top rounded"
                                    style={{ height: "300px", objectFit: "cover" }}
                                />
                                <div className="card-body text-center">
                                    <h6 className="card-title">Imagen {index + 1}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

DetailsView.propTypes = {
    stockGroup: PropTypes.object.isRequired
};

export default DetailsView;
