import React from "react";
import PropTypes from "prop-types";

const ResultSyncView = ({ createdLogs, invalidLogs }) => (
  <div class="card h-100 px-3 py-2">

    <div class="card-header">
      <div className="d-flex align-items-center justify-content-start w-100">
        <h3 class="card-header-title">
          Resultados de la sincronización
        </h3>
      </div>
    </div>

    <div class="card-body pt-2" style={{ maxHeight: "1000px", overflowY: "auto" }}>

      {createdLogs.length > 0 && (
        <div className="my-5">
          <h3 className="mb-3">Sincronizaciones exitosas</h3>
          <ul>
            {createdLogs.map((log, idx) => (
              <li key={idx}>{log?.message ?? ""}</li>
            ))}
          </ul>
        </div>
      )}

      {invalidLogs.length > 0 && (
        <div className="my-5">
          <h3 className="mb-3">Sincronizaciones fallidas</h3>
          <ul>
            {invalidLogs.map((log, idx) => (
              <li key={idx}>{log?.message ?? ""}</li>
            ))}
          </ul>
        </div>
      )}

    </div>

  </div>
)

ResultSyncView.propTypes = {
  createdLogs: PropTypes.array.isRequired,
  invalidLogs: PropTypes.array.isRequired
};

export default ResultSyncView;