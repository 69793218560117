import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/orders/PlansDetails.view";
import { usePost } from "seed/api";

const PlansDetails = ({ orderId }) => {
  const [orders, setOrders] = useState([]);
  const imageExtensions = ["jpeg", "jpg", "png", "heic"];
  const videoExtensions = ["mp4", "webm", "ogg", "mkv"];

  const qPlans = useQuery(
    `{
      orders {
        id
        plans {
            id
            name
            url
        }
      }
    }
    `,
    "id = " + orderId
  );

  const [callDeletePlan, qDeletePlan] = usePost("/orders/delete_plans", {
    onCompleted: () => {
        qPlans.refetch();
    },
  });

  useEffect(() => {
    if (!qPlans.loading && !qPlans.error) {
      setOrders(qPlans.data.orders);
    }
  }, [qPlans.loading, qPlans.error, qPlans.data]);
  

  if (qPlans.loading) return <Loading />;
  if (qPlans.error) return <Error />;

  const handleDeletePlan = (orderId, planIndex) => {
    const variables = {
        order_id: orderId,
        plan_id: parseInt(planIndex),
    };
    callDeletePlan(variables);
  };

  const displayFile = (fileUrl, fileName, noOfElement) => {
  
    let splittedPath = fileUrl.split(".");
    let fileExtension = splittedPath[splittedPath.length - 1].toLowerCase();
  
    if (imageExtensions.includes(fileExtension)) {
        return (
            <tr className="text-center">
                <div className="d-flex justify-content-center">
                    <img src={fileUrl} alt={fileName} height="300px" />
                </div>
            </tr>
          );
    } else if (videoExtensions.includes(fileExtension)) {
        return (
            <tr className="text-center">
              <td>Video {noOfElement + "." + fileExtension}</td>
              <td>
                <video height="400px" controls style={{ objectFit: "fill" }} muted>
                  <source src={fileUrl} type={"video/" + fileExtension} />
                </video>
              </td>
            </tr>
          );
    } else {
        return (
            <tr className="text-center">
              <td className="border-0">
                <a href={fileUrl}>
                  Archivo {noOfElement + "." + fileExtension}
                </a>
              </td>
            </tr>
          );
    }
  };

  return <View 
            orders={orders} 
            imageExtensions={imageExtensions}
            videoExtensions={videoExtensions}
            displayFile={displayFile} 
            handleDeletePlan={handleDeletePlan}
        />;
};

PlansDetails.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default PlansDetails;