import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";

const FormAssignView = ({ 
    users, 
    onSubmit,
    error,
    technician={}
}) => (
 <div class="card">
    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">{technician.id ? "Reasignación de técnico" : "Asignación técnico"}</h3>
    </div>
    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik initialValues={{ technician: { id: technician.id } }} onSubmit={onSubmit}>
            {({ values, setFieldValue }) =>
              <Form>
                <div class="mb-3">
                  <div class="form-group">
                    <div>
                      <label class="input-label">Buscar técnico</label>
                      <Field component="select"
                        id="menu"
                        name="technician.id"
                        class="form-control" required
                      >
                        <option value="">Seleccionar técnico</option>
                        {users.map(user => <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>)}
                      </Field>
                    </div>
                  </div>
                </div>
                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                <button type="submit" class="btn btn-block btn-primary">Asignar técnico</button>
              </Form>}
          </Formik>
        </div>
      </div>
    </div>

  </div>
);

FormAssignView.propTypes = {
    onSubmit: PropTypes.func
};

export default FormAssignView;