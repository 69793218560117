import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";

const CompanyFormView = ({
    onSubmit,
    company = {},
}) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-header-title">{company.id ? 'Editar compañía' : 'Nueva compañía'}</h3>
    </div>
    {console.log(company)}
    <div className="card-body">
      <div className="row">
        <div className="col-md-12">
          <Formik 
            initialValues={{ 
              ...company,
            }} 
            onSubmit={onSubmit}>
            {({ values }) => (
              <Form>
                <div className="mb-3">
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group form-group-default required">
                        <label className="control-label">Nombre de la compañía</label>
                        <Field 
                          type="text" 
                          name="name" 
                          id="name" 
                          className="form-control" 
                          required
                        />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group form-group-default required">
                        <label className="control-label">Razón Social</label>
                        <Field 
                          type="text" 
                          name="socialReason" 
                          id="socialReason" 
                          className="form-control" 
                          required
                        />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group form-group-default required">
                        <label className="control-label">RFC</label>
                        <Field 
                          type="text" 
                          name="rfc" 
                          id="rfc" 
                          className="form-control" 
                          required
                        />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  </div>

                </div>
                <button type="submit" className="btn btn-block btn-primary">
                  {company.id ? 'Actualizar' : 'Crear'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </div>
);

CompanyFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  company: PropTypes.object,
};

export default CompanyFormView;
