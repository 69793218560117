import React, { useState } from "react";
import PropTypes from "prop-types";
import { craneFamilies } from "components/utils/constants"
import View from "components/comercial/analytics/Analytics.view";

function Analytics() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 || window.innerHeight <= 450);
  const [filters, setFilters] = useState({
    family: "all"
  });

  const onSelectFamilyTab = (family) => {
    let newFilters = { ...filters };
    newFilters.family = family == "Todos" ? "all" : family
    setFilters(newFilters);
  }

  return <View filters={filters} onSelectFamilyTab={onSelectFamilyTab} isMobile={isMobile}/>
}

Analytics.propTypes = {};

export default Analytics;