import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

const BinnacleReportView = ({
    binnacleTokens,
    statusFilters
}) => 
<>
  <div style={{ height: "65vh", overflowY: "auto", overflowX: "hidden" }}>
    {binnacleTokens.map((token, idx) => (
      <div
        className="row border mx-1 text-reset"
        key={token.id}
        style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}
      >
        <div className="col-md-1 p-2 text-center">
          {moment.utc(token.createdAt).local().format("DD/MM/YYYY")}
        </div>
        <div className="col-md-2 p-2 text-center">
          {token?.tokenIdArrisBinnacles[0]?.operator?.order?.crane?.model} -
          {token?.tokenIdArrisBinnacles[0]?.operator?.order?.crane?.series}
        </div>
        <div className="col-md-2 p-2 text-center">
          {token?.tokenIdArrisBinnacles[0]?.operator?.user?.firstName}
        </div>
        <div className="col-md-3 p-2 text-center">
          {token?.tokenIdArrisBinnacles[0]?.operator?.order?.buildingName}
        </div>
        <div className="col-md-2 p-2 text-center">
          <div
            className="custom-badge"
            style={(function () {
              if (token.status == "APPROVED")
                return { backgroundColor: "#28a745" };
              if (token.status == "PENDING")
                return { backgroundColor: "#007bff" };
              if (token.status == "REJECTED")
                return { backgroundColor: "#dc3545" };
            })()}
          >
            {(function () {
              if (token.status == "APPROVED") return "Revisado";
              else if (token.status == "PENDING") return "Pendiente";
              else if (token.status == "REJECTED") return "Rechazado";
            })()}
          </div>
        </div>
        <div className="col-md-2 p-2 text-center">
          <Link to={`/details/${token.id}`} className="btn-secondary px-3 py-1">
            Ver detalles
          </Link>
        </div>
      </div>
    ))}
  </div>
</>;

BinnacleReportView.propTypes = {
  binnacleTokens: PropTypes.array,
  statusFilters: PropTypes.string
};

export default BinnacleReportView;