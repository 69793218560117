import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/arris_binnacle/reports/List.view";

const getFilterString = (data) => {

  const crane = data.crane;
  const owners = data.owner;

  let ownerFilters = [];
  let filters = ["in_use=true"];

  if (owners.GROKE == true) {
    ownerFilters.push(`owner=GROKE`);
  }
  if (owners.RENTED == true) {
    ownerFilters.push(`owner=RENTED`);
  }
  if (owners.SOLD == true) {
    ownerFilters.push(`owner=SOLD`);
  }
  if (crane != null && typeof crane == 'number') {
    filters.push(`id=${crane}`);
  }

  if (ownerFilters.length > 0) {
    if (ownerFilters.length >= 2) {
      filters.push("(" + ownerFilters.join(" OR ") + ")");
    } else {
      filters.push(ownerFilters.join(" OR "));
    }
  }

  return filters.join(" AND ");
}

const CraneList = ({ search, filters, frecuency }) => {

  const filterString = getFilterString({ "crane": search, "owner": filters["owner"] })
  const reqCranes = useQuery(`{
      cranes {
        craneId
        model
        number
        series
        inUse
        owner
        status
      }
    }`,
    filterString,
    { orderBy: "owner" }
  );

  if (reqCranes.loading) return <Loading />;
  if (reqCranes.error) return <Error />;

  const cranes = reqCranes.data.cranes;
  const filteredCranes = filters["family"] == "all" ? cranes :
    cranes.filter((crane) => crane.model.startsWith(filters["family"]));


  return <View cranes={filteredCranes} />;

}

CraneList.propTypes = {
  search: PropTypes.string,
  statusFilters: PropTypes.object,
  filters: PropTypes.object,
  inUseFilters: PropTypes.object,
  orderStatusFilters: PropTypes.object,
  qCranes: PropTypes.object
};

export default CraneList;