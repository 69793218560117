import React from "react";
import PropTypes from "prop-types";

const DetailsView = ({
    arrisBinnacles,
    allApprovedOrRejected,
}) => (
  <div className="card">
    <div className="card-body">
      <div className="text-center">
        <h1 class="card-title">Validación horas trabajadas</h1>
        <p>
          Operador: {arrisBinnacles[0].operator.user.firstName}
          <br />
          Grúa: {arrisBinnacles[0].operator.order.crane.model} -
          {arrisBinnacles[0].operator.order.crane.series}
          <br />
          Cliente: {arrisBinnacles[0].operator.order.clientName}
          <br />
          Obra: {arrisBinnacles[0].operator.order.buildingName}
        </p>

        <div className="mt-3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Horas Trabajadas</th>
                <th scope="col">Horas de Comida</th>
                <th scope="col">Horas en Falla</th>
              </tr>
            </thead>
            <tbody>
              {arrisBinnacles.map((arrisBinnacle, index) => (
                <tr key={index}>
                  <td>{arrisBinnacle.date}</td>
                  <td>{arrisBinnacle.hoursWorked}</td>
                  <td>{arrisBinnacle.eatHours}</td>
                  <td>
                    {arrisBinnacle.failuresHours
                      ? arrisBinnacle.failuresHours
                      : "-"}
                  </td>
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td>
                  {arrisBinnacles.reduce(
                    (total, binnacle) => total + parseInt(binnacle.hoursWorked),
                    0
                  )}
                </td>
                <td>
                  {arrisBinnacles.reduce(
                    (total, binnacle) => total + parseInt(binnacle.eatHours),
                    0
                  )}
                </td>
                <td>
                  {arrisBinnacles.reduce(
                    (total, binnacle) =>
                      total +
                      (binnacle.failuresHours
                        ? parseInt(binnacle.failuresHours)
                        : 0),
                    0
                  ) || "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

DetailsView.propTypes = {
    arrisBinnacles: PropTypes.array,
    allApprovedOrRejected: PropTypes.bool,
};

export default DetailsView;
