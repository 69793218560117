import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import Advice from "components/helpers/Advice";
import "styles/css/Form.css";

const craneModels = [
  "5LC5010",
  "5LC3510",
  "5LC4510",
  "5LC5211",
  "LC5211",
  "10LC110",
  "10LC140",
  "10LC1044",
  "LC1044",
  "11LC132",
  "11LC160",
  "21LC170",
  "LC2070",
  "LC2074",
  "21LC210",
  "21LC290",
  "21LC400",
  "21LC450",
  "21LC550",
  "21LC660",
  "21LC750"
];

const onChangeModel = (selected, setFieldValue) => {
  if (selected.length === 0) return
  let model = (selected[0].label ?? selected[0]).toUpperCase().replace(/\s/g, "")
  setFieldValue("model", model)
}

const CraneForm = ({ crane = {}, onSubmit, onClose }) =>
  <div class="card h-100">

    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">{crane.id ? "Editar grúa" : "Nueva grúa"}</h3>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik
            initialValues={crane}
            onSubmit={onSubmit}>
            {({ values, setFieldValue }) =>
              <Form>

                <div class="mb-3">

                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">Modelo</label>
                        <Typeahead
                          id="model"
                          allowNew={true}
                          required
                          class="form-control"
                          placeholder="Selecciona un modelo"
                          options={craneModels}
                          defaultSelected={values.model ? [values.model] : []}
                          onInputChange={(selected) => onChangeModel(selected, setFieldValue)}
                          onChange={(selected) => onChangeModel(selected, setFieldValue)}
                        />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">Tonelaje</label>
                        <Field type="text" name="number" id="number" class="form-control"
                          placeholder="Ej. 5T" required />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">Serie</label>
                        <Field type="text" name="series" id="series" class="form-control" required />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">ID Groke</label>
                        <Field type="text" name="craneId" id="craneId" required
                          class="form-control" placeholder="Ej. GG-123" />
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group form-group-default required">
                        <label class="control-label">Propietario</label>
                        <Field as="select" name="owner" class="form-control" required>
                          <option value="">Seleccione una opción</option>
                          <option value="GROKE">Groke</option>
                          <option value="RENTED">Rentada</option>
                          <option value="SOLD">Vendida</option>
                        </Field>
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <button type="submit" class="btn btn-block btn-primary">
                      {crane.id ? "Actualizar grúa" : "Crear grúa"}
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button type="button" class="btn btn-block btn-secondary" onClick={onClose}>Cancelar</button>
                  </div>
                </div>

              </Form>}
          </Formik>
        </div>
      </div>
    </div>

  </div>;

CraneForm.propTypes = {
  crane: PropTypes.object,
  count: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  error: PropTypes.string,
  step: PropTypes.number,
  onClickRetry: PropTypes.func,
  editing: PropTypes.bool
};

export default CraneForm;
