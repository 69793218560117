import React from "react";
import PropTypes from "prop-types";
import NotResults from "components/helpers/NotResults";
import { Link } from "react-router-dom";
import { countActiveFilters } from "components/utils/filters";
import { mapCraneOwner, } from "components/utils/enum_mapper";

const CraneList = ({ cranes, onDelete }) =>
  <>
    {
      cranes.length == 0
        ? <NotResults />
        : <>
          <div style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }}>
            {cranes.map((crane, idx) =>
              <div
                key={crane.id}
                className="row border mx-1"
                style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}
              >
                <div className="col-md-4 p-2">
                  <b>{crane.series}</b>{" / " + crane.model + "" + crane.number}
                </div>

                <div className="col-md-4 p-2 text-center">
                  {mapCraneOwner(crane.owner)}
                </div>

                <div className="col-md-2 p-2">
                  <div className="row">
                    <div className="col">
                      <Link to={{ pathname: `/${crane.id}/edit` }}>
                        <i className="fa fa-edit"></i> Editar
                      </Link>
                    </div>
                    <div className="col">
                      <a className="text-danger" role="button" onClick={() => {
                        onDelete(crane)
                      }}>
                        <i className="fa fa-trash"></i> Eliminar
                      </a>
                    </div>
                  </div>

                </div>

              </div>
            )}
          </div>
        </>
    }
  </>;

CraneList.propTypes = {
  cranes: PropTypes.array,
  onDelete: PropTypes.func,
};

export default CraneList;