import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PreventiveMaintenancesPending = ({ preventiveMaintenances, zones, getDaysUntilMaintenance }) => {
    const [showAssignedOnly, setShowAssignedOnly] = useState(false);

    const calculateCardHeight = () => {
        const availableHeight = window.innerHeight * 1.35; 
        const cardHeight = availableHeight / zones.length;
        return cardHeight;
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col text-right">
                    <div className="form-check">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            checked={showAssignedOnly} 
                            onChange={() => setShowAssignedOnly(!showAssignedOnly)} 
                            id="assignedOnlyCheck"
                        />
                        <label className="form-check-label" htmlFor="assignedOnlyCheck">
                            Mostrar solo asignados
                        </label>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                {zones.map((zone, index) => {
                    let maintenances = preventiveMaintenances
                        .filter(pm => pm.zone === zone && (!showAssignedOnly || pm.technician))
                        .sort((a, b) => getDaysUntilMaintenance(a.startDate) - getDaysUntilMaintenance(b.startDate));

                    return (
                        <div className="col-md-6 d-flex align-items-stretch" key={zone} style={{ marginBottom: "2vh", height: calculateCardHeight() }}>
                            <div className="card mb-2 shadow-lg" style={{ margin: "2vh 2vh", width: "100%", overflowY: "auto" }}>
                                <div className="card-header font-weight-bold">
                                    <h3>Zona {zone === "SHALLOWS" ? "Bajío" : zone === "CENTRE" ? "Centro" : zone === "NORTH" ? "Norte" : "Sur"}</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row font-weight-bold mx-1">
                                        <div className="col-12 col-md-3 p-2">Grúa</div>
                                        <div className="col-12 col-md-4 p-2 text-center">Obra</div>
                                        <div className="col-12 col-md-3 p-2 text-center">Días para mantenimiento</div>
                                    </div>
                                    {maintenances.length > 0 ? (
                                        maintenances.map((maintenance, idx) => {
                                            const daysUntilMaintenance = getDaysUntilMaintenance(maintenance.startDate);
                                            const daysStyle = {
                                                color: daysUntilMaintenance <= 0 ? 'red' : '#FFAD01'
                                            };
                                            return (
                                                <div className="row border mx-1 text-reset" key={maintenance.id} style={{ background: idx % 2 === 0 ? "#fff" : "#f5f5f5" }}>
                                                    <div className="col-12 col-md-3 p-2">
                                                        {maintenance.order.crane.series + " / " + maintenance.order.crane.model + maintenance.order.crane.number}
                                                    </div>
                                                    <div className="col-12 col-md-4 p-2 text-center">
                                                        {maintenance.order.buildingName}
                                                    </div>
                                                    <div className="col-12 col-md-3 p-2 text-center" style={daysStyle}>
                                                        {daysUntilMaintenance}
                                                    </div>
                                                    <div className="col-12 col-md-2 p-2 text-center">
                                                        {maintenance.technician ? (
                                                            <>
                                                                <Link to={{ pathname: "/reassign/" + maintenance.id }} className="btn btn-secondary">
                                                                    Reasignar
                                                                </Link>
                                                                <div className="mt-1">
                                                                    <h6>Asignado a: 
                                                                        <span className="text-primary">
                                                                            {" " + maintenance.technician.firstName + " " + maintenance.technician.lastName}
                                                                        </span>
                                                                    </h6>
                                                                </div>
                                                            </>
                                                        ) : maintenance.status === "CREATED" ? 
                                                            <Link to={{ pathname: "/assign/" + maintenance.id }} className="btn btn-primary">
                                                                Asignar
                                                            </Link>
                                                        : maintenance.status === "IN_PROGRESS" &&
                                                            <div className="btn btn-danger" style={{ cursor: "not-allowed" }}>
                                                                En proceso
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="row border mx-1 text-reset" style={{ background: "#fff" }}>
                                            <div className="col p-2 text-center">
                                                No hay mantenimientos pendientes para esta zona.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

PreventiveMaintenancesPending.propTypes = {
    preventiveMaintenances: PropTypes.array,
    zones: PropTypes.array.isRequired,
    getDaysUntilMaintenance: PropTypes.func
};

export default PreventiveMaintenancesPending;
