import React from "react";
import PropTypes from "prop-types";
import View from "components/preventive_maintenances/pending/FormAssign.view";
import { useHistory } from "react-router-dom";
import { useDetail, useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import swal from "sweetalert";

const FormAssign = ({ preventiveMaintenanceId }) => {
    
    const history = useHistory();

    const [assignUser, setAssignUser] = usePost("/preventive_maintenances/assign_technician", {
        onCompleted: (data) => {
            if(data.value_response == -1){
                swal("Error", "No hay un operador asignado a la orden.", "error").then(() => {
                    window.location.reload();
                });
            }else if(data.value_response == 1){
                history.goBack();
                swal("¡Éxito!", "Técnico asignado correctamente.", "success").then(() => {
                    window.location.reload();
                });
            }
        },
        onError: () => {
            swal("Error", "Error al asignar el técnico", "error");
        }
    });

    const qUsers = useQuery(`{
        users{
            firstName
            lastName
        }
    }`, "rol=TECHNICIAN");

    const qPreventiveMaintenance = useDetail(`
    {
        preventiveMaintenance{
            zone
            status
            startDate
            order{
                buildingName
                crane{
                    model
                    series
                    number
                }
            }
            technician{
                firstName
                lastName
            }
        }
    }`, preventiveMaintenanceId);
    
    if (qPreventiveMaintenance.loading) return <Loading />;
    if (qPreventiveMaintenance.error) return "Error";
    if (qUsers.loading) return <Loading />;
    if (qUsers.error) return "Error";

    const { users=[] } = qUsers.data;

    const onSubmit = (values) => {
        values.maintenance_id = preventiveMaintenanceId;
        values.technician_id = values.technician.id;
        assignUser(values);
    };
    
    return <View onSubmit={onSubmit} users={users}/>;

};

FormAssign.propTypes = {
    preventiveMaintenanceId: PropTypes.number.isRequired
};

export default FormAssign;
