import React from "react";
import PropTypes from "prop-types";

const DataBIView = ({ urlBI, containerRef }) => {
    const iframeHeight = window.innerWidth >= "768" ? `199%` : `50%`;

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f7f7f7"
        }}>
            <div
                ref={containerRef}
                style={{
                    width: "110vw",
                    height: "100vh",
                    backgroundColor: "#f7f7f7",
                    overflowY: "auto"
                }}>
                <iframe
                    className="mx-auto"
                    style={{
                        width: "100%",
                        border: "none",
                        overflowY: "auto",
                        background: "#f7f7f7",
                        height: iframeHeight,
                    }}
                    title="Panel"
                    src={`${urlBI}&language=es-MX&hideToolbar=true&filterPaneEnabled=false&navContentPaneEnabled=false`}
                />
            </div>
        </div>
    );
};

DataBIView.propTypes = {
    urlBI: PropTypes.string.isRequired
};

export default DataBIView;
