import React from "react";
import PropTypes from "prop-types";
import { Loading } from "seed/helpers";
import View from "components/security_revisions/Details.view";
import { useQuery } from "seed/gql";

const SecurityRevisionDetails = ({ securityRevisionId, onCompleted = () => null, onError = () => null }) => {
  const [selectedEvidences, setSelectedEvidences] = React.useState(null);
  const contDesv = [];

  let initialRevision = 0;
  let finalRevision = 0;

  if (securityRevisionId.includes("-")) {
    [initialRevision, finalRevision] = securityRevisionId.split("-");
  } else {
    initialRevision = securityRevisionId;
    finalRevision = securityRevisionId;
  }

  const reqSecurityRevision = useQuery(`{
    securityRevisions {
      craneModel
      type
      order {
        buildingName
      }
      securityChecks {
        deviationValue
        value
        isDeviation
        findings
        corrections
        scpLiberation
        files {
          id
          name
          url
          createdAt
        }
        securityCheckType {
          number
          name
          securityCategory {
            name
            partId
          }
        }
      }
    }
  }`, `id = ${parseInt(initialRevision)} OR id = ${parseInt(finalRevision)}`);

  if (reqSecurityRevision.loading) return <Loading />;
  if (reqSecurityRevision.error) return "Error";

  const revisions = reqSecurityRevision.data.securityRevisions;
  if (!revisions.length) return "No se encontraron revisiones de seguridad.";

  const initialRevisionType = revisions.find(revision => revision.type === "INITIAL");
  const finalRevisionType = revisions.find(revision => revision.type === "FINAL");

  const initialRevisionChecks = revisions.find(revision => revision.type === "INITIAL")?.securityChecks || [];
  const finalRevisionChecks = revisions.find(revision => revision.type === "FINAL")?.securityChecks || [];

  const getCategoryGroups = (checks) => {
    const categoryGroupsObject = {};
    checks.forEach(check => {
      const categoryId = check?.securityCheckType?.securityCategory.id;
      if (!categoryGroupsObject[categoryId]) {
        categoryGroupsObject[categoryId] = {
          name: check?.securityCheckType?.securityCategory?.name || 'Unknown', 
          partId: check?.securityCheckType?.securityCategory?.partId || '', 
          checks: []
        };
      }
      categoryGroupsObject[categoryId].checks.push(check);
    });
    return Object.values(categoryGroupsObject);
  };
  

  const initialRevisionCategoryGroups = getCategoryGroups(initialRevisionChecks);
  const finalRevisionCategoryGroups = getCategoryGroups(finalRevisionChecks);

  const initialMessage = initialRevisionType && !initialRevisionType.securityChecks.length
    ? "Revisión de electromecánica no iniciada"
    : null;

  const finalMessage = finalRevisionType && !finalRevisionType.securityChecks.length
    ? "Revisión de estructura y componentes no iniciada"
    : null;

  return (
    <View
      contDesv={contDesv}
      finalMessage={finalMessage}
      initialMessage={initialMessage}
      selectedEvidences={selectedEvidences}
      finalRevisionType={finalRevisionType}
      initialRevisionType={initialRevisionType}
      setSelectedEvidences={setSelectedEvidences}
      craneModel={revisions[0]?.craneModel || ""}
      buildingName={revisions[0]?.order?.buildingName || ""}
      finalRevisionCategoryGroups={finalRevisionCategoryGroups}
      initialRevisionCategoryGroups={initialRevisionCategoryGroups}
    />
  );
};

SecurityRevisionDetails.propTypes = {
  securityRevisionId: PropTypes.string,
  onCompleted: PropTypes.func,
  onError: PropTypes.func
};

export default SecurityRevisionDetails;
