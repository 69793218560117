import React, { useState } from "react";
import PropTypes, { func } from "prop-types";
import View from "components/shippings/DownloadReport.view";
import moment from "moment";
import { downloadCSV } from "components/utils/file";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

const DownloadReport = ({ onCompleted = () => null, setCreated }) => {

  const reqShippings = useQuery(`{
    shippings {
        createdAt
        truckPlate
        transportPlate
        transportCarrierName
        transportCompany
        company{
          rfc
          name
          socialReason
        }
        type
        status
        isEnabled
        deliveryDate
        order {
            clientName
            buildingName
            buildingAddress
            contactName
            contactPhone
            contactEmail
            feetModel
            diceSize
            requestingTrader
            orderVersion
            mountingDate
            rentPeriod
            elevationsNumber
            boomLength
            towerHeight
            finalHeight
            insuranceResponsable
            status
            crane {
                model
                series
            }
        }
    }
  }`);

  if (reqShippings.loading) return <Loading />;
  if (reqShippings.error) return "Error";

  const { shippings = [] } = reqShippings.data;

  console.log(shippings);

  const generateReport = (values) => {
    let headers = ["Fecha", "Fecha estimada", "Modelo de grúa", "Serie de grúa", "Placa de grúa", "Placa de transporte",
    "Transportista", "Empresa de transporte", "RFC", "Razon Social", "Destino", "Estatus", "Habilitado", "Cliente", "Obra", "Dirección", 
    "Contacto","Teléfono", "Correo", "Modelo de pies", "Tamaño de dados", "Comerciante solicitante", "Versión de orden",
    "Fecha de montaje", "Periodo de renta", "Número de elevaciones", "Longitud de pluma", "Altura de torre", 
    "Altura final", "Responsable de seguro", "Estatus de orden"];

    const shippingsFilter = shippings.filter((shipping) => {
        const startDate = moment(values.start_date).format("YYYY-MM-DD");
        const endDate = moment(values.end_date).format("YYYY-MM-DD");
        return moment(shipping.createdAt).format("YYYY-MM-DD") >= startDate 
            && moment(shipping.createdAt).format("YYYY-MM-DD") <= endDate;
    });
    
    let rows = shippingsFilter && shippingsFilter.sort((a, b) => {
        if(moment(a.createdAt).format("YYYY-MM-DD") < moment(b.createdAt).format("YYYY-MM-DD")) return -1;
        if(moment(a.createdAt).format("YYYY-MM-DD") > moment(b.createdAt).format("YYYY-MM-DD")) return 1;
        return 0;
    }).map(shipping => [
        moment(shipping.createdAt).format("DD/MM/YYYY"),
        moment(shipping.deliveryDate).format("DD/MM/YYYY"),
        shipping.order?.crane?.model ? shipping.order?.crane?.model : "",
        shipping.order?.crane?.series ? shipping.order?.crane?.series : "",
        shipping.truckPlate,
        shipping.transportPlate,
        shipping.transportCarrierName,
        shipping?.company?.name ? shipping?.company?.name : shipping.transportCompany,
        shipping?.company?.rfc ? shipping?.company?.rfc : "",
        shipping?.company?.socialReason ? shipping?.company?.socialReason : "",
        function () {
            if(shipping.type == "TO_BUILDING") return "A obra";
            else return "De obra";
        }(),
        function () {
            if(shipping.status == "CREATED") return "Creado";
            else if(shipping.status == "IN_RECOLLECTION") return "En habilitado";
            else if(shipping.status == "RECOLLECTED") return "Habilitado";
            else if(shipping.status == "SUPERVISOR_REVIEW") return "Revisión de supervisor";
            else if(shipping.status == "REVIEWED") return "Revisado";
            else if(shipping.status == "REVIEWED_WITH_ERRORS") return "Revisado con errores";
            else if(shipping.status == "IN_PROCESS") return "En proceso";
            else if(shipping.status == "COMPLETED") return "Completado";
            else if(shipping.status == "IN_REVIEW") return "En revisión";
            else if(shipping.status == "TRAVELING") return "En camino";
            else if(shipping.status == "ARRIVED") return "En destino";
            else if(shipping.status == "FINISHED") return "Finalizado";
            else return "Revisiones de seguridad";
        }(),
        function () {
            if(shipping.isEnabled) return "Habilitado";
            else return "Embarque";
        }(),
        shipping.order?.clientName ? shipping.order?.clientName : "",
        shipping.order?.buildingName ? shipping.order?.buildingName : "",
        shipping.order?.buildingAddress ? shipping.order?.buildingAddress : "",
        shipping.order?.contactName ? shipping.order?.contactName : "",
        shipping.order?.contactPhone ? shipping.order?.contactPhone : "",
        shipping.order?.contactEmail ? shipping.order?.contactEmail : "",
        shipping.order?.feetModel ? shipping.order?.feetModel : "",
        shipping.order?.diceSize ? shipping.order?.diceSize : "",
        shipping.order?.requestingTrader ? shipping.order?.requestingTrader : "",
        shipping.order?.orderVersion ? shipping.order?.orderVersion : "",
        shipping.order?.mountingDate ? shipping.order?.mountingDate : "",
        shipping.order?.rentPeriod ? shipping.order?.rentPeriod : "",
        shipping.order?.elevationsNumber ? shipping.order?.elevationsNumber : "",
        shipping.order?.boomLength ? shipping.order?.boomLength : "",
        shipping.order?.towerHeight ? shipping.order?.towerHeight : "",
        shipping.order?.finalHeight ? shipping.order?.finalHeight : "",
        function () {
            if(shipping.order?.insuranceResponsable == "CLIENT") return "Cliente";
            else if(shipping.order?.insuranceResponsable == "OWNER") return "Groke";
            else return "";
        }(),
        function () {
            if(shipping.order?.status == "CREATED") return "Creado";
            else if(shipping.order?.status == "CHARGING") return "Cargando";
            else if(shipping.order?.status == "IN_BUILDING") return "En obra";
            else if(shipping.order?.status == "FINISHED") return "Finalizado";
            else if(shipping.order?.status == "TRAVELING") return "En camino";
            else if(shipping.order?.status == "RECEIVED") return "Recibida";
            else if(shipping.order?.status == "RETURNED") return "Regresada";
            else return "";
        }(),
    ]);

    downloadCSV([], headers, rows, "Reporte_general");
  }

  const onSubmit = (values) => {
    
    const startDate = moment(values.start_date).format("YYYY-MM-DD");
    const endDate = moment(values.end_date).format("YYYY-MM-DD");

    if(startDate > endDate) {
      alert("La fecha de inicio no puede ser mayor a la fecha de fin");
      return;
    }

    generateReport(values);
    
  }

  const onClose = () => {
    setCreated(false);
  };

  return <View
    onSubmit={onSubmit}
    onComplete={onCompleted}
    onClose={onClose}
  />;

}

DownloadReport.propTypes = {
  setCreated: PropTypes.func,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
};

export default DownloadReport;