import React, {useState} from "react";
import PropTypes from "prop-types";
import View from "components/preventive_maintenances/done/Details.view";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";

const Details = ({ preventiveMaintenanceId }) => {

    const [activeTab, setActiveTab] = useState("REVIEW");

    const qReport = useDetail(`
    {
        preventiveMaintenance{
            zone
            status
            finishDate
            technician{	
                firstName
                lastName
            }
            signature{
                url
            }
            report{
                residentName
                signature{
                    url
                }
            }
            maintenanceOperatorEvaluations{
                points
                criteria{
                    criteria
                }
            }
            maintenancePreventiveMaintenanceEvidences{
                file{
                    url
                }
                value
                category{
                    title
                    subtitle
                    typeQuestion
                }
            }

        }
    }`, preventiveMaintenanceId);

    if (qReport.loading) return <Loading />;
    if (qReport.error) return "Error";

    const { preventiveMaintenance = [] } = qReport.data;

    const renderStars = (points) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(i <= points ? <i key={i} className="fa fa-star text-warning" /> : <i key={i} className="fa fa-star-o text-warning" />);
        }
        return stars;
    };

    const isVideo = (url) => {
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        const extension = url.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    const filteredEvidences = preventiveMaintenance.maintenancePreventiveMaintenanceEvidences?.filter(
        (evidence) => evidence.category.typeQuestion === activeTab
    );

    return <View
            isVideo={isVideo}
            activeTab={activeTab} 
            renderStars={renderStars}
            setActiveTab={setActiveTab}
            filteredEvidences={filteredEvidences}
            preventiveMaintenance={preventiveMaintenance}/>;

}

Details.propTypes = {
    preventiveMaintenanceId: PropTypes.number.isRequired
};

export default Details;