import React, {useState, useRef} from 'react';
import View from 'components/arris/binnacles_validation/BinnacleReport.view';
import { usePagination, useQuery } from 'seed/gql';
import { Loading } from 'seed/helpers';
import { formatEnumFilters, formatFilters, formatSearchFilters } from "components/utils/filters";

function BinnacleReport() {
  
    const searchRef = useRef(null);
    const [search, setSearch] = useState("");
    const [orderDate, setOrderDate] = useState("-");
    const [statusFilters, setStatusFilters] = useState({
        "APPROVED": false,
        "PENDING": false,
        "REJECTED": false,
    });

    const qCranes = useQuery(`{
        cranes {
          series
          number
          model
        }
      }`, "in_use=true");

    if  (qCranes.loading) return <Loading/>;
    if  (qCranes.error) return "Error";

    const { cranes } = qCranes.data;

    const handleChange = (value) => 
        setSearch(value);
    
    return <View
                search={search}
                cranes={cranes}
                searchRef={searchRef}
                orderDate={orderDate}
                handleChange={handleChange}
                setOrderDate={setOrderDate}
                statusFilters={statusFilters}
                setStatusFilters={setStatusFilters}
            />;

}

export default BinnacleReport;