import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { FileField } from "seed/helpers";
import { Typeahead } from "react-bootstrap-typeahead";

const UploadItemView = ({ stockGroups, group={}, onSubmit, error }) =>
    <div class="card">

        {/* Header */}
        <div class="card-header">
            <h3 class="card-header-title">Cargar Item</h3>
        </div>

        {/* Body */}
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <Formik
                        initialValues={group}
                        onSubmit={onSubmit}>
                        {({ values, setFieldValue }) =>
                            <Form>
                                <div class="mb-3">
                                    {/* Crane */}
                                    <div class="form-group">
                                        <div>
                                            <label class="input-label">Grupo de Piezas</label>
                                            <Typeahead
                                                id="menu"
                                                placeholder="Buscar Grupo de Piezas"
                                                labelKey={(group) => `${group.name}`}
                                                onChange={(selected) => selected.length > 0 ? setFieldValue("group_id", selected[0].id) : ""}
                                                options={stockGroups}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                                            <span className="mr-2 font-weight-bold mt-2">Item:</span>
                                            <FileField
                                                name="items"
                                                multiple={true}
                                                accept="image/*, video/*"
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                                <button type="submit" class="btn btn-block btn-primary">Cargar pieza</button>
                            </Form>}
                    </Formik>
                </div>
            </div>
        </div>

    </div>;

UploadItemView.propTypes = {};

export default UploadItemView;