import React from "react";
import Proptypes from "prop-types";
import { BrowserRouter, Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import ModalRoute from "components/helpers/ModalRoute";
import Details from "components/arris/binnacles_validation/Details";
import BinnacleList from "components/arris/binnacles_validation/List";

const BinnacleReportView = ({
  search,
  cranes,
  orderDate,
  searchRef,
  handleChange,
  setOrderDate,
  statusFilters,
  setStatusFilters,
}) => (
  <BrowserRouter basename="/arris/binnacles">
    <div className="content container-fluid p-7">
      <div className="page-header pt-4">
        <div className="row align-items-end">
          <div className="col-sm">
            <h1 className="page-header-title">Reporte de Bitácora</h1>
          </div>
        </div>
      </div>

      <div className="mb-3 mx-1">
        <div className="row">
          <div className="col-md-6">
            <h4>Bitácoras cargadas</h4>
          </div>
          <div className="col-md-6">
            <div className="float-right">
              <Typeahead
                id="menu"
                ref={searchRef}
                placeholder="Buscar grúa..."
                onInputChange={handleChange}
                labelKey={(crane) =>
                  `${crane.series} - ${crane.model} ${crane.number}`
                }
                onChange={(selected) =>
                  selected.length > 0 ? handleChange(selected[0].series) : ""
                }
                options={cranes}
              >
                <div className="rbt-aux">
                  {search.length == 0 && (
                    <i className="fa fa-search rbt-aux"></i>
                  )}
                  {search.length > 0 && (
                    <i
                      className="fa fa-times rbt-close text-danger"
                      style={{ paddingTop: "4px" }}
                      role="button"
                      onClick={() => {
                        searchRef.current.clear();
                        handleChange("");
                      }}
                    ></i>
                  )}
                </div>
              </Typeahead>
            </div>
          </div>
        </div>
      </div>

      <div className="row border font-weight-bold mx-1">
        <div className="col-md-1 p-2 text-center">
          <div
            className="d-flex flex-row align-items-center"
            style={{ marginTop: "-10px", marginBottom: "-10px" }}
          >
            <span>Fecha</span>
            <div className="mx-1">
              <button
                type="button"
                className={`btn btn-text mx-1 px-0 ${
                  orderDate == "" ? "text-primary" : ""
                }`}
                onClick={() => setOrderDate("")}
              >
                <i className="fa fa-arrow-up"></i>
              </button>
              <button
                type="button"
                className={`btn btn-text mx-1 px-0 ${
                  orderDate == "-" ? "text-primary" : ""
                }`}
                onClick={() => setOrderDate("-")}
              >
                <i className="fa fa-arrow-down"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2 p-2 text-center">Grúa</div>
        <div className="col-md-2 p-2 text-center">Operador</div>
        <div className="col-md-3 p-2 text-center">Obra</div>
        <div className="col-md-2 p-2 text-center">
          <div
            class="dropdown"
            style={{ marginTop: "-10px", marginBottom: "-10px" }}
          >
            <a
              class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}
            >
              Estado
            </a>
            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>
                <div class="dropdown-item">
                  <input
                    type="checkbox"
                    id="filter1"
                    name="filter1"
                    defaultChecked={statusFilters["APPROVED"]}
                    onChange={(e) =>
                      setStatusFilters({
                        ...statusFilters,
                        APPROVED: e.target.checked,
                      })
                    }
                  />
                  <label className="px-2 align-middle" for="filter1">
                    <h5>Revisado</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input
                    type="checkbox"
                    id="filter6"
                    name="filter6"
                    defaultChecked={statusFilters["PENDING"]}
                    onChange={(e) =>
                      setStatusFilters({
                        ...statusFilters,
                        PENDING: e.target.checked,
                      })
                    }
                  />
                  <label className="px-2 align-middle" for="filter6">
                    <h5>Pendiente</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input
                    type="checkbox"
                    id="filter6"
                    name="filter6"
                    defaultChecked={statusFilters["REJECTED"]}
                    onChange={(e) =>
                      setStatusFilters({
                        ...statusFilters,
                        REJECTED: e.target.checked,
                      })
                    }
                  />
                  <label className="px-2 align-middle" for="filter6">
                    <h5>Rechazado</h5>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-2 p-2 text-center">Acciones</div>
      </div>

      <BinnacleList
        search={search}
        statusFilters={statusFilters}
        orderDate={orderDate}
      />

      <ModalRoute
        path="/details/:binnacleTokenId(\d+)"
        width={800}
        height={550}
        component={Details}
      />
    </div>
  </BrowserRouter>
);

BinnacleReportView.propTypes = {
  search: Proptypes.string,
  cranes: Proptypes.array,
  searchRef: Proptypes.object,
  orderDate: Proptypes.string,
  handleChange: Proptypes.func,
  setOrderDate: Proptypes.func,
  statusFilters: Proptypes.object,
  setStatusFilters: Proptypes.func,
};

export default BinnacleReportView;
