import React from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
} from 'chart.js';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import Analytics from "components/comercial/analytics/List";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const craneOcupationLineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Ocupación de grúas',
    },
  },
}

const craneOcupationPieChartOptions = {
  responsive: true,
  //maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Ocupación de grúas',
    },
  },
}

const craneFamiliesOcupationStackedBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    title: {
      display: false,
      text: 'Ocupación de grúas por familias',
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const craneStatesOcupationBarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Ocupación de grúas por cada estado',
    },
  },
};

const craneStatesOcupationBarChartMobileOptions = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Ocupación de grúas por cada estado',
    },
  },
};

const DashboardView = ({
  isMobile = false,
  isMobileHorizontal = false,
  craneOcupationTableAnalytic,
  craneOcupationLineChartData,
  craneOcupationPieChartData,
  craneFamiliesOcupationStackedBarChartData,
  craneStatesOcupationBarChartData,
  loadingCraneOcupationLineChart,
  loadingCraneOcupationPieChart,
  loadingCraneFamiliesOcupationStackedBarChart,
  loadingCraneStatesOcupationBarChart,
  loadingCraneOcupationTableAnalytics
}) =>
  <div className="my-5">

    <div className={`d-flex my-5 ${isMobile && "flex-column"}`}>

      <div className={`card ${isMobile || isMobileHorizontal ? "py-3 px-1" : "p-5"}`}
        style={{ width: isMobile ? "100%" : "70%", border: "1px solid #CDCDCD" }}>
        <div className="d-flex justify-content-between">
          <div></div>
          <h3 className="text-center mb-3">Ocupación de grúas por mes de este año</h3>
          {loadingCraneOcupationLineChart ? (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (<div></div>)}
        </div>
        <Line options={craneOcupationLineChartOptions} data={craneOcupationLineChartData} />
      </div>

      <div className={`card ${isMobile || isMobileHorizontal ? "py-3 px-1" : "p-5"} ${isMobile ? "mt-5" : "ml-5"}`}
        style={{ width: isMobile ? "100%" : "30%", border: "1px solid #CDCDCD" }}>
        <div className="d-flex justify-content-between">
          <div></div>
          <h3 className="text-center mb-5">Ocupación de grúas este mes</h3>
          {loadingCraneOcupationPieChart ? (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (<div></div>)}
        </div>
        <Doughnut options={craneOcupationPieChartOptions} data={craneOcupationPieChartData} />
      </div>

    </div>

    <div className={`d-flex my-5 ${isMobile && "flex-column"}`}>

      <div className={`card ${isMobile || isMobileHorizontal ? "py-3 px-1" : "p-5"}`}
        style={{ width: isMobile ? "100%" : "60%", border: "1px solid #CDCDCD" }}>
        <div className="d-flex justify-content-between">
          <div></div>
          <h3 className="text-center mb-3">Tabla del estatus de las grúas en este año</h3>
          {loadingCraneOcupationTableAnalytics ? (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (<div></div>)}
        </div>
        <Analytics data={craneOcupationTableAnalytic} />
      </div>

      <div className={`card ${isMobile || isMobileHorizontal ? "py-3 px-1" : "p-5"}  ${isMobile ? "mt-5" : "ml-5"}`}
        style={{ width: isMobile ? "100%" : "40%", border: "1px solid #CDCDCD", height: isMobile ? "100vh" : "auto" }}>
        <div className="d-flex justify-content-between">
          <div></div>
          <h3 className="text-center mb-3">Ocupación de las grúas por familias</h3>
          {loadingCraneFamiliesOcupationStackedBarChart ? (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (<div></div>)}
        </div>
        <div className="h-100">
          <Bar options={craneFamiliesOcupationStackedBarChartOptions}
            data={craneFamiliesOcupationStackedBarChartData} />
        </div>
      </div>

    </div>

    <div className={`d-flex my-5 ${isMobile && "flex-column"}`} >

      <div className={`card w-100 ${isMobile || isMobileHorizontal ? "py-3 px-1" : "p-5"}`}
        style={{ border: "1px solid #CDCDCD", height: isMobile ? "100vh" : "auto" }}>
        <div className="d-flex justify-content-between">
          <div></div>
          <h3 className="text-center mb-3">Ocupación de las grúas por estado</h3>
          {loadingCraneStatesOcupationBarChart ? (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (<div></div>)}
        </div>
        <div className="h-100">
          <Bar options={isMobile ? craneStatesOcupationBarChartMobileOptions : craneStatesOcupationBarChartOptions}
            data={craneStatesOcupationBarChartData} />
        </div>
      </div>

    </div>

  </div>;

DashboardView.propTypes = {
  isMobile: PropTypes.bool,
  isMobileHorizontal: PropTypes.bool,
  craneOcupationTableAnalytic: PropTypes.array.isRequired,
  craneOcupationLineChartData: PropTypes.object.isRequired,
  craneOcupationPieChartData: PropTypes.object.isRequired,
  craneFamiliesOcupationStackedBarChartData: PropTypes.object.isRequired,
  craneStatesOcupationBarChartData: PropTypes.object.isRequired,
  loadingCraneOcupationLineChart: PropTypes.bool.isRequired,
  loadingCraneOcupationPieChart: PropTypes.bool.isRequired,
  loadingCraneFamiliesOcupationStackedBarChart: PropTypes.bool.isRequired,
  loadingCraneStatesOcupationBarChart: PropTypes.bool.isRequired,
  loadingCraneOcupationTableAnalytics: PropTypes.bool.isRequired,
};

export default DashboardView;