
const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const getOwner = (owner) => {
    if (owner == "GROKE") return "GROKE";
    if (owner == "RENTED") return "RENTADA";
    if (owner == "SOLD") return "VENDIDA";
    return "";
}

const getCraneStatus = (status) => {
    if (status == "FREE") return "LIBRE";
    if (status == "BUSY") return "EN USO";
    if (status == "DISMOUNTING") return "POR DESMONTARSE";
    return "";
}

const getCraneFamily = (model) => {
    if (model.startsWith("10LC")) return "10LC's";
    if (model.startsWith("21LC")) return "21LC's";
    if (model.startsWith("Linden")) return "Linden's";
    if (model.startsWith("5LC")) return "5LC's";
    if (model.startsWith("LCL")) return "LCL's";
    if (model.startsWith("CDK")) return "CDK's";
    if (model.startsWith("11LC")) return "11LC's";
    if (model.startsWith("EDC")) return "EDC's";
    if (model.startsWith("SA")) return "SA's";
    if (model.startsWith("LC")) return "LC's";
    if (model.startsWith("RTC")) return "RTC's";
    if (model.startsWith("SBL")) return "SBL's";
    if (model.startsWith("BL")) return "BL's";
    return "";
}

const craneFamilies = ["Todos", "10LC", "21LC", "Linden", "5LC", "LCL", "CDK",
    "11LC", "EDC", "SA", "LC", "RTC", "SBL", "BL"]

export { months, getOwner, getCraneStatus, getCraneFamily, craneFamilies }