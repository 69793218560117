import React from "react";
import PropTypes from "prop-types";
import { months } from "components/utils/constants";


const ExtraWorkedHoursTableView = ({ data, grouped, loading = false }) => (
  <>
    <div className="d-flex justify-content-between">
      <div></div>
      <h3 className="text-center mb-3">Horas extras trabajadas</h3>
      {loading ? (
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      ) : (<div></div>)}
    </div>
    <div className="table-responsive">
      <table className="table table-align-middle" id="analytics">

        <thead className="thead-light">
          <tr>
            <th></th>
            {data.labels && data.labels.map((item, idx) => (
              <th key={idx}>{months[item.month - 1]} {item.year}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.data && data.data.map((item, idx) => (
            function () {
              if (grouped == "crane") {
                return (
                  <tr key={idx}>
                    <th><b>{item.crane.series}</b>{" / " + item.crane.model + "" + item.crane.number}</th>
                    {item.data && item.data.map((month, idx) => (
                      <th key={idx}>{month}</th>
                    ))}
                  </tr>
                )
              } else if (grouped == "order") {
                return (
                  <tr key={idx}>
                    <th><b>{item.building}</b></th>
                    {item.data && item.data.map((month, idx) => (
                      <th key={idx}>{month}</th>
                    ))}
                  </tr>
                )
              } else if (grouped == "client") {
                return (
                  <tr key={idx}>
                    <th><b>{item.client}</b></th>
                    {item.data && item.data.map((month, idx) => (
                      <th key={idx}>{month}</th>
                    ))}
                  </tr>
                )
              }
            }()))}
        </tbody>

      </table>
    </div>
  </>
);

ExtraWorkedHoursTableView.propTypes = {
  data: PropTypes.object.isRequired,
  grouped: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

export default ExtraWorkedHoursTableView;