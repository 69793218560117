import React from "react";
import PropTypes from "prop-types";
import View from "components/shippings/transport_companies/Form.view";
import { SAVE_TRANSPORT_COMPANY } from "seed/gql/queries";
import { useHistory } from "react-router-dom";
import { useSave } from "seed/gql";
import swal from 'sweetalert';

const FormSave = () => {

    const history = useHistory();

    const[callSave, setCallSave] = useSave(SAVE_TRANSPORT_COMPANY, {
        onCompleted: (data) => {
            swal("Compañía de transporte creada", " ", "success").then(() => {
                history.push('/companies');
            });
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const onSubmit = (values) => {

        if (!/^[A-Z&Ñ]{3,4}\d{6}(?:[A-Z\d]{3})?$/.test(values.rfc)) {
            swal("RFC inválido", "Por favor, ingrese un RFC válido", "error");
            return;
        }

        callSave({
            ...values
        });
    };

    return <View onSubmit={onSubmit} />;
}

FormSave.propTypes = {};

export default FormSave;