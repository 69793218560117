import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListView = ({
    transportCompanies,
    onDeleteCompany,
}) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-header-title">Compañías de transporte</h3>
      <div class="col-sm-auto">
        <div class="btn-group" role="group">
          <Link to="/companies/create" className="btn btn-primary btn-sm">
            <i class="fas fa-plus mr-1"></i>
            Nueva compañía
          </Link>
        </div>
      </div>
    </div>

    <div className="card-body">
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-3">Compañías creadas</h4>
          <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'auto' }}>
            {transportCompanies?.map(company => (
              <li 
                key={company.id} 
                className="list-group-item" >
                {company.name}
                <i 
                  className="fas fa-trash mt-1 text-danger float-right ml-3" 
                  onClick={() => onDeleteCompany(company.id)} 
                  style={{ cursor: 'pointer' }} />
                <Link to={`/companies/${company.id}/edit`} className="float-right">
                  <i 
                    className="fas fa-edit mt-1 text-info float-right" 
                    style={{ cursor: 'pointer' }} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

ListView.propTypes = {
    transportCompanies: PropTypes.array,
    onDeleteCompany: PropTypes.func,
};

export default ListView;