import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { useHistory } from 'react-router-dom';
import { getCommercialCranesFilter } from "components/utils/filters";
import Error from "components/helpers/Error";
import { difference, gte, lte, addMonths } from "components/comercial/utils/dates"
import View from "components/comercial/schedule/List.view";

const filterByFamilies = (families = [], cranes) => {
  const newCranes = [];
  if (families.length == 1 && families.includes("all")) {
    newCranes.push(...cranes)
  } else {
    families.forEach((family) => {
      newCranes.push(...cranes.filter((item) => item.model.startsWith(family)));
    });
  }
  return newCranes;
}

const filterByStatus = (status = "FREE", cranes) => {

  const getCraneStatus = (crane) => {

    const schedules = crane.craneSchedules;
    const actualDate = {
      "month": new Date().getMonth() + 1,
      "year": new Date().getFullYear()
    }

    for (const schedule of schedules) {
      const startDate = {
        "month": schedule.startMonth,
        "year": schedule.startYear
      }
      const endDate = addMonths(startDate, schedule.months - 1)

      if (gte(actualDate, startDate) && lte(actualDate, endDate)) {

        if (schedule.status == "BUSY") {
          const monthsDiff = difference(endDate, actualDate);
          if (monthsDiff <= 3) {
            return "DISMOUNTING";
          } else {
            return "BUSY";
          }
        } else if (schedule.status == "TO_USE") {
          return "TO_USE";
        }

      }
    }

    return "FREE";
  }

  if (status == null || status == "") return cranes;
  return cranes.filter((crane) => getCraneStatus(crane) == status);
}

function List({
  isMobile,
  startMonth,
  startYear,
  boardSize,
  filters = {},
  onBackHistory,
  onNextHistory
}) {
  const history = useHistory();
  const filterString = getCommercialCranesFilter(filters)

  const reqCranes = useQuery(`{
    craneCommercials {
      series
      number
      model
      status
      craneSchedules {
        client 
        building
        state
        months
        status
        startMonth
        startYear
        createdAt
      }
    }
  }`, filterString, { orderBy: "-id" })

  if (reqCranes.loading) return <Loading />;
  if (reqCranes.error) return <Error />;

  const { craneCommercials = [] } = reqCranes.data;
  let cranes = [...craneCommercials];

  cranes = filterByFamilies([filters.family], [...cranes])
  cranes = filterByStatus(filters.status, [...cranes])

  const onOpenForm = (startMonth, startYear, crane) => {
    history.push(`/create?startMonth=${startMonth}&startYear=${startYear}&crane=${crane}`);
  }

  return (
    <View
      isMobile={isMobile}
      cranes={cranes}
      startMonth={startMonth}
      startYear={startYear}
      boardSize={boardSize}
      onOpenForm={onOpenForm}
      onBackHistory={onBackHistory}
      onNextHistory={onNextHistory}
    />
  );
}

List.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  pageNum: PropTypes.number.isRequired,
  setPageNum: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  startMonth: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired,
  boardSize: PropTypes.number.isRequired,
  onBackHistory: PropTypes.func.isRequired,
  onNextHistory: PropTypes.func.isRequired,
  filters: PropTypes.object
};

export default List;