import React from "react";
import PropTypes from "prop-types";
import CheckEvidences from "components/binnacle/CheckEvidences";

const SecurityRevisionDetailsView = ({ 
  contDesv,
  craneModel,
  buildingName,
  finalMessage,
  initialMessage,
  selectedEvidences, 
  finalRevisionType,
  initialRevisionType,
  setSelectedEvidences,
  finalRevisionCategoryGroups, 
  initialRevisionCategoryGroups
}) => (
  <div className="card">
    {selectedEvidences && (
      <CheckEvidences
        evidences={selectedEvidences}
        setSelectedEvidences={setSelectedEvidences}
      />
    )}
    <div className="card-body">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>{craneModel}</h2>
        <h3>{buildingName}</h3>
      </div>
      <div style={{ paddingTop: "10px", maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}>
        {initialRevisionType && (
          <div>
            <h3 className="text-center text-info">Revisión de electromecánica</h3>
            {initialMessage ? (
              <div className="alert alert-warning">{initialMessage}</div>
            ) : (
              <div className="row">
                {initialRevisionCategoryGroups.map((category, idx2) => (
                  <div key={idx2} className="col-md-12">
                    <table className="table table-bordered table-sm border text-center">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th colSpan={5} className="d-flex justify-content-center align-items-center">
                            {category.name} {category.partId}
                            <button
                              className="btn btn-link text-center accordion-btn text-white"
                              type="button"
                              data-toggle="collapse"
                              data-target={"#id" + idx2}
                              aria-expanded="true"
                              aria-controls={"id" + idx2}
                            >
                              <i className="fa fa-chevron-down"></i>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="collapse" id={`id${idx2}`} style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <tr>
                          <td style={{ width: "5%" }}>No.</td>
                          <td style={{ width: "25%" }}>Categoría</td>
                          <td style={{ width: "5%" }}>Estado</td>
                          <td style={{ width: "35%" }}>Desv</td>
                          <td style={{ width: "25%" }}>Acciones</td>
                        </tr>
                        {category.checks.map((check, idx3) => (
                          <tr key={`check_${idx2}_${idx3}`}>
                            <td>{check.securityCheckType.number}</td>
                            <td>{check.securityCheckType.name}</td>
                            <td>
                              {check.deviationValue === "TRUE"
                                ? <i className="fa fa-check text-success"></i>
                                : check.deviationValue === "FALSE"
                                ? <i className="fa fa-times text-danger"></i>
                                : <i className="fa fa-clock text-secondary"></i>}
                            </td>
                            <td>
                              {check.corrections || check.findings || check.scpLiberation}
                              {(contDesv.length > 0 && check.isDeviation) ? 
                                <div>
                                  {contDesv.map((desv, idx) => (
                                    <div className="border-bottom" key={idx}>
                                      Descripción: {desv.description}<br/>
                                      Revisión: {desv.revisionNumber}<br/>
                                      {desv.enabled ? "Habilitada" : null}<br/>
                                      {desv.paintjob ? "Pintura" : null}<br/>
                                      {desv.maintenance ? "Mantenimiento" : null}<br/>
                                      {desv.maneuvers ? "Maniobras" : null}
                                    </div>
                                  ))}
                                </div>
                              : check.value}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => setSelectedEvidences(check.files)}
                              >
                                <i className="fa fa-eye mx-2"></i>
                                Ver evidencias
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {finalRevisionType && (
          <div>
            <h3 className="text-center text-info mt-7">Revisión de estructura y componentes</h3>
            {finalMessage ? (
              <div className="alert alert-warning">{finalMessage}</div>
            ) : (
              <div className="row">
                {finalRevisionCategoryGroups.map((category, idx2) => (
                  <div key={idx2} className="col-md-12">
                    {console.log("category", category.name)}
                    <table className="table table-bordered table-sm border text-center">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th colSpan={5} className="d-flex justify-content-center align-items-center">
                            {category.name} {category.partId}
                            <button
                              className="btn btn-link text-center accordion-btn text-white"
                              type="button"
                              data-toggle="collapse"
                              data-target={"#id" + idx2}
                              aria-expanded="true"
                              aria-controls={"id" + idx2}
                            >
                              <i className="fa fa-chevron-down"></i>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="collapse" id={`id${idx2}`} style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <tr>
                          <td style={{ width: "5%" }}>No.</td>
                          <td style={{ width: "25%" }}>Categoría</td>
                          <td style={{ width: "5%" }}>Estado</td>
                          <td style={{ width: "35%" }}>Desv</td>
                          <td style={{ width: "25%" }}>Acciones</td>
                        </tr>
                        {category.checks.map((check, idx3) => (
                          <tr key={`check_${idx2}_${idx3}`}>
                            <td>{check.securityCheckType.number}</td>
                            <td>{check.securityCheckType.name}</td>
                            <td>
                              {check.deviationValue === "TRUE"
                                ? <i className="fa fa-check text-success"></i>
                                : check.deviationValue === "FALSE"
                                ? <i className="fa fa-times text-danger"></i>
                                : <i className="fa fa-clock text-secondary"></i>}
                            </td>
                            <td>
                              {check.corrections || check.findings || check.scpLiberation}
                              {(contDesv.length > 0 && check.isDeviation) ? 
                                <div>
                                  {contDesv.map((desv, idx) => (
                                    <div className="border-bottom" key={idx}>
                                      Descripción: {desv.description}<br/>
                                      Revisión: {desv.revisionNumber}<br/>
                                      {desv.enabled ? "Habilitada" : null}<br/>
                                      {desv.paintjob ? "Pintura" : null}<br/>
                                      {desv.maintenance ? "Mantenimiento" : null}<br/>
                                      {desv.maneuvers ? "Maniobras" : null}
                                    </div>
                                  ))}
                                </div>
                              : check.value}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => setSelectedEvidences(check.files)}
                              >
                                <i className="fa fa-eye mx-2"></i>
                                Ver evidencias
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

SecurityRevisionDetailsView.propTypes = {
  revision: PropTypes.array,
  contDesv: PropTypes.array,
  craneModel: PropTypes.string,
  buildingName: PropTypes.string,
  selectedEvidences: PropTypes.array,
  setSelectedEvidences: PropTypes.func,
  initialMessage: PropTypes.string,
  finalMessage: PropTypes.string,
  initialRevisionType: PropTypes.bool,
  finalRevisionType: PropTypes.bool,
};

export default SecurityRevisionDetailsView;
