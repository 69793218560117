import React from "react";
import PropTypes from "prop-types";

const PlansDetailsView = ({
  orders,
  displayFile,
  handleDeletePlan,
  imageExtensions,
  videoExtensions,
}) => (
  <div
    className="card"
    style={{
      maxHeight: "500px",
      overflowY: "auto",
      overflowX: "hidden",
    }}
  >
    <div className="row mt-3 justify-content-center">
      <div className="col-lg-9">
        <div className="row">
          <div className="">
            {orders.length >= 1 ? (
              orders.map((order, index) => (
                <div key={order.id}>
                  {order.plans.length >= 1 ? (
                    <table className="table mt-2">
                      <thead>
                        <tr>
                          <th style={{ width: "60vw" }}>Archivo</th>
                          <th className="text-center" style={{ width: "40vw" }}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {order.plans.map((plan, planIndex) => {
                          return (
                            <tr key={planIndex}>
                              <td className="ml-3">{displayFile(plan.url, plan.name, planIndex + 1)}</td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() =>
                                    handleDeletePlan(order.id, plan.id)
                                  }
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row justify-content-center my-3">
                      <div className="col text-center align-self-center px-4">
                        <h3>No se han agregado planos para esta orden</h3>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="row justify-content-center my-3">
                <div className="col text-center px-4">
                  <h3>No se han agregado planos para esta orden</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

PlansDetailsView.propTypes = {
  orders: PropTypes.array.isRequired,
  displayFile: PropTypes.func.isRequired,
  handleDeletePlan: PropTypes.func.isRequired,
  imageExtensions: PropTypes.array.isRequired,
  videoExtensions: PropTypes.array.isRequired,
};

export default PlansDetailsView;
