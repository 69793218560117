import React, { useState, useRef, useEffect } from "react";
import View from "components/arris/arris_reports/weekly_report/WeeklyReport.view";
import { useGet, useGetCall, usePost } from "seed/api";
import { API_URL } from "settings";
import { mapArrisCraneStatus, mapArrisOperatorTurn } from "components/utils/enum_mapper";
import { downloadCSV } from "components/utils/file";
import moment from "moment";
import swal from "sweetalert";
import { useQuery } from "seed/gql";
import { useHistory } from "react-router";
import { Loading } from "seed/helpers";

const getStartDate = (path) => {

  if(path == "daily_report") return moment().format("YYYY-MM-DD");

  const date = new Date();
  const day = date.getDay();
  const diff = date.getDate() - day + 1;
  return moment(new Date(date.setDate(diff))).format("YYYY-MM-DD");

}

const DailySubmissions = () => {

  const history = useHistory();
  const path = history.location.pathname.split("/").at(-1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [reports, setReports] = useState([]);
  const [missing, setMissing] = useState(0);
  const [presented, setPresented] = useState(0);
  const [failures, setFailures] = useState(0);
  const [selectedReport, setSelectedReport] = useState(null);
  const [startDate, setStartDate] = useState(getStartDate(path));
  const [supervisorFilters, setSupervisorFilters] = useState([]);
  const [updateFilterSupervisor, setUpdateFilterSupervisor] = useState([]);
  const [updateFilterOperator, setUpdateFilterOperator] = useState([]);
  const [originalOperators, setOriginalOperators] = useState([]);
  const [orderDate, setOrderDate] = useState("DESC");
  const [turnFilters, setTurnFilters] = useState({
    "FIRST": false,
    "SECOND": false
  });
  const [initialTurns, setInitialTurns] = useState({
    "FIRST": true,
    "SECOND": true
  });
  
  const [detailFilters, setDetailFilters] = useState({
    "FAILURES": path == "failure_report" || path == "weekly_report",
    "NOT_FAILURES": path == "weekly_report" || path == "daily_report" || path == "not_sent_report",
    "COMPLETE": path == "weekly_report" || path == "daily_report",
    "NOT_COMPLETE": path == "weekly_report" || path == "not_sent_report" || path == "daily_report",
  });

  const getDetailsFilters = (details) =>
    Object.keys(details).filter(key => details[key]).join(",");

  const updateQuery = async (turns, details, filteredDate, date, filtersSup, filtersOp, pageChanged) => {
    const filteredTurns = Object.keys(turns).filter(key => turns[key]).join(",");
    const filteredDetails = getDetailsFilters(details);
    const filteredSupervisors = filtersSup ? filtersSup.join(",") : supervisorFilters;
    const operatorsFilter = filtersOp ? filtersOp.join(",") : " ";
    const page = pageChanged ? pageChanged : pageNum;
    const query = `?turns=${filteredTurns}&details=${filteredDetails}&order_date=${filteredDate}&date=${date}&supervisors=${filteredSupervisors}&operators=${operatorsFilter}&page=${page}`;
    const req = await fetch(API_URL + "/arris_operators/get_weekly_report/" + query, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Token ${sessionStorage.getItem("token")}`
      },
    });

    const res = await req.json();
    if(res.loading) return <Loading/>;

    if(filtersOp.length == 0){
      const updatedOperators = res.reports.filter((report, index, self) => {
          return index === self.findIndex((r) => r.operator.id === report.operator.id);
        })
        .map((report) => ({
          id: report.operator.id,
          name: `${report.operator.first_name}`,
        }
      ));

      setOriginalOperators(updatedOperators)
    
    }

    setReports(res.reports);
    setMissing(res.missing_reports);
    setPresented(res.presented_reports);
    setFailures(res.failures_reports);
    setTotalPages(res.page_info.total_pages);  

    console.log(res.reports)

    if(res.presented_reports == 0 || (path == "not_sent_report" && missing == 0))
      setTotalPages(0);

  };


  const reqWeeklyReport = useGet("/arris_operators/get_weekly_report", {
    turns: "FIRST,SECOND",
    details: getDetailsFilters(detailFilters),
    order_date: "DESC",
    date: startDate,
  }, {
    onCompleted: (data) => {
      if(updateFilterOperator.length == 0){
        const updatedOperators = data.reports.filter((report, index, self) => {
            return index === self.findIndex((r) => r.operator.id === report.operator.id);
          })
          .map((report) => ({
            id: report.operator.id,
            name: `${report.operator.first_name}`,
          }
        ));
  
        setOriginalOperators(updatedOperators)
      }
      setReports(data.reports);
      setMissing(data.missing_reports);
      setPresented(data.presented_reports);
      setFailures(data.failures_reports);
      setTotalPages(data.page_info.total_pages); 
    }
  });

  const [callUploadEvidences, qUploadEvidences] = usePost("/arris_forms/upload_onedrive_evidences", {
    onCompleted: (data) => {
      window.location.href = "/ariss/weekly_report";
    }
  });
  
  const qSupervisors = useQuery(`
    {
      arrisOperators{
        id
        turn
        user {
          id
          firstName
        }
      }
    }`,
    "turn = SECURITY"
  );

  useEffect(() => {
    if (qSupervisors.data) {
      const securityAdminUsers = qSupervisors.data.arrisOperators ?? [];
      const securityAdminIds = securityAdminUsers.map((operator) => operator.user.id);
      setSupervisorFilters(securityAdminIds);
      if (securityAdminIds.length > 0 && originalOperators.length == 0) {
        updateQuery(initialTurns, detailFilters, orderDate, startDate, securityAdminIds, updateFilterOperator);
      }
    }
  }, [qSupervisors.data, pageNum, originalOperators]);

  if (reqWeeklyReport.loading) return <Loading/>;
  if (qSupervisors.loading) return <Loading />;
  if (qSupervisors.error) return "Error";

  const supervisors = qSupervisors.data.arrisOperators
  const handleSupervisorFilterChange = (event, supervisorId) => {
    const { checked } = event.target;
    let updatedFilters = updateFilterSupervisor;

    if(!updatedFilters.includes(supervisorId) && checked) {
      updatedFilters = [...updateFilterSupervisor, supervisorId];
    } else {
      updatedFilters = updatedFilters.filter((id) => id !== supervisorId);
    }
    
    setUpdateFilterSupervisor(updatedFilters);
    
    const shouldUseInitialTurns = !turnFilters.FIRST && !turnFilters.SECOND;
    const turnsToUse = shouldUseInitialTurns ? initialTurns : turnFilters;

    const supervisorFilter = updatedFilters.length > 0 ? updatedFilters : supervisorFilters;

    updateQuery(turnsToUse, detailFilters, orderDate, startDate, supervisorFilter, updateFilterOperator);
  };

  const handleTurnFilters = (event, turn) => {
    const { checked } = event.target;
    let updatedFilterTurn = { ...turnFilters, [turn]: checked };

    setTurnFilters(updatedFilterTurn);

    const shouldUseInitialTurns = !updatedFilterTurn.FIRST && !updatedFilterTurn.SECOND;
    const turnsToUse = shouldUseInitialTurns ? initialTurns : updatedFilterTurn;

    const filtersToUse = updateFilterSupervisor.length === 0 ? supervisorFilters : updateFilterSupervisor;

    updateQuery(turnsToUse, detailFilters, orderDate, startDate, filtersToUse, updateFilterOperator);
  };

  const handleOperatorChange = (event, operatorID) => {
    const { checked } = event.target;
    let updatedOperators = updateFilterOperator;
  
    if (!updatedOperators.includes(operatorID) && checked) {
      updatedOperators = [...updateFilterOperator, operatorID];
    } else {
      updatedOperators = updatedOperators.filter((id) => id !== operatorID);
    }
  
    setUpdateFilterOperator(updatedOperators);
  
    const shouldUseInitialTurns = !turnFilters.FIRST && !turnFilters.SECOND;
    const turnsToUse = shouldUseInitialTurns ? initialTurns : turnFilters;
  
    const filtersToUse = updateFilterSupervisor.length === 0 ? supervisorFilters : updateFilterSupervisor;

    updateQuery(turnsToUse, detailFilters, orderDate, startDate, filtersToUse, updatedOperators);
  };
  
  

  const onClickPage = (pageNum) => {
    setPageNum(pageNum);
    updateQuery(turnFilters, detailFilters, orderDate, startDate, supervisorFilters, updateFilterOperator, pageNum);
  }
  
  const downloadReport = () => {
    let headers = [
      "Fecha", 
      "Modelo", 
      "Serie", 
      "Operador", 
      "Turn", 
      "Evidencias", 
      "Fallas", 
      "Bitácora", 
      "Actividades", 
      "Justificación"
    ];
    let rows = reports.map(report => [
      report.date,
      report.crane.model + report.crane.number,
      report.crane.series,
      report.operator.first_name + " " + report.operator.last_name,
      mapArrisOperatorTurn(report.operator.turn),
      '"' + report.evidences.map(evidence => evidence.file.url).join("\n") + '"',
      '"' + report.failures.join("\n") + '"',
      '"' + report.binnacle.map(binnacle => binnacle.type + " - " + binnacle.name + " => " 
        + mapArrisCraneStatus(binnacle.status)).join("\n") + '"',
      report.activity
    ]);
    downloadCSV([], headers, rows, "envios_diarios_" + moment().format("YYYY-MM-DD"));
  };

  const uploadOneDrive = async (reportId) => {
    swal("¡Listo!", "Se está realizando la carga, en unos minutos podrás visualizarlo en la carpeta correspondiente.", "success").then(() => {
      window.location.href = "/ariss/weekly_report";
  });
    callUploadEvidences({ report_id: reportId });
  };

  const uniqueSupervisors = supervisors.filter((supervisor, index) => {
    const firstIndexOfUser = supervisors.findIndex(
      (s) => s.user.id === supervisor.user.id
    );
    return index === firstIndexOfUser;
  });

  return <View 
    path={path}
    failures={failures}
    missing={missing}
    presented={presented}
    reports={reports}
    search={search}
    pageNum={pageNum}
    totalPages={totalPages}
    setSearch={setSearch}
    searchRef={searchRef}
    selectedReport={selectedReport}
    setSelectedReport={setSelectedReport}
    updateQuery={updateQuery}
    turnFilters={turnFilters}
    setTurnFilters={setTurnFilters}
    handleTurnFilters={handleTurnFilters}
    handleOperatorChange={handleOperatorChange}
    originalOperators={originalOperators}
    detailFilters={detailFilters}
    setDetailFilters={setDetailFilters}
    orderDate={orderDate}
    setOrderDate={setOrderDate}
    downloadReport={downloadReport}
    startDate={startDate}
    setStartDate={setStartDate}
    onClickPage={onClickPage}
    uploadOneDrive={uploadOneDrive}
    uniqueSupervisors={uniqueSupervisors}
    handleSupervisorFilterChange={handleSupervisorFilterChange}
    supervisorFilters={supervisorFilters}
    updateFilterOperator={updateFilterOperator}
  />;

}

DailySubmissions.propTypes = {};

export default DailySubmissions;
