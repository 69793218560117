import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const FormView = ({
  cranes,
  isMobile = false,
  edit = false,
  schedule = {},
  search,
  elevations,
  searchRef,
  formikRef = () => { },
  onDeleteSchedule = () => { },
  onSubmit,
  handleChange,
  addElevation,
  onChangeElevation,
  onDeleteElevation
}) =>
  <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">

    <div class="card h-100 px-3 py-2">

      <div class="card-header">
        <div className="d-flex align-items-center justify-content-start w-100">
          <h3 class="card-header-title">
            {edit ? "Editar " : "Crear "} cronograma
          </h3>
        </div>
      </div>

      <div class="card-body pt-2" style={{ maxHeight: "1000px", overflowY: "auto" }}>
        <div class="row">
          <div class="col">

            <Formik
              initialValues={schedule}
              innerRef={formikRef}
              onSubmit={onSubmit}>
              {({ values, setFieldValue }) =>
                <Form>

                  <div class="mb-5 w-100">
                    <Typeahead
                      id="menu"
                      ref={searchRef}
                      placeholder="Buscar Grúa"
                      onInputChange={handleChange}
                      labelKey={(crane) => `${crane.series} - ${crane.model} ${crane.number}`}
                      onChange={(selected) => selected.length > 0 ? handleChange(selected[0].id) : ""}
                      options={cranes}
                      selected={function () {
                        if (typeof search == "number") {
                          if (cranes.find(crane => crane.id == search)) {
                            return [cranes.find(crane => crane.id == search)];
                          }
                        }
                        return []
                      }()}
                      style={{ border: "1px solid lightgray", borderRadius: "6px" }}
                    >
                      <div className="rbt-aux">
                        {search.length == 0 && <i className="fa fa-search rbt-aux"></i>}
                        {search.length > 0 && <i className="fa fa-times rbt-close text-danger"
                          style={{ paddingTop: "4px" }} role="button" onClick={() => {
                            searchRef.current.clear()
                            handleChange("")
                          }}></i>}
                      </div>
                    </Typeahead>
                  </div>

                  <div class="mb-1 w-100">
                    <div class="form-group form-group-default required mt-3 mb-3">
                      <label class="control-label">Nombre del Cliente</label>
                      <Field type="text" name="client" class="form-control" required />
                      <div class="invalid-feedback">Required</div>
                    </div>
                  </div>

                  <div class="mb-1 w-100">
                    <div class="form-group form-group-default required mt-3 mb-3">
                      <label class="control-label">Obra</label>
                      <Field type="text" name="building" class="form-control" required />
                      <div class="invalid-feedback">Required</div>
                    </div>
                  </div>

                  <div class="mb-1 w-100">
                    <div className="row">
                      <div className="col-md-6">

                        <div class="form-group form-group-default required mt-3 mb-3">
                          <label class="control-label">Tipo</label>
                          <Field as="select" className="form-control" name="status" required>
                            <option value="">Selecciona un tipo</option>
                            <option value="TO_USE">Por utilizar</option>
                            <option value="BUSY">Ocupado</option>
                          </Field>
                          <div class="invalid-feedback">Required</div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group form-group-default required mt-3 mb-3">
                          <label class="control-label">Estado</label>
                          <Field as="select" name="state" class="form-control" required >
                            <option value="">Selecciona un estado</option>
                            <option value="AGUASCALIENTES">AGUASCALIENTES</option>
                            <option value="BAJA CALIFORNIA">BAJA CALIFORNIA</option>
                            <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
                            <option value="CAMPECHE">CAMPECHE</option>
                            <option value="CHIAPAS">CHIAPAS</option>
                            <option value="CHIHUAHUA">CHIHUAHUA</option>
                            <option value="CIUDAD DE MEXICO">CIUDAD DE MEXICO</option>
                            <option value="COAHUILA">COAHUILA</option>
                            <option value="COLIMA">COLIMA</option>
                            <option value="DURANGO">DURANGO</option>
                            <option value="ESTADO DE MEXICO">ESTADO DE MEXICO</option>
                            <option value="GUANAJUATO">GUANAJUATO</option>
                            <option value="GUERRERO">GUERRERO</option>
                            <option value="HIDALGO">HIDALGO</option>
                            <option value="JALISCO">JALISCO</option>
                            <option value="MICHOACAN">MICHOACAN</option>
                            <option value="MORELOS">MORELOS</option>
                            <option value="NAYARIT">NAYARIT</option>
                            <option value="NUEVO LEON">NUEVO LEON</option>
                            <option value="OAXACA">OAXACA</option>
                            <option value="PUEBLA">PUEBLA</option>
                            <option value="QUERETARO">QUERETARO</option>
                            <option value="QUINTANA ROO">QUINTANA ROO</option>
                            <option value="SAN LUIS POTOSI">SAN LUIS POTOSI</option>
                            <option value="SINALOA">SINALOA</option>
                            <option value="SONORA">SONORA</option>
                            <option value="TABASCO">TABASCO</option>
                            <option value="TAMAULIPAS">TAMAULIPAS</option>
                            <option value="TLAXCALA">TLAXCALA</option>
                            <option value="VERACRUZ">VERACRUZ</option>
                            <option value="YUCATAN">YUCATAN</option>
                            <option value="ZACATECAS">ZACATECAS</option>
                          </Field>
                          <div class="invalid-feedback">Required</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-1 w-100">
                    <div className="row">

                      <div className="col-md-6">
                        <div class="form-group form-group-default required mt-3 mb-3">
                          <label class="control-label">Fecha inicial</label>

                          <DatePicker
                            views={['month', 'year']}
                            format="MM/YYYY"
                            value={values.start_date}
                            clearable={true}
                            style={{ width: '100%' }}
                            onChange={(date) => setFieldValue("start_date", date)}
                            sx={{
                              width: '95%',
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                margin: '-10px',
                                padding: '-10px'
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                margin: '0',
                                padding: '0'
                              },
                              '& .MuiInputBase-input': {
                                fontSize: '15px'
                              }
                            }}
                          />
                          <div class="invalid-feedback">Required</div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group form-group-default required my-3" style={{ height: "70%" }}>
                          <label class="control-label">Duración</label>
                          <Field type="number" name="months" class="form-control" required />
                          <div class="invalid-feedback">Required</div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <h3 className="my-5">Elevaciones</h3>
                  <div className="mb-5">
                    {
                      elevations.map((elevation, idx) => (
                        <div key={idx} className="mb-1 w-100">

                          {
                            isMobile ? (
                              <div className="d-flex mb-5">

                                <div style={{ width: "70%" }}>

                                  <div className="row">
                                    <div className="col-12 col-md-6">
                                      <div class="form-group form-group-default required mt-3 mb-3">
                                        <label class="control-label">Fecha inicial</label>
                                        <DatePicker
                                          views={['month', 'year']}
                                          format="MM/YYYY"
                                          value={elevation.start_date}
                                          name="start_date"
                                          clearable={true}
                                          style={{ width: '100%' }}
                                          onChange={(date) => onChangeElevation(date, idx, "start_date")}
                                          sx={{
                                            width: '95%',
                                            '& .MuiOutlinedInput-root': {
                                              border: 'none',
                                              margin: '-10px',
                                              padding: '-10px'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              border: 'none',
                                              margin: '0',
                                              padding: '0'
                                            },
                                            '& .MuiInputBase-input': {
                                              fontSize: '15px'
                                            }
                                          }}
                                        />

                                        <div class="invalid-feedback">Required</div>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                      <div class="form-group form-group-default required mt-3 mb-3">
                                        <label class="control-label">Duración</label>
                                        <Field type="number" class="form-control mt-2" required value={elevation.months}
                                          onChange={(event) => onChangeElevation(event.target.value, idx, "months")}
                                        />
                                        <div class="invalid-feedback">Required</div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className="d-flex justify-content-center align-items-center"
                                  style={{ width: "30%", minHeight: "100%" }}>
                                  <div class="mt-3 mb-3 justify-content-center">
                                    <button className="btn btn-danger" type="button"
                                      onClick={() => onDeleteElevation(idx)}>
                                      <i class="fas fa-minus-square"></i>
                                    </button>
                                  </div>
                                </div>

                              </div>
                            ) : (
                              <div className="row">

                                <div className="col-md-5">
                                  <div class="form-group form-group-default required mt-3 mb-3">
                                    <label class="control-label">Fecha inicial</label>
                                    <DatePicker
                                      views={['month', 'year']}
                                      format="MM/YYYY"
                                      value={elevation.start_date}
                                      name="start_date"
                                      clearable={true}
                                      style={{ width: '100%' }}
                                      onChange={(date) => onChangeElevation(date, idx, "start_date")}
                                      sx={{
                                        width: '95%',
                                        '& .MuiOutlinedInput-root': {
                                          border: 'none',
                                          margin: '-10px',
                                          padding: '-10px'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                          margin: '0',
                                          padding: '0'
                                        },
                                        '& .MuiInputBase-input': {
                                          fontSize: '15px'
                                        }
                                      }}
                                    />

                                    <div class="invalid-feedback">Required</div>
                                  </div>
                                </div>

                                <div className="col-md-5">
                                  <div class="form-group form-group-default required mt-3 mb-3"
                                    style={{ height: '70%' }}>
                                    <label class="control-label">Duración</label>
                                    <Field type="number" class="form-control mt-2" required value={elevation.months}
                                      onChange={(event) => onChangeElevation(event.target.value, idx, "months")}
                                    />
                                    <div class="invalid-feedback">Required</div>
                                  </div>
                                </div>

                                <div className="d-flex col-md-2 justify-content-center align-items-center">
                                  <div class="mt-3 mb-3 justify-content-center">
                                    <button className="btn btn-danger" type="button"
                                      onClick={() => onDeleteElevation(idx)}>
                                      <i class="fas fa-minus-square"></i>
                                    </button>
                                  </div>
                                </div>

                              </div>
                            )
                          }

                        </div>
                      ))
                    }

                    <div className="d-flex">
                      <button className={`btn btn-info ${isMobile ? "w-100" : "w-50"}`} onClick={addElevation}>
                        <i className="fas fa-plus mr-3"></i>Agregar elevación
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className={`col-md-${edit ? "6" : "12"}`}>
                      <button type="submit" className="btn btn-block btn-primary">
                        Guardar
                      </button>
                    </div>

                    {
                      edit && (
                        <div className={`col-md-6 ${isMobile ? "mt-3" : ""}`}>
                          <button type="button" className="btn btn-block btn-danger"
                            style={{ borderRadius: "10px", border: "0" }} onClick={onDeleteSchedule}>
                            Eliminar
                          </button>
                        </div>
                      )
                    }
                  </div>

                </Form>}
            </Formik>

          </div>
        </div>
      </div>

    </div>
  </LocalizationProvider>;

FormView.propTypes = {};

export default FormView;