import React from "react";
import PropTypes from "prop-types";
import View from "components/security_stock/UploadItem.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import swal from 'sweetalert';

const UploadItem = () => {

    const [callSave, qSave] = usePost("/stock_groups/upload_item", {
        onCompleted: () => {
            swal("¡Éxito!", "Grupo de piezas creado", "success").then(() => window.location.href = "/security_stock");
        },
        onError: () => {
            swal("¡Error!", "Ocurrió un error al crear el grupo de piezas", "error");
        }
      });

    const qGroups = useQuery(`{
        stockGroups {
            id
            name
        }
    }`);
    
    if(qGroups.loading) return <Loading />;
    if(qGroups.error) return "Error";

    const { stockGroups } = qGroups.data;

    const onSubmit = (values) => {
        
        if (!values.group_id) {
            swal("¡Advertencia!", "Debe seleccionar un grupo de piezas", "warning");
            return;
        }
        
        if (!values.items) {
            swal("¡Advertencia!", "Debe seleccionar al menos un archivo", "warning");
            return;
        }

        callSave(values);
    };

    return <View stockGroups={stockGroups} onSubmit={onSubmit} />;

}

UploadItem.propTypes = {};

export default UploadItem;