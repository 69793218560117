import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import moment from 'moment';
import { getOwner, getCraneStatus, getCraneFamily } from "components/utils/constants";
import 'moment/locale/es';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
moment.locale('es');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getBase64ImageFromUrl(url) {
    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            })
            .catch(reject);
    });
}

async function generatePDF(preventiveMaintenance) {

  const finishDate = preventiveMaintenance?.finishDate
    ? new Date(preventiveMaintenance.finishDate).toLocaleDateString('es-ES')
    : 'N/A';

  const technician = `${preventiveMaintenance.technician?.firstName || ''} ${preventiveMaintenance.technician?.lastName || ''}`.trim() || 'N/A';
  const residentName = preventiveMaintenance?.report?.residentName || 'N/A';

  const logoBase64 = await getBase64ImageFromUrl('/theme/img/groke.png');
  const technicianSignatureBase64 = preventiveMaintenance.signature?.url ? await getBase64ImageFromUrl(preventiveMaintenance.signature.url) : '';
  const residentSignatureBase64 = preventiveMaintenance?.report?.signature?.url ? await getBase64ImageFromUrl(preventiveMaintenance.report.signature.url) : '';

  const activitiesReview = preventiveMaintenance.maintenancePreventiveMaintenanceEvidences
      .filter(evidence => evidence.category.typeQuestion === "REVIEW")
      .map((evidence, index) => [
          index + 1,
          `${evidence.category.title} - ${evidence.category.subtitle}`
      ]);

  const activitiesPower = preventiveMaintenance.maintenancePreventiveMaintenanceEvidences
      .filter(evidence => evidence.category.typeQuestion === "POWER")
      .map((evidence, index) => [
          index + 1,
          `${evidence.category.title} - ${evidence.category.subtitle}`
      ]);

  const documentDefinition = {
      content: [
          // Encabezado
          {
              columns: [
                  { text: 'Reporte de Mantenimiento Preventivo', style: 'header', width: '*' },
                  { image: logoBase64, width: 100, alignment: 'right' }
              ],
              margin: [0, 0, 0, 20]
          },

          // Sección: Información del Mantenimiento
          {
            table: {
                widths: ['*'],
                body: [
                    [{ text: 'Información del Mantenimiento', style: 'sectionHeader', fillColor: '#0090A9' }],
                ]
            },
            layout: 'noBorders',
            margin: [0, 0, 0, 10]
          },
          { text: `Fecha de Mantenimiento: ${finishDate}`, alignment: 'center', margin: [0, 5, 0, 0] },
          { text: `Técnico: ${technician}`, alignment: 'center', margin: [0, 5, 0, 0] },
          { text: `${preventiveMaintenance?.order?.crane?.series} / ${preventiveMaintenance?.order?.crane?.model} ${preventiveMaintenance?.order?.crane?.number} - ${preventiveMaintenance?.order?.clientName}`, alignment: 'center', margin: [0, 5, 0, 0] },
          { text: `Zona: ${preventiveMaintenance?.zone == "NORTH" ? "Norte" :
              preventiveMaintenance?.zone == "SOUTH" ? "Sur" :
                  preventiveMaintenance?.zone == "CENTRE" ? "Centro" : 'Bajío'}`, alignment: 'center', margin: [0, 5, 0, 0] },

          // Firmas
          {
              table: {
                  widths: ['50%', '50%'],
                  body: [
                      [{ text: 'Nombre del Técnico', alignment: 'center', bold: true }, { text: 'Firma', alignment: 'center', bold: true }],
                      [
                          technician,
                          technicianSignatureBase64 ? { image: technicianSignatureBase64, width: 150, height: 75, alignment: 'center' } : { text: 'No disponible', alignment: 'center' }
                      ],
                      [{ text: 'Nombre del Residente', alignment: 'center', bold: true }, { text: 'Firma', alignment: 'center', bold: true }],
                      [
                          residentName,
                          residentSignatureBase64 ? { image: residentSignatureBase64, width: 150, height: 75, alignment: 'center' } : { text: 'No disponible', alignment: 'center' }
                      ]
                  ]
              },
              margin: [0, 10, 0, 10]
          },

          {
            table: {
                widths: ['*'],
                body: [
                    [{ text: 'Actividades Realizadas (Revisión)', style: 'sectionHeader', fillColor: '#0090A9' }],
                ]
            },
            layout: 'noBorders',
            margin: [0, 20, 0, 10]
          },
          {
              table: {
                  widths: ['10%', '90%'],
                  body: [
                      [{ text: 'No.', bold: true, alignment: 'center'}, { text: 'Actividades Realizadas', bold: true, alignment: 'center' }],
                      ...activitiesReview
                  ]
              },
              margin: [0, 10, 0, 20]
          },

          {
            table: {
                widths: ['*'],
                body: [
                    [{ text: 'Actividades Realizadas (Alimentación)', style: 'sectionHeader', fillColor: '#0090A9' }],
                ]
            },
            layout: 'noBorders',
            margin: [0, 20, 0, 10]
          },
          {
              table: {
                  widths: ['10%', '90%'],
                  body: [
                      [{ text: 'No.', bold: true }, { text: 'Actividades Realizadas', bold: true }],
                      ...activitiesPower
                  ]
              },
              margin: [0, 10, 0, 20]
          }
      ],
      styles: {
          header: {
              fontSize: 18,
              bold: true
          },
          sectionHeader: {
              fontSize: 16,
              bold: true,
              color: 'white',
              margin: [0, 10, 0, 5],
              alignment: 'center'
          }
      },
  };

  pdfMake.createPdf(documentDefinition).download(`Reporte de Mantenimiento Preventivo_${preventiveMaintenance?.order?.crane?.series}/${preventiveMaintenance?.order?.crane?.model}${preventiveMaintenance?.order?.crane?.number}.pdf`);
}

const downloadCSV = (notes, headers, rows, filename) => {
  const BOM = '\uFEFF';

  let csv = notes.join("\n") + (notes.length > 0 ? "\n\n" : "");
  csv += headers.join(",") + "\n";
  csv += rows.map(row => row.join(",")).join("\n");

  let csvWithBOM = BOM + csv;

  let blob = new Blob([csvWithBOM], { type: 'text/csv; charset=utf-8' });

  let url = URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
}

const generateExcelColumns = (n) => {
  let result = [];
  let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < letters.length && result.length < n; i++) {
    result.push(letters[i]);
  }

  for (let i = 0; i < letters.length && result.length < n; i++) {
    for (let j = 0; j < letters.length && result.length < n; j++) {
      result.push(letters[i] + letters[j]);
    }
  }
  return result.slice(0, n);
}

const downloadScheduleExcel = async ({ headers, reports, size }) => {

  const busyColor = "366092";
  const freeColor = "FF0000";
  const toUseColor = "C4D79B";
  const dismountingColor = "E26B0A";

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Acumulado');

  const cells_to_join = [];
  const cols = ["145", "#", "FAMILIA", "STATUS", "MODELO", "CLIENTE", "OBRA", "UBICACIÓN", "CONDICIÓN ACTUAL",
    ...headers, "Salida aprox", "EN USO", "POR UTILIZAR", "SUMA"];

  worksheet.addRow(cols);

  for (let i = 0; i < cols.length; i++) {

    const lastRow = worksheet.lastRow;
    const currentCell = lastRow.getCell(i + 1);

    currentCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF000000' }
    }

    currentCell.font = {
      color: { argb: 'FFFFFFFF' },
      bold: true
    }

  }

  for (let i = 0; i < reports.length; i++) {
    const report = reports[i];
    const crane = report.crane;
    const schedule = report.schedule;
    const dashboard = report.dashboard;
    const totals = report.totals;

    let row = ["", i + 1, getCraneFamily(crane?.model ?? ""),
      getOwner(crane?.owner), crane?.model ?? "", schedule?.client ?? "",
      schedule?.building ?? "", schedule?.state ?? "", getCraneStatus(crane?.status)]

    let idx = 0;
    let dashboardRow = [];
    const excelCols = generateExcelColumns(size * 2);

    // Generate cells of the dashboard
    for (const item of dashboard) {
      if (item.status == "FREE") {
        dashboardRow.push("");
        idx += 1;
      } else {
        dashboardRow.push(item.client);
        if (item.months - 1 > 0) {
          cells_to_join.push(`${excelCols[idx + 9]}${i + 2}:${excelCols[idx + 8 + item.months]}${i + 2}`)
          idx += item.months;
        }
        for (let k = 0; k < item.months - 1; k++) {
          dashboardRow.push("");
        }
      }
    }

    // Add dashboard to the row data
    row.push(...dashboardRow);
    row.push(...[report?.departure ?? "", totals.busy, totals.to_use, totals.busy + totals.to_use]);
    worksheet.addRow(row);

    // Color the state of the schedule
    if (crane?.status == "FREE") {
      const lastRow = worksheet.lastRow;
      for (let i = 5; i <= 9; i++) {
        const currentCell = lastRow.getCell(i);
        currentCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' }
        }
      }
    } else if (crane?.status == "DISMOUNTING") {
      const lastRow = worksheet.lastRow;
      for (let i = 5; i <= 9; i++) {
        const currentCell = lastRow.getCell(i);
        currentCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF' + dismountingColor }
        }
      }
    } else if (crane?.status == "BUSY") {
      const lastRow = worksheet.lastRow;
      const currentCell = lastRow.getCell(9);
      currentCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF' + busyColor }
      }
      currentCell.font = { color: { argb: 'FFFFFFFF' } };
    }

    // Paint color sold cranes
    if (crane?.owner == "SOLD") {
      const lastRow = worksheet.lastRow;
      const currentCell = lastRow.getCell(4);
      currentCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF964B00' }
      }
      currentCell.font = { color: { argb: 'FFFFFFFF' } };
    }

    // Put borders on all the cells
    for (let i = 0; i < row.length; i++) {
      const lastRow = worksheet.lastRow;
      const currentCell = lastRow.getCell(i + 1);
      currentCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }

    // Paint dashboard with colors
    idx = 0;
    for (const item of dashboard) {
      const lastRow = worksheet.lastRow;

      if (item.status == "FREE") {
        const currentCell = lastRow.getCell(10 + idx);
        currentCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF' + freeColor }
        }
        idx += 1;
      } else if (item.status == "BUSY" || item.status == "DISMOUNTING") {
        for (let i = 0; i < item.months; i++) {
          const currentCell = lastRow.getCell(10 + idx + i);
          currentCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF' + busyColor }
          }
          currentCell.font = { color: { argb: 'FFFFFFFF' } };
        }
        // currentCell.alignment = { horizontal: 'center', vertical: 'middle' };
        idx += item.months;
      } else if (item.status == "TO_USE") {
        for (let i = 0; i < item.months; i++) {
          const currentCell = lastRow.getCell(10 + idx + i);
          currentCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF' + toUseColor }
          }
        }
        // currentCell.alignment = { horizontal: 'center', vertical: 'middle' };
        idx += item.months;
      }
    }

    // Paint totals with colors
    const lastRow = worksheet.lastRow;
    const busyCell = lastRow.getCell(cols.length - 2);
    const toUseCell = lastRow.getCell(cols.length - 1);

    if (parseInt(busyCell.value) > 0) {
      busyCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF' + busyColor }
      }
      busyCell.font = { color: { argb: 'FFFFFFFF' } };
    }

    if (parseInt(toUseCell.value) > 0) {
      toUseCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF' + toUseColor }
      }
    }

  }

  // Merge cells to join 
  // for (const cells of cells_to_join) {
  //   worksheet.mergeCells(cells);
  // }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, 'Reporte.xlsx');


}

const downloadMonthlyBinnaclesExcel = async ({ reports, crane = "" }) => {

  let totalHours = 0;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Reporte');

  worksheet.addRow([`Reporte mensual de grúa ${crane}`]);
  worksheet.mergeCells("A1:F1");
  const lastRow = worksheet.lastRow;
  const currentCell = lastRow.getCell(1);
  currentCell.alignment = { horizontal: 'center', vertical: 'middle' };

  const cols = ["#", "Semana", "Horas grúa", "Firmado por", "", "Fecha firma"];
  worksheet.addRow(cols);
  worksheet.mergeCells("D2:E2");
  for (let i = 0; i < cols.length; i++) {
    const lastRow = worksheet.lastRow;
    const currentCell = lastRow.getCell(i + 1);
    currentCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  }

  async function fetchImageAsArrayBuffer(url) {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  for (let i = 0; i < reports.length; i++) {
    const report = reports[i];
    const week = report.week;
    const hours = report.hours;
    const reviewerName = report.reviewer;
    const dateSignature = report.date_signature;
    totalHours += hours;

    const startWeek = moment(week.start, "DD/MM/YYYY").format("DD MMM");
    const endWeek = moment(week.end, "DD/MM/YYYY").format("DD MMM");

    let row = [`Sem ${i + 1}`, `${startWeek} - ${endWeek}`, hours, reviewerName, "", dateSignature];
    worksheet.addRow(row);

    if (report.signature) {
      try {
        const imageBuffer = await fetchImageAsArrayBuffer(report.signature);
        const imageId = workbook.addImage({
          buffer: imageBuffer,
          extension: 'png', 
        });

        worksheet.addImage(imageId, {
          tl: { col: 4.1, row: i + 1.6 }, 
          ext: { width: 50, height: 30 },
        });
      } catch (error) {
        console.error('Error al descargar la firma', error);
      }
    }

    // Aplicando bordes a las celdas de la fila actual
    for (let j = 0; j < row.length; j++) {
      const lastRow = worksheet.lastRow;
      const currentCell = lastRow.getCell(j + 1);
      currentCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }
}

  const totals = ["", "Horas totales", totalHours, "", "", ""];
  worksheet.addRow(totals);
  for (let i = 0; i < cols.length; i++) {
    const lastRow = worksheet.lastRow;
    const currentCell = lastRow.getCell(i + 1);
    currentCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `Reporte mensual ${crane}.xlsx`);


}

const downloadWeeklyBinnaclesExcel = async ({ reports, crane = "" }) => {

  const weekdayNames = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Reporte');

  worksheet.addRow([`Reporte semanal de grúa ${crane}`]);
  worksheet.mergeCells("A1:J1");
  const lastRow = worksheet.lastRow;
  const currentCell = lastRow.getCell(1);
  currentCell.alignment = { horizontal: 'center', vertical: 'middle' };

  const cols = ["Día", "Fecha", "Horario de trabajo", "",
    "Horario de comida", "", "Horario grúa detenida por falla", "", "", ""];
  worksheet.addRow(cols);
  worksheet.mergeCells("C2:D2");
  worksheet.mergeCells("E2:F2");
  worksheet.mergeCells("G2:H2");
  for (let i = 0; i < cols.length; i++) {
    const lastRow = worksheet.lastRow;
    const currentCell = lastRow.getCell(i + 1);
    currentCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  }

  const subCols = ["", "", "H Entrada", "H salida", "H Entrada", "H salida", "H Entrada", "H salida",
    "Horas grúa del dia", "Horas operador del día", "Revisó", "", "Firma"];
  worksheet.addRow(subCols);
  for (let i = 0; i < subCols.length; i++) {
    const lastRow = worksheet.lastRow;
    const currentCell = lastRow.getCell(i + 1);
    currentCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  }

  async function fetchImageAsArrayBuffer(url) {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  let totalCraneHours = 0;
  let totalOperatorHours = 0;

  for (let i = 0; i < reports.length; i++) {
    const report = reports[i];
    const weekday = (report?.weekday != null && report?.weekday != undefined) ? weekdayNames[report.weekday] : "";
    const date = moment.utc(report.date, "DD-MM-YYYY").format("DD-MMM");
    const workStart = (report?.work_time && report?.work_time?.start) ? report.work_time.start : "";
    const workEnd = (report?.work_time && report?.work_time?.end) ? report.work_time.end : "";
    const mealStart = (report?.meal_time && report?.meal_time?.start) ? report.meal_time.start : "";
    const mealEnd = (report?.meal_time && report?.meal_time?.end) ? report.meal_time.end : "";
    const failStart = (report?.fail_time && report?.fail_time?.start) ? report.fail_time.start : "";
    const failEnd = (report?.fail_time && report?.fail_time?.end) ? report.fail_time.end : "";
    const workHours = report?.work_hours ? report.work_hours : 0;
    const mealHours = report?.meal_hours ? report.meal_hours : 0;
    const failHours = report?.fail_hours ? report.fail_hours : 0;
    const craneHours = workHours - mealHours - failHours;
    const operatorsHours = workHours - mealHours;
    const reviewer = report.reviewer;
    if (report.signature) {
      try {
        const imageBuffer = await fetchImageAsArrayBuffer(report.signature);
        const imageId = workbook.addImage({
          buffer: imageBuffer,
          extension: 'png', 
        });
  
        worksheet.addImage(imageId, {
          tl: { col: 12, row: reports.indexOf(report) + 3 },
          ext: { width: 50, height: 30 },
        });
      } catch (error) {
        console.error('Error al descargar la firma', error);
      }
    } else {
      const lastRow = worksheet.lastRow;
      const currentCell = lastRow.getCell(11);
      currentCell.value = "";
      currentCell.alignment = { horizontal: 'center', vertical: 'middle' };
    }

    totalCraneHours += craneHours;
    totalOperatorHours += operatorsHours;

    let row = [weekday, date, workStart, workEnd, mealStart, mealEnd, failStart, failEnd, craneHours, operatorsHours, reviewer, "", ""];
    worksheet.addRow(row);

    for (let i = 0; i < row.length; i++) {
      const lastRow = worksheet.lastRow;
      const currentCell = lastRow.getCell(i + 1);
      currentCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }
  }

  const totals = ["", "", "", "", "", "", "", "", totalCraneHours, totalOperatorHours];
  worksheet.addRow(totals);
  for (let i = 0; i < totals.length; i++) {
    const lastRow = worksheet.lastRow;
    const currentCell = lastRow.getCell(i + 1);
    currentCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `Reporte semanal ${crane}.xlsx`);


}


export { downloadCSV, downloadScheduleExcel, downloadMonthlyBinnaclesExcel, downloadWeeklyBinnaclesExcel, generatePDF };