import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDelete } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/comercial/cranes/List.view";
import Error from "components/helpers/Error";
import { DELETE_CRANE_COMMERCIAL } from "seed/gql/queries";

const getFilterString = (data) => {

  const crane = data.crane;
  const owners = data.owner;

  let ownerFilters = [];
  let filters = ["in_use=true"];

  if (owners.GROKE == true) {
    ownerFilters.push(`owner=GROKE`);
  }
  if (owners.RENTED == true) {
    ownerFilters.push(`owner=RENTED`);
  }
  if (owners.SOLD == true) {
    ownerFilters.push(`owner=SOLD`);
  }
  if (crane != null && typeof crane == 'number') {
    filters.push(`id=${crane}`);
  }

  if (ownerFilters.length > 0) {
    if (ownerFilters.length >= 2) {
      filters.push("(" + ownerFilters.join(" OR ") + ")");
    } else {
      filters.push(ownerFilters.join(" OR "));
    }
  }

  return filters.join(" AND ");
}

const CraneList = ({ search, filters, qCranes }) => {

  const filterString = getFilterString({ "crane": search, "owner": filters["owner"] })
  const reqCranes = useQuery(`{
      craneCommercials {
        craneId
        model
        number
        series
        inUse
        owner
        status
      }
    }`,
    filterString,
    { orderBy: "owner" }
  );

  const [callDelete, reqDelete] = useDelete(DELETE_CRANE_COMMERCIAL, {
    onCompleted: () => {
      reqCranes.refetch();
      qCranes.refetch();
    }
  });


  if (reqCranes.loading) return <Loading />;
  if (reqCranes.error) return <Error />;

  const cranes = reqCranes.data.craneCommercials;
  const filteredCranes = filters["family"] == "all" ? cranes :
    cranes.filter((crane) => crane.model.startsWith(filters["family"]));

  const onDelete = (order) => {
    let confirm = window.confirm("¿Está seguro de eliminar la grúa?");
    if (confirm) {
      callDelete({ id: order.id });
    }
  };


  return <View cranes={filteredCranes} onDelete={onDelete} />;

}

CraneList.propTypes = {
  search: PropTypes.string,
  statusFilters: PropTypes.object,
  filters: PropTypes.object,
  inUseFilters: PropTypes.object,
  orderStatusFilters: PropTypes.object,
  qCranes: PropTypes.object
};

export default CraneList;