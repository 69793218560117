import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

const SecurityRevisionFormView = ({ 
  securityRevision = {}, 
  orders = [], 
  onSubmit, 
  error, 
  searchBy, 
  setSearchBy,
  cranes
}) =>
<div class="card">
  {/* Header */}
  <div class="card-header">
    <h3 class="card-header-title">
      {securityRevision.id ? "Editar" : "Nueva"} Revisión de Seguridad
    </h3>
  </div>

  {/* Body */}
  <div class="card-body">
    <div class="row">
      <div class="col">
        <Formik
          initialValues={securityRevision}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div class="mb-3">
                <div class="form-group">
                  <label class="input-label">Revisión por</label>
                  <Field
                    as="select"
                    name="revisionBy"
                    className="form-control"
                    onChange={(e) => setSearchBy(e.target.value)}
                  >
                    <option value="Orden">Órden</option>
                    <option value="Grua">Grúa</option>
                  </Field>
                </div>

                {/* Crane or Orders */}
                {searchBy === "Grua" ? (
                  <div class="form-group">
                    <div>
                      <label class="input-label">Grúas</label>
                      <Typeahead
                        id="menu"
                        placeholder="Buscar Grúas"
                        labelKey={(crane) => `${crane?.series} - ${crane?.model} ${crane?.number}`}
                        onChange={(selected) => {
                          if (selected.length > 0) {
                            setFieldValue("crane", selected[0].id);
                            setFieldValue("craneModel", selected[0].model);
                          } else {
                            setFieldValue("crane", undefined);
                            setFieldValue("craneModel", undefined);
                          }
                        }}
                        options={cranes}
                      />
                    </div>
                  </div>
                ) : (
                  <div class="form-group">
                    <div>
                      <label class="input-label">Órdenes</label>
                      <Typeahead
                        id="menu"
                        placeholder="Buscar Órdenes"
                        labelKey={(order) => `${order?.crane?.series} - ${order?.crane?.model} ${order?.crane?.number}`}
                        onChange={(selected) => {
                          if (selected.length > 0) {
                            setFieldValue("order", selected[0].id);
                            setFieldValue("craneModel", selected[0].crane.model); 
                          } else {
                            setFieldValue("order", undefined);
                            setFieldValue("craneModel", undefined);
                          }
                        }}
                        options={orders}
                      />
                    </div>
                  </div>
                )}

              </div>
              {error ? (
                <div class="alert alert-soft-danger">{error}</div>
              ) : null}
              <button type="submit" class="btn btn-block btn-primary">
                Crear Revisión de Seguridad
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  </div>
</div>;

SecurityRevisionFormView.propTypes = {
  securityRevision: PropTypes.object,
  orders: PropTypes.array,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  searchBy: PropTypes.string,
  setSearchBy: PropTypes.func,
  cranes: PropTypes.array
};

export default SecurityRevisionFormView;
