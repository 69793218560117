import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/arris/binnacles_validation/List.view";
import { formatEnumFilters, formatFilters, formatSearchFilters } from "components/utils/filters";

const BinnacleList = ({ search, statusFilters, orderDate }) => {
    
    const pageSize = 15;
    const [pageNum, setPageNum] = useState(1);

    const reqBinnaclesToken = usePagination(
        `{
            binnacleTokenPagination {
                totalPages
                binnacleTokens {
                    reviewerName
                    createdAt
                    status
                    tokenIdArrisBinnacles {
                        eatHours
                        failuresHours
                        hoursWorked
                        date
                        operator {
                            order{
                                buildingName
                                crane{
                                    model
                                    series
                                }
                            }
                            user{
                                firstName
                            }
                        }
                    }
                }
            }
        }`,
        pageNum,
        pageSize,
        formatFilters([
            formatEnumFilters(statusFilters, "status"),
            formatSearchFilters(search, 
                ["tokenIdArrisBinnacles.operator.order.crane.model", "tokenIdArrisBinnacles.operator.order.crane.series", "tokenIdArrisBinnacles.operator.order.buildingName"]
            )
        ]),
        {orderBy: orderDate + "created_at"}
    );

    if (reqBinnaclesToken.loading) return <Loading />;
    if (reqBinnaclesToken.error) return "Error";

    const { binnacleTokens, totalPages= 0 } = reqBinnaclesToken.data.binnacleTokenPagination;

    const onClickPage = (pageNum) =>
        setPageNum(pageNum);

    return <View
                search={search}
                statusFilters={statusFilters}
                pageNum={pageNum}
                totalPages={totalPages}
                onClickPage={onClickPage}
                binnacleTokens={binnacleTokens}
            />;

}

BinnacleList.propTypes = {
    search: PropTypes.string,
    statusFilters: PropTypes.object,
    orderDate: PropTypes.string
};

export default BinnacleList;