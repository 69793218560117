import React from "react";
import View from "components/arris/binnacles_validation/Details.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

function Details({ binnacleTokenId }) {

    const qBinnacleInfo = useQuery(
        `{
            arrisBinnacles{
                date
                hoursWorked
                eatHours
                failuresHours
                status
                tokenId{
                    reviewerName
                }
                operator{
                    user{
                        firstName
                    }
                    order{
                        buildingName
                        clientName
                        crane{
                            model
                            number
                            series
                        }
                    }
                }
            }
        }`,
        "token_id = " + binnacleTokenId
    );

    if (qBinnacleInfo.loading) return <Loading />;
    if (qBinnacleInfo.error) return "Error";
    
    const { arrisBinnacles = [] } = qBinnacleInfo.data;

    const allApprovedOrRejected = arrisBinnacles.every(
        (arrisBinnacle) =>
          arrisBinnacle.status === "APPROVED" || arrisBinnacle.status === "REJECTED"
    );

    return <View 
                arrisBinnacles={arrisBinnacles}
                allApprovedOrRejected={allApprovedOrRejected}
            />;
}

Details.propTypes = {};

export default Details;