import React from "react";
import PropTypes from "prop-types";
import Schedule from "components/comercial/schedule/Schedule";

const ScheduleEmbeddedView = () =>
  <div>
    <Schedule />
  </div>;

ScheduleEmbeddedView.propTypes = {};

export default ScheduleEmbeddedView;