import React, { useState, useRef } from "react";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/security_stock/SecurityStock.view";

const SecurityStock = () => {

    const searchRef = useRef(null);
    const [search, setSearch] = useState("");
    const [orderDate, setOrderDate] = useState("-");
    
    const handleChange = (value) => setSearch(value);

    const qCranes = useQuery(`{
        cranes {
            series
            number
            model
        }
    }`, "in_use=true");

    if(qCranes.loading) return <Loading/>;
    if(qCranes.error) return <Error/>;

    const { cranes } = qCranes.data;

    return <View 
            search={search}
            cranes={cranes}
            searchRef={searchRef}
            orderDate={orderDate}
            setOrderDate={setOrderDate}
            handleChange={handleChange}
        />;
};


SecurityStock.propTypes = {};

export default SecurityStock;