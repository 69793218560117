import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { months } from "components/utils/constants";
import { difference, gte, lte, lt, gt, eq } from "components/comercial/utils/dates"
import { addMonths } from "components/comercial/utils/dates";
import "styles/css/Schedule.css";

const createMonthArray = (startMonth, startYear, boardSize) => {

  const array = [];
  const actualDate = moment.utc(`${startYear}-${startMonth}-01`, "YYYY-MM-DD");

  for (let i = 0; i < boardSize; i++) {
    let newDate = actualDate.clone();
    newDate.add(i, 'months')

    const month = newDate.month() + 1;
    const year = newDate.year();

    array.push({ month, year });
  }

  return array;

}

const getCraneStatus = (crane) => {

  const schedules = crane.craneSchedules;
  const actualDate = {
    "month": new Date().getMonth() + 1,
    "year": new Date().getFullYear()
  }

  for (const schedule of schedules) {
    const startDate = {
      "month": schedule.startMonth,
      "year": schedule.startYear
    }
    const endDate = addMonths(startDate, schedule.months - 1)

    if (gte(actualDate, startDate) && lte(actualDate, endDate) && schedule.status == "BUSY") {

      const monthsDiff = difference(endDate, actualDate);
      if (monthsDiff <= 3) {
        return "DISMOUNT";
      } else {
        return "BUSY";
      }
    }
  }

  return "FREE";

}

const getCraneDashboard = (onOpenForm, crane, startMonth, startYear, periodSize) => {

  const dashboard = [];
  let actualDate = { "month": startMonth, "year": startYear };
  const endDate = addMonths(actualDate, periodSize);
  const craneStatus = getCraneStatus(crane);

  while (lt(actualDate, endDate)) {

    const activeSchedule = getScheduleInMonth(crane, actualDate);
    if (activeSchedule) {
      const startDateSchedule = { "month": activeSchedule.startMonth, "year": activeSchedule.startYear };
      const endDateSchedule = addMonths(startDateSchedule, activeSchedule.months - 1);

      let monthsToJoin = 0;
      while (lt(actualDate, endDate) && lte(actualDate, endDateSchedule)) {
        monthsToJoin += 1;
        actualDate = addMonths(actualDate, 1);
      }

      let colorClass;
      let textColor;
      if (craneStatus === "DISMOUNT") {
        colorClass = "warning"; 
        textColor = "text-dark";
      } else if (activeSchedule.status === "BUSY") {
        colorClass = "primary"; 
        textColor = "text-white";
      } else {
        colorClass = "success"; 
        textColor = "text-white";
      }

      dashboard.push(
        <td className={`bg-${colorClass} ${textColor} p-1 text-center`}	
          colSpan={monthsToJoin} style={{ wordBreak: "break-word" }}>
          <Link to={`/${activeSchedule.id}/edit`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            {activeSchedule.building} - {activeSchedule.client} <i class="fas fa-edit ml-2"></i>
          </Link> <br /><small>{activeSchedule.state}</small>
        </td>
      );

    } else {
      const auxDate = { ...actualDate }
      dashboard.push(
        <td className="bg-danger free" style={{ cursor: "pointer" }}
          onClick={() => onOpenForm(auxDate.month, auxDate.year, crane.id)}></td>
      )
      actualDate = addMonths(actualDate, 1);
    }

  }

  return dashboard;

}

const getScheduleInMonth = (crane, actualDate) => {
  const schedules = crane.craneSchedules;
  for (const schedule of schedules) {
    const startDate = {
      "month": schedule.startMonth,
      "year": schedule.startYear
    }
    const endDate = addMonths(startDate, schedule.months - 1)

    if (gte(actualDate, startDate) && lte(actualDate, endDate)) {
      return schedule
    }
  }
  return null;
}

const getCraneOcupation = (crane, startMonth, startYear, periodSize) => {

  let busy = 0, toUse = 0;
  const dashboard = [];
  let actualDate = { "month": startMonth, "year": startYear };
  const endDate = addMonths(actualDate, periodSize);

  while (lt(actualDate, endDate)) {

    const activeSchedule = getScheduleInMonth(crane, actualDate);
    if (activeSchedule) {
      const startDateSchedule = { "month": activeSchedule.startMonth, "year": activeSchedule.startYear };
      const endDateSchedule = addMonths(startDateSchedule, activeSchedule.months - 1);
      getCraneStatus(crane);

      let monthsToJoin = 0;
      while (lt(actualDate, endDate) && lte(actualDate, endDateSchedule)) {
        monthsToJoin += 1;
        actualDate = addMonths(actualDate, 1);
      }

      if (activeSchedule.status == "BUSY") {
        busy += monthsToJoin;
      } else if (activeSchedule.status == "TO_USE") {
        toUse += monthsToJoin;
      }

      dashboard.push(
        <td className={`bg-${activeSchedule.status == "BUSY" ? "primary" : "success"} text-white p-1 text-center`}
          colSpan={monthsToJoin} style={{ wordBreak: "break-word" }}>
          <Link to={`/${activeSchedule.id}/edit`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            {activeSchedule.client} <i class="fas fa-edit ml-2"></i>
          </Link>
        </td>
      );

    } else {
      dashboard.push(<td className="bg-danger"></td>)
      actualDate = addMonths(actualDate, 1);
    }

  }

  return { "toUse": toUse, "busy": busy, "total": toUse + busy };
}

const getCraneDeparture = (crane) => {

  const actualDate = {
    "month": new Date().getMonth() + 1,
    "year": new Date().getFullYear()
  }
  let schedules = [...crane.craneSchedules];
  schedules = schedules.filter(schedule => schedule.status == "BUSY");
  schedules = schedules.filter((schedule) => {
    const startDate = { "month": schedule.startMonth, "year": schedule.startYear }
    const endDate = addMonths(startDate, schedule.months - 1)
    if (!lt(endDate, actualDate)) return schedule;
  });
  if (schedules.length == 0) return "Libre";

  schedules.sort((a, b) => {
    const startDateA = { "month": a.startMonth, "year": a.startYear }
    const startDateB = { "month": b.startMonth, "year": b.startYear }
    if (gt(startDateA, startDateB)) return 1;
    if (lt(startDateA, startDateB)) return -1;
    return 0;
  })

  let auxSchedule = null
  for (const schedule of schedules) {
    const startDate = { "month": schedule.startMonth, "year": schedule.startYear }
    if (lte(startDate, actualDate)) {
      auxSchedule = schedule;
      break;
    }
  }

  if (!auxSchedule) auxSchedule = schedules[0];

  const startDate = { "month": auxSchedule.startMonth, "year": auxSchedule.startYear };
  const endDate = addMonths(startDate, auxSchedule.months - 1);

  return `${months[endDate.month - 1]} ${endDate.year.toString().substring(2, 4)}`

}


const ListView = ({
  cranes,
  isMobile,
  startMonth,
  startYear,
  boardSize,
  onOpenForm,
  onBackHistory,
  onNextHistory,
}) =>
  <>
    <div className="table-responsive mb-5" style={{ height: isMobile ? "auto" : "68vh" }}>

      <table className="table table-borderless table-thead-bordered table-align-middle card-table">

        <thead className="thead-light text-nowrap">
          <tr role="row">

            <th className={`align-text-top ${isMobile && "py-0"}`}>
              <div className="d-flex justify-content-between mt-2">
                Grúa
                <div className="d-flex align-items-center mt-2" style={{ marginRight: "-45px" }}>
                  <i class="fas fa-angle-left mr-2" onClick={() => onBackHistory(1)}
                    style={{ cursor: "pointer" }}></i>
                  <i class="fas fa-angle-double-left" onClick={() => onBackHistory(6)}
                    style={{ cursor: "pointer" }}></i>
                </div>
              </div>
            </th>

            <th className={`${isMobile && "py-0"}`}>
              <table className="table">
                <thead>
                  <tr>
                    {createMonthArray(startMonth, startYear, boardSize).map((item, idx) => (
                      <td key={idx}>{months[item.month - 1]} {item.year.toString().substring(2, 4)}</td>
                    ))}
                  </tr>
                </thead>
              </table>
            </th>

            <th className={`${isMobile && "py-0"}`}>
              <div className="d-flex justify-content-between p-0">
                <div className="d-flex align-items-center"
                  style={{ margin: isMobile ? "-80px 30px 0 -30px" : "-20px 30px 0 -45px" }}>
                  <i class="fas fa-angle-right mr-2" onClick={() => onNextHistory(1)}
                    style={{ cursor: "pointer" }}></i>
                  <i class="fas fa-angle-double-right" onClick={() => onNextHistory(6)}
                    style={{ cursor: "pointer" }}></i>
                </div>
                {!isMobile && <>En uso / <br /> Por utilizar / <br /> Total</>}
              </div>
            </th>

            <th className={`align-text-top ${isMobile && "py-0"}`}>Salida</th>

          </tr>
        </thead>

        <tbody>
          {cranes.map((crane, idx) => (
            <tr className="justify-content-center text-reset" rolw="row" key={crane.id}
              style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}
            >

              <td className="text-nowrap">
                <b>{crane.series}</b>{" / " + crane.model + "" + crane.number}
                {function () {
                  const status = getCraneStatus(crane);
                  if (status == "BUSY") {
                    return <span className="badge badge-primary ml-3">En uso</span>;
                  } else if (status == "FREE") {
                    return <span className="badge badge-danger ml-3">Libre</span>
                  } else if (status == "DISMOUNT") {
                    return <span className="badge badge-warning ml-3">Por desmontar</span>
                  }
                  return <></>;
                }()}
              </td>

              <td className="dahsboard">
                <table className="table"
                  style={{ borderSpacing: "5px", borderCollapse: "separate", tableLayout: "fixed", marginBottom: "0px" }}>
                  <tbody>
                    <tr className="bg-danger" style={{ visibility: "collapse" }}>
                      {Array.from({ length: boardSize }).map((idx) => (
                        <td key={idx} ></td>
                      ))}
                    </tr>
                    <tr>
                      {getCraneDashboard(onOpenForm, crane, startMonth, startYear, boardSize).map((item) => (
                        item
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>

              <td className="text-nowrap">
                {function () {
                  const data = getCraneOcupation(crane, startMonth, startYear, boardSize);
                  return (
                    <p className="ml-2">
                      <span class="badge badge-light border">{data.busy}</span>
                      <span class="badge badge-light border">{data.toUse}</span>
                      <span class="badge badge-light border">{data.total}</span>
                    </p>
                  );
                }()}
              </td>

              <td className="text-nowrap">{getCraneDeparture(crane)}</td>

            </tr>
          ))}
        </tbody>

      </table>
    </div>
  </>;


ListView.propTypes = {
  cranes: PropTypes.array.isRequired,
  startMonth: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired,
  boardSize: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  onOpenForm: PropTypes.func.isRequired,
  onBackHistory: PropTypes.func.isRequired,
  onNextHistory: PropTypes.func.isRequired,
  onChangePageNum: PropTypes.func.isRequired
};

export default ListView;