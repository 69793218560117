import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { usePost } from "seed/api";
import View from "components/arris_binnacle/analytics/ExtraWorkedHoursTable.view";

function ExtraWorkedHoursTable({ filters, grouped }) {

  const [data, setData] = useState({});

  const [callData, reqData] = usePost('/arris_binnacles/get_extra_worked_hours', {
    onCompleted: (data) => {
      setData(data);
    },
    onError: (err) => {
      console.error(err);
      swal("Error", "Error al obtener la informacion, intente de nuevo mas tarde", "error")
    }
  });

  useEffect(() => {
    callData({
      size: 10,
      filters: filters,
      actual_month: new Date().getMonth() + 1,
      actual_year: new Date().getFullYear(),
      grouped_by: grouped
    })
  }, [filters])

  const loading = reqData.loading;
  return <View data={data} grouped={grouped} loading={loading} />;
}

ExtraWorkedHoursTable.propTypes = {
  filters: PropTypes.object.isRequired,
  grouped: PropTypes.string.isRequired,
};

export default ExtraWorkedHoursTable;