import React, {useState} from "react";
import PropTypes from "prop-types";
import { Loading } from "seed/helpers";
import View from "components/security_revisions/Form.view";
import { useGet, usePost } from "seed/api";

const SecurityRevisionFormSave = ({ onCompleted = () => null, onError = () => null }) => {
  
  const [searchBy, setSearchBy] = useState("Orden");

  const [callResetRevisionDates, reqCallResetRevisionDates] = usePost("/security_revisions/reset_revision_dates", {
    onCompleted: (data) => {
      window.location.replace("/security_revisions");
    },
    onError: (error) => {}
  });

  const [callSave, qSave] = usePost("/security_revisions/create_revision", {
    onCompleted: (data) => {
      callResetRevisionDates({"revision_id": data.id_initial});
      callResetRevisionDates({"revision_id": data.id_final});
    }
  });

  const qOrders = useGet("/orders/orders_without_sec_revision");
  const qCranes = useGet("/cranes");
  if(qOrders.loading) return <Loading/>;
  if(qCranes.loading) return <Loading/>;

  const error = qSave.error ? "An error has occurred" : null;
  const orders = qOrders.data??[];
  const cranes = qCranes.data??[];

  const onSubmit = (values) => {
    values.status = "CREATED";
    values.active = true;

    if (searchBy === "Orden") {
      values.type = "ORDER";
    }

    if (searchBy === "Grua") {
      values.type = "CRANE";
    }

    callSave(values);
  }

  const filteredOrders = orders.filter(order => order.crane !== null);

  return <View
    cranes={cranes}
    orders={filteredOrders}
    error={error}
    searchBy={searchBy}
    setSearchBy={setSearchBy}
    onSubmit={onSubmit}
  />;
  
}

SecurityRevisionFormSave.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func
};

export default SecurityRevisionFormSave;