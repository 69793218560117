import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SecurityStockListView = ({ stockGroups, onDeleteGroup }) => (
    <div style={{ height: "65vh", overflowY: "auto", overflowX: "hidden" }}>
      {stockGroups.length == 0 ? <div className="text-center h5 mt-2">No hay grupos de piezas registrados</div> : 
       stockGroups.map((group, idx) =>
        <div className="row border mx-1 text-reset" key={group.id}
          style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}>

          <div className="col-md-3 p-2 text-center">
            {group?.crane?.series ? group?.crane?.series : "SIN ASIGNAR"}
             {" / " + (group?.crane?.model + group?.crane?.number ? group?.crane?.model + group?.crane?.number : "SIN ASIGNAR")}
          </div>

          <div className="col-md-2 p-2 text-center">
            {group.name}
          </div>

          <div className="col-md-2 p-2 text-center">
            {(new Date(group.createdAt)).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}
          </div>

          <div className="col-md-2 p-2 text-center">
            {group?.items?.length}
          </div>

          <div className="col-md-3 p-2 d-flex align-items-center justify-content-center">
            <Link
              to={`/${group.id}`}>
              <button
                className="btn btn-secondary btn-lg px-3 py-1"
              >
                Ver piezas
              </button>
            </Link>

            {/* Contenedor de íconos con ancho fijo */}
            <div className="icon-container d-flex justify-content-start align-items-center ml-2" style={{ minWidth: "120px" }}>
              <a className="text-danger ml-2" >
                <i className="fa fa-trash" onClick={() => onDeleteGroup(group.id)}></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
);

SecurityStockListView.propTypes = {
    stockGroups: PropTypes.array
};

export default SecurityStockListView;