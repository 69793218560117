import React from 'react';
import View from 'components/shippings/transport_companies/TransportCompanies.view';
import PropTypes from 'prop-types';
import { DELETE_TRANSPORT_COMPANY } from 'seed/gql/queries';
import { useQuery, useDelete } from "seed/gql";
import { Loading } from 'seed/helpers';
import swal from 'sweetalert';

const TransportCompanies = () => {

    const reqCompanies = useQuery(`{
        transportCompanies {
            id 
            name
        }
    }`);

    const [callDeleteCompany, setCallDeleteCompany] = useDelete(DELETE_TRANSPORT_COMPANY, {
        onCompleted: (data) => {
            reqCompanies.refetch();
        },
        onError: (error) => {
          swal(
            "Error al eliminar la compañía de transporte",
            "Verifique los datos y vuelva a intentarlo",
            "error"
          );
        },
    });

    if (reqCompanies.loading) return <Loading />;
    if (reqCompanies.error) return "Error";

    const { transportCompanies = [] } = reqCompanies.data;

    const onDeleteCompany = (id) => {
        swal({
            title: "¿Está seguro?",
            text: "Una vez eliminada, no podrá recuperar la compañía de transporte",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              callDeleteCompany({ id: id });
            }
        });
    };

    return <View
                onDeleteCompany={onDeleteCompany}    
                transportCompanies={transportCompanies} 
            />;
};

TransportCompanies.propTypes = {};

export default TransportCompanies;
