import React from "react";
import { useEffect, useRef } from "react";
import View from "components/data_analytics/DataBI.view";

const DataBI = () => {

    const pathname = window.location.pathname;

    const containerRef = useRef(null);

    const urlsBi = {
        "/analytics/general_report": "https://app.powerbi.com/reportEmbed?reportId=0fe418ff-48a6-48de-a4f1-cb9984fa7d41&autoAuth=true&ctid=9dc46dac-dcca-4fac-8caa-13187325af21&pageName=06aabbcd90dac8425fda",
        "/analytics/crane_report": "https://app.powerbi.com/reportEmbed?reportId=0fe418ff-48a6-48de-a4f1-cb9984fa7d41&autoAuth=true&ctid=9dc46dac-dcca-4fac-8caa-13187325af21&pageName=42fbc0c9b06beba5a625",
        "/analytics/performance_report": "https://app.powerbi.com/reportEmbed?reportId=0fe418ff-48a6-48de-a4f1-cb9984fa7d41&autoAuth=true&ctid=9dc46dac-dcca-4fac-8caa-13187325af21&pageName=8e1baa52339a11c3049a"
    }

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollTop = (container.scrollHeight - container.clientHeight) / 2;
        }
    }, []);

    const urlBI = urlsBi[pathname]
    return <View urlBI={urlBI} containerRef={containerRef} />;
}

DataBI.propTypes = {};

export default DataBI;