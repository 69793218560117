import React from "react";
import PropTypes from "prop-types";
import { Modal } from "seed/helpers";
import "styles/css/Analytics.css"
import { months } from "components/utils/constants";
import Details from "components/comercial/analytics/Details";

const ListView = ({ data, type, actualMonth, actualYear, isMobile, cranes, showDetails, onClose, openModal }) => (
  <>
    <div className="table-responsive">
      <table className="table table-align-middle" id="analytics">

        <thead className="thead-light">
          <tr>
            <th></th>
            {data.map((item, idx) => (
              <th key={idx}>{months[item.month - 1]} {item.year}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>Grúas libres</th>
            {data.map((item, idx) => (
              <td key={idx} onClick={() => openModal(item.data.free, "FREE", item.month, item.year)}>
                {item.data.free.length}
              </td>
            ))}
          </tr>
          <tr>
            <th>Grúas en uso</th>
            {data.map((item, idx) => (
              <td key={idx} onClick={() => openModal(item.data.busy, "BUSY", item.month, item.year)}>
                {item.data.busy.length}
              </td>
            ))}
          </tr>
          <tr>
            <th>Grúas por montarse</th>
            {data.map((item, idx) => (
              <td key={idx}></td>
            ))}
          </tr>
          <tr>
            <th>Grúas por desmontarse</th>
            {data.map((item, idx) => (
              <td key={idx} onClick={() => openModal(item.data.dismounting, "DISMOUNTING", item.month, item.year)}>
                {item.data.dismounting.length}
              </td>
            ))}
          </tr>
          <tr>
            <th><b>Total</b></th>
            {data.map((item, idx) => (
              <th key={idx}><b>
                {item.data.free.length + item.data.busy.length + item.data.dismounting.length}
              </b></th>
            ))}
          </tr>
        </tbody>

      </table>
    </div>

    {showDetails && (
      <Modal
        width={isMobile ? window.innerWidth - 50 : 600}
        height={isMobile ? window.innerHeight - 50 : 600}
        onClose={onClose}
        component={Details}
        data={cranes}
        type={type}
        month={actualMonth}
        year={actualYear}
        isMobile={isMobile}
      />
    )}
  </>
);

ListView.propTypes = {
  data: PropTypes.array.isRequired,
  cranes: PropTypes.array,
  type: PropTypes.string.isRequired,
  showDetails: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openModal: PropTypes.func
};

export default ListView;