import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/comercial/analytics/Details.view";

function Details({ data, type, month, year, isMobile }) {

  const query = data.map((crane) => `id=${crane}`).join(" OR ");
  const reqCranes = useQuery(`{
    craneCommercials {
      series
      number
      model
      craneSchedules {
        client
        building
        state
        months
        startMonth
        startYear
        createdAt
      }
    }
  }`, query)

  if (reqCranes.loading) return <Loading />;
  if (reqCranes.error) return <Error />;

  const cranes = data.length > 0 ? reqCranes.data.craneCommercials : [];
  return <View type={type} cranes={cranes} month={month} year={year} isMobile={isMobile}/>;
}

Details.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

export default Details;