import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import { BrowserRouter } from "react-router-dom";
import { craneFamilies } from "components/utils/constants"
import FormSave from "components/comercial/schedule/FormSave";
import FormSet from "components/comercial/schedule/FormSet";
import ModalRoute from "components/helpers/ModalRoute";
import CraneList from "components/comercial/schedule/List";
import Sync from "components/comercial/schedule/Sync";
import { Link } from "react-router-dom";

const ScheduleView = ({
  clients,
  isMobile,
  states,
  filters,
  buildings,
  searchRef,
  onClose,
  handleChange,
  cranes,
  search,
  startMonth,
  startYear,
  boardSize,
  showFilters,
  setShowFilters,
  onDownloadReport,
  onSelectFamilyTab,
  onBackHistory,
  onNextHistory,
  onChangeFilter
}) =>
  <BrowserRouter basename="/commercial/schedule">



    <div class={`content container-fluid ${!isMobile && "p-7"}`} style={{ maxHeight: "100vh", overflow: "auto" }}>

      {/* Header */}
      {!isMobile && (
        <div class="page-header pt-4">
          <div class="row align-items-end">
            <div class="col-sm">
              <h1 class="page-header-title">
                Cronograma logístico
              </h1>
            </div>
            <div class={`col-sm-auto ${isMobile ? "p-0 mt-3" : ""}`}>
              <div class="btn-group" role="group">
                <Link to="/sync" className={`btn btn-outline-primary ${isMobile ? "mx-1" : "mx-3"}`} role="button">
                  <i class="fas fa-arrow-alt-circle-up mr-1"></i>Sincronizar datos
                </Link>
                <Link to="/create" className={`btn btn-primary ${isMobile ? "mx-1" : "mx-3"}`} role="button">
                  <i class="tio-add mr-1"></i>Nuevo registro
                </Link>
                <a className="btn btn-primary" role="button" onClick={onDownloadReport}>
                  Descargar Reporte
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        window.innerWidth <= 500 ? (
          <>
            <div className="d-flex justify-content-center mx-1 mb-3">
              <Link to="/create" className="btn btn-sm btn-primary" role="button" style={{ lineHeight: 1.3 }}>
                <i class="tio-add mr-1"></i>Nuevo registro
              </Link>
              <a className="btn btn-sm btn-primary ml-3" role="button"
                onClick={onDownloadReport} style={{ lineHeight: 1.3 }}>
                Descargar Reporte
              </a>
            </div>

            <div className="d-flex justify-content-center mx-1 mb-3">
              <button class={`btn btn-sm btn-${showFilters ? "outline-" : ""}primary ml-3`} type="button"
                data-toggle="collapse" data-target="#filters" style={{ lineHeight: 1.3 }}
                onClick={() => setShowFilters(prev => !prev)}
              >
                {showFilters ? "Ocultar filtros" : "Mostrar filtros"}
              </button>
              <button class={`btn btn-sm btn-primary ml-3`} type="button"
                style={{ lineHeight: 1.3 }}
                onClick={() => setShowFilters(prev => !prev)}
              >
                <i class="fas fa-directions mr-1"></i> Ir a analítica
              </button>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center mx-1 mb-3">
            <Link to="/create" className="btn btn-sm btn-primary" role="button" style={{ lineHeight: 1.3 }}>
              <i class="tio-add mr-1"></i>Nuevo registro
            </Link>
            <a className="btn btn-sm btn-primary ml-3" role="button"
              onClick={onDownloadReport} style={{ lineHeight: 1.3 }}>
              Descargar Reporte
            </a>
            <button class={`btn btn-sm btn-${showFilters ? "outline-" : ""}primary ml-3`} type="button"
              data-toggle="collapse" data-target="#filters" style={{ lineHeight: 1.3 }}
              onClick={() => setShowFilters(prev => !prev)}
            >
              {showFilters ? "Ocultar filtros" : "Mostrar filtros"}
            </button>
            <button class={`btn btn-sm btn-primary ml-3`} type="button"
              style={{ lineHeight: 1.3 }}
              onClick={() => window.location.replace("/commercial/analytics")}
            >
              <i class="fas fa-directions mr-1"></i> Ir a analítica
            </button>
          </div>
        )
      )}

      <div className={`${isMobile && "collapse"} mx-1 mb-0`} id="filters">
        <div className={`${isMobile ? "row mt-3" : "d-flex justify-content-end"}`}>

          <div className={isMobile && "col-6 m-0 p-0"}>
            <div className="form-group mr-3">
              <select
                id="searchBySelect"
                className="form-control"
                onChange={(event) => onChangeFilter(event, "client")}
              >
                <option value="">Todos los clientes</option>
                {
                  clients.map((client, idx) => (
                    <option key={idx} value={client}>{client}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className={isMobile && "col-6 m-0 p-0"}>
            <div className="form-group mr-3">
              <select
                id="searchBySelect"
                className="form-control"
                onChange={(event) => onChangeFilter(event, "building")}
              >
                <option value="">Todas las obras</option>
                {
                  buildings.map((building, idx) => (
                    <option key={idx} value={building}>{building}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className={isMobile && "col-6 m-0 p-0"}>
            <div className="form-group mr-3">
              <select
                id="searchBySelect"
                className="form-control"
                onChange={(event) => onChangeFilter(event, "status")}
              >
                <option value="">Todas los estatus</option>
                <option value="FREE">Libre</option>
                <option value="TO_USE">Por utilizar</option>
                <option value="BUSY">Ocupada</option>
                <option value="DISMOUNTING">Por desmontarse</option>
              </select>
            </div>
          </div>

          {!isMobile && (
            <div className="form-group mr-3">
              <select
                id="searchBySelect"
                className="form-control"
                onChange={(event) => onChangeFilter(event, "owner")}
              >
                <option value="">Todos los propietarios</option>
                <option value="GROKE">Groke</option>
                <option value="RENTED">Rentada</option>
                <option value="SOLD">Vendida</option>
              </select>
            </div>
          )}

          <div className={isMobile && "col-6 m-0 p-0"}>
            <div className="form-group mr-3">
              <select
                id="searchBySelect"
                className="form-control"
                onChange={(event) => onChangeFilter(event, "state")}
              >
                <option value="">Todos los estados</option>
                {
                  states.map((state, idx) => (
                    <option key={idx} value={state}>{state}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className={isMobile && "col-12 m-0 p-0"}>
            <div className={!isMobile ? "float-right" : "mb-3 pr-3"}>
              <Typeahead
                id="menu"
                ref={searchRef}
                placeholder="Buscar Grúas"
                onInputChange={handleChange}
                labelKey={(crane) => `${crane.series} - ${crane.model} ${crane.number}`}
                onChange={(selected) => selected.length > 0 ? handleChange(selected[0].id) : ""}
                options={cranes}
              >
                <div className="rbt-aux">
                  {search.length == 0 && <i className="fa fa-search rbt-aux"></i>}
                  {search.length > 0 && <i className="fa fa-times rbt-close text-danger"
                    style={{ paddingTop: "4px" }} role="button" onClick={() => {
                      searchRef.current.clear()
                      handleChange("")
                    }}></i>}
                </div>
              </Typeahead>
            </div>
          </div>

        </div>
      </div>

      <div className="mx-1 mb-3 pt-1">
        <div className="d-flex flex-wrap">
          {craneFamilies.map((family, idx) => {
            const checked = filters.family == family || (family == "Todos" && filters.family == "all");
            return (
              <button type="button" key={idx}
                onClick={() => onSelectFamilyTab(family)}
                // eslint-disable-next-line max-len
                className={`btn btn-sm btn-${checked ? "" : "outline-"}primary ${isMobile ? "ml-1 mt-2 py-1" : "mx-1 py-1"}`}>
                {family}</button>
            );
          })}
        </div>
      </div>

      {/* List of cranes */}
      <CraneList
        isMobile={isMobile}
        startMonth={startMonth}
        startYear={startYear}
        boardSize={boardSize}
        filters={filters}
        onBackHistory={onBackHistory}
        onNextHistory={onNextHistory}
      />

      <ModalRoute
        path="/create"
        width={isMobile ? window.innerWidth - 50 : 800}
        height={isMobile ? window.innerHeight - 50 : 600}
        onClose={onClose}
        isMobile={isMobile}
        component={FormSave}
      />

      <ModalRoute
        path="/:scheduleId/edit"
        width={isMobile ? window.innerWidth - 50 : 800}
        height={isMobile ? window.innerHeight - 50 : 600}
        onClose={onClose}
        isMobile={isMobile}
        component={FormSet}
      />

      <ModalRoute
        path="/sync"
        width={800}
        height={600}
        onClose={onClose}
        component={Sync}
      />

    </div>

  </BrowserRouter>;

ScheduleView.propTypes = {
  filters: PropTypes.object
};

export default ScheduleView;