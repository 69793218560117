import React from "react";
import PropTypes from "prop-types";
import View from "components/arris/verify/Verify.view";
import { useDetail } from "seed/gql";
import CryptoJS from 'crypto-js';
import { Loading } from "seed/helpers";

function Verify() {
  const searchParams = new URLSearchParams(document.location.search)
  var info = searchParams.get('info')
  var form_id = searchParams.get('form_id') ?? 0

  const qArrisForm = useDetail(`{
    arrisForm {
      status
      onedriveUrl
      onedriveStatus
    } 
  }`, form_id);

  var verifyData = ""

  try {
    // "donjulio70......................" to hex
    var keyHex = CryptoJS.enc.Hex.parse("646f6e6a756c696f37302e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e2e")

    var bytes = CryptoJS.AES.decrypt(info, keyHex, {
      mode: CryptoJS.mode.ECB
    });
    console.log(bytes.toString())
    verifyData = bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log("ERROR");
    console.log(error);
    verifyData = "INFORMACIÓN INVÁLIDA";
  }

  
  if(qArrisForm) {

    if(qArrisForm.loading) return <Loading/>;
    if(qArrisForm.error) return "Error";

    const { arrisForm } = qArrisForm.data;
    return <View
            arrisForm={arrisForm}
            verifyData={verifyData}
          />;
  } else {
    const arrisForm = {}
    return <View
            arrisForm={arrisForm}
            verifyData={verifyData}
          />;
  }
  
}

Verify.propTypes = {};

export default Verify;