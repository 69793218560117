import React from "react";
import PropTypes from "prop-types";
import { SET_CRANE_COMMERCIAL } from "seed/gql/queries";
import View from "components/comercial/cranes/Form.view";
import { useSet, useDetail } from "seed/gql";
import Error from "components/helpers/Error";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import NotFound from "components/helpers/NotFound";

const CraneFormSet = ({ match, onClose }) => {

  const craneId = match.params.craneId;

  const qCrane = useDetail(`{
    craneCommercial {
      craneId
      model
      number
      series
      owner
      status
    }
  }`, craneId);

  const [callSet, reqSave] = useSet(SET_CRANE_COMMERCIAL, {
    onCompleted: (data) => {
      swal("Grúa editada exitosamente", "", "success").then((response) => { if (response) onClose() })
    },
    onError: (error) => {
      console.error(error);
      swal("Error al editar la grúa", "Verifique sus datos y vuelva a intentarlo", "error");
    }
  });

  if (qCrane.loading) return <Loading />;
  if (qCrane.error && qCrane.error.message.includes("matching query does not exist")) return <NotFound />
  if (qCrane.error) return <Error />;

  const crane = qCrane.data.craneCommercial;

  const onSubmit = (values) => {
    callSet({
      ...values,
      id: parseInt(craneId)
    });
  };

  return <View
    crane={crane}
    onSubmit={onSubmit}
    onClose={onClose}
  />;

}

CraneFormSet.propTypes = {
  match: PropTypes.object,
  onClose: PropTypes.func
};

export default CraneFormSet;