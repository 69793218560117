import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/security_stock/List.view";
import { DELETE_STOCK_GROUP } from "seed/gql/queries";
import { useDelete } from "seed/gql";
import swal from "sweetalert";
import { formatFilters, formatSearchFilters } from "components/utils/filters";

const SecurityStockList = ({ search, orderDate }) => {

    const [ callDelete, reqDelete ] = useDelete(DELETE_STOCK_GROUP, {
        onCompleted: () => {
            window.location.reload();
        }
    });

    const qStockGroups = useQuery(`{
        stockGroups {
            name
            description
            createdAt
            crane {
                series
                number
                model
            }
            items{
                item{
                    url
                }
            }
        }
    }`,
    formatFilters([
        formatSearchFilters(search, ["crane.series", "crane.model", "crane.number"])
    ]),
    { orderBy: orderDate + "created_at" });

    if(qStockGroups.loading) return <Loading />;
    if(qStockGroups.error) return "Error";

    const { stockGroups } = qStockGroups.data;

    const onDeleteGroup = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado, no podrás recuperar este grupo de stock",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                callDelete({ id });
            }
        });
    };

    return <View
            stockGroups={stockGroups}
            onDeleteGroup={onDeleteGroup}
            />

};

SecurityStockList.propTypes = {};

export default SecurityStockList;
