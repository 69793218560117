import React from "react";
import PropTypes from "prop-types";
import View from "components/shippings/transport_companies/Form.view";
import { useDetail, useSet } from "seed/gql";
import { Loading } from "seed/helpers";
import { useHistory } from "react-router-dom";
import { SET_TRANSPORT_COMPANY } from "seed/gql/queries";
import swal from 'sweetalert';

const CompanyFormSet = ({ companyId }) => {

    const history = useHistory();

    const [callSetCompany, qSetCompany] = useSet(SET_TRANSPORT_COMPANY, {
        onCompleted: (data) => {
            swal("Compañía de transporte actualizada", " ", "success").then(() => {
                history.push('/companies');
            });
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const qCompany = useDetail(`
    {
        transportCompany {
            name
            socialReason
            rfc
        }
    }`, 
    companyId);

    if (qCompany.loading) return <Loading />
    if (qCompany.error) return "Error";

    const onSubmit = (values) => {
            
        if (!/^[A-Z&Ñ]{3,4}\d{6}(?:[A-Z\d]{3})?$/.test(values.rfc)) {
            swal("RFC inválido", "Por favor, ingrese un RFC válido", "error");
            return;
        }

        callSetCompany({
            ...values,
            id: companyId
        });
    }

    return <View company={qCompany.data.transportCompany} onSubmit={onSubmit} />;
}

CompanyFormSet.propTypes = {
    companyId: PropTypes.number.isRequired,
};

export default CompanyFormSet;