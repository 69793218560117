import React, { useRef, useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { useGet, usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { useHistory } from "react-router";
import Error from "components/helpers/Error";
import { downloadScheduleExcel } from "components/utils/file";
import { craneFamilies } from "components/utils/constants";
import View from "components/comercial/schedule/Schedule.view";

function Schedule() {

  const history = useHistory();
  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [states, setStates] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    client: "",
    building: "",
    state: "",
    crane: null,
    owner: "",
    status: "",
    family: "all"
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 || window.innerHeight <= 450);
  const [boardSize, setBoardSize] = useState(isMobile ? 5 : 12);
  const [startMonth, setStartMonth] = useState(new Date().getMonth() + 1);
  const [startYear, setStartYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768 || window.innerHeight <= 450 );
      setBoardSize(window.innerWidth <= 768 || window.innerHeight <= 450 ? 5 : 12);
    };

    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const reqClients = useGet('/schedules/get_clients', {}, {
    onCompleted: (data) => {
      setClients(data)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const reqBuildings = useGet('/schedules/get_buildings', {}, {
    onCompleted: (data) => {
      setBuildings(data)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const reqStates = useGet('/schedules/get_states', {}, {
    onCompleted: (data) => {
      setStates(data)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const [callReport, reqReport] = usePost('/schedules/report', {
    onCompleted: (data) => {
      downloadScheduleExcel({...data, size: boardSize});
    },
    onError: (error) => {
      console.error(error);
      alert("Ha ocurrido un error")
    }
  })

  const qCranes = useQuery(`{
    craneCommercials {
      series
      number
      model
    }
  }`);

  if (qCranes.loading || reqClients.loading || reqBuildings.loading
    || reqStates.loading) return <Loading />;
  if (qCranes.error) return <Error />;

  const cranes = qCranes.data.craneCommercials;

  const handleChange = (value) => {
    setSearch(value);
    const newFilters = { ...filters };
    newFilters["crane"] = value;
    setFilters(newFilters);
  }

  const onChangeFilter = (event, filter) => {
    const value = event.target.value;
    const newFilters = { ...filters };
    newFilters[filter] = value;
    setFilters(newFilters);
  }

  const onSelectFamilyTab = (family) => {
    let newFilters = { ...filters };
    newFilters.family = family == "Todos" ? "all" : family;
    setFilters(newFilters);
  }

  const onBackHistory = (months) => {
    if (startMonth - months >= 1) {
      setStartMonth(startMonth - months);
    } else {
      months -= startMonth;
      setStartYear(startYear - 1);
      setStartMonth(12 - months);
    }
  }

  const onNextHistory = (months) => {
    if (startMonth + months <= 12) {
      setStartMonth(startMonth + months);
    } else {
      const aux = 12 - startMonth
      months -= aux;
      setStartYear(startYear + 1);
      setStartMonth(months);
    }
  }

  const onDownloadReport = () => {
    callReport({
      start_month: startMonth,
      start_year: startYear,
      size: boardSize
    })
  }

  const onClose = () => {
    history.goBack();
    document.location.href = "/commercial/schedule";
  }

  return (
    <View
      cranes={cranes}
      isMobile={isMobile}
      search={search}
      clients={clients}
      states={states}
      filters={filters}
      onClose={onClose}
      buildings={buildings}
      searchRef={searchRef}
      boardSize={boardSize}
      startMonth={startMonth}
      startYear={startYear}
      showFilters={showFilters} 
      setShowFilters={setShowFilters}
      onSelectFamilyTab={onSelectFamilyTab}
      onDownloadReport={onDownloadReport}
      onBackHistory={onBackHistory}
      onNextHistory={onNextHistory}
      handleChange={handleChange}
      onChangeFilter={onChangeFilter}
    />
  );
}

Schedule.propTypes = {};

export default Schedule;