import React from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { SAVE_CRANE_COMMERCIAL } from "seed/gql/queries";
import View from "components/comercial/cranes/Form.view";
import { useSave } from "seed/gql";

const CraneFormSave = ({ onClose }) => {

  const [callSave, reqSave] = useSave(SAVE_CRANE_COMMERCIAL, {
    onCompleted: (data) => {
      swal("Grúa creada exitosamente", "", "success").then((response) => 
      {if (response) onClose()});
    },
    onError: (error) => {
      console.error(error);
      swal("Error al crear la grúa", "Verifique sus datos y vuelva a intentarlo", "error");
    }
  });

  const onSubmit = (values) => {
    values.status = "COMMERCIAL"
    callSave({
      ...values,
      craneId: values.craneId,
      inUse: true
    });
  };

  return <View
    onSubmit={onSubmit}
    onClose={onClose}
  />;

}

CraneFormSave.propTypes = {
  setCreated: PropTypes.func,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func
};

export default CraneFormSave;