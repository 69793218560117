import React from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement
);

const barChartOptions = {
  responsive: true,
  indexAxis: 'y',
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Horas totales trabajadas',
    },
  },
};

const ExtraWorkedHoursLineChartView = ({ data, loading = false, monthOptions, onMonthChange, initialMonth }) => (
  <>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h3 className="text-center">Horas extras trabajadas</h3>
      <select 
        className="form-control ml-7"
        value={initialMonth}
        onChange={(e) => onMonthChange(e.target.value)}
        style={{width: 'auto'}}
      >
        {monthOptions.map((month, index) => (
          <option key={index} value={index}>
            {month}
          </option>
        ))}
      </select>
      {loading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : <div></div>}
    </div>
    <Bar options={barChartOptions} data={data} />
  </>
);

ExtraWorkedHoursLineChartView.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default ExtraWorkedHoursLineChartView;