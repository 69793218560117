import React, { useEffect, useState } from "react";
import { useGetCall } from "seed/api";
import { detect } from "detect-browser";
import View from "components/Home.view";

const Home = () => {
  const [isAuth, setIsAuth] = useState(false)
  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => setIsAuth(true),
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/login"); // IMPORTANT: Switch to normal login (e.g /login) when copying
    }
  })
  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
      sessionStorage.setItem("rol", localStorage.getItem("rol"));
    }
    const browser = detect();
    if (!browser || browser.name != 'chrome') {
      // alert("Para un correcto funcionamiento del sistema se recomienda utilizar el navegador *Google Chrome*");
    }
    callAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (!isAuth) return null;
  return <View rol={sessionStorage.getItem("rol")} />;
}

Home.propTypes = {};

export default Home;