import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { addMonths, gte, lte } from "components/comercial/utils/dates";
import { months } from "components/utils/constants";

const getSchedule = (crane, month, year) => {
  const schedules = [...crane.craneSchedules];
  const actualDate = {"month": month, "year": year};

  for (const schedule of schedules) {
    const startDateSchedule = {"month": schedule.startMonth, "year": schedule.startYear}
    const endDateSchedule = addMonths(startDateSchedule, schedule.months - 1)

    if (gte(actualDate, startDateSchedule) && lte(actualDate, endDateSchedule)) {
      return schedule
    }
  }

  return null;
}

const DetailsView = ({ cranes, type, month, year, isMobile }) =>
  <div className={`card ${!isMobile && "px-3 py-2"}`}>
    <div className="card-body table-responsive">

      <table className="table">

        <thead>
          <tr>
            {function () {
              if (type == "FREE") {
                return (<th>Grúa</th>);
              }
              if (type == "BUSY" || type == "DISMOUNTING") {
                return (
                  <>
                    <th>Grúa</th>
                    <th>Cliente</th>
                    <th>Obra</th>
                    <th>Fecha inicio</th>
                    <th>Meses</th>
                  </>
                );
              }
              return <></>;
            }()}
          </tr>
        </thead>

        <tbody>

          {cranes.map((crane) => (
            <tr key={crane.id}>
              {function () {
                if (type == "FREE") {
                  return (<th>{crane.series} - {crane.model}{crane.number}</th>);
                }
                if (type == "BUSY" || type == "DISMOUNTING") {
                  const schedule = getSchedule(crane, month, year);
                  if (!schedule) return <></>;
                  return (
                    <>
                      <td>{crane.series} - {crane.model}{crane.number}</td>
                      <td>{schedule.client}</td>
                      <td>{schedule.building}</td>
                      <td>{months[schedule.startMonth-1]} {schedule.startYear.toString().substring(2, 4)}</td>
                      <td>{schedule.months}</td>
                    </>
                  );

                }
              }()}
            </tr>
          ))}

        </tbody>

      </table>

    </div>
  </div>;

DetailsView.propTypes = {
  cranes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

export default DetailsView;