import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDetail, useDelete } from "seed/gql";
import { usePost } from "seed/api";
import { DELETE_SCHEDULE } from "seed/gql/queries";
import dayjs from 'dayjs';
import swal from "sweetalert";
import Error from "components/helpers/Error";
import { Loading } from "seed/helpers";
import View from "components/comercial/schedule/Form.view";

function FormSet({ match, isMobile }) {

  const scheduleId = match.params.scheduleId;
  const searchRef = useRef(null);
  const formikRef = useRef(null);
  const [search, setSearch] = useState("");
  const [elevations, setElevations] = useState([]);

  const [callUpdate, reqUpdate] = usePost('/schedules/update_schedule', {
    onCompleted: (data) => {
      swal("Cronograma creado exitosamente", "Se creó la reservación de la grúa correctamente.", "success")
        .then((response) => {
          if (response) {
            window.location.replace("/commercial/schedule");
          }
        })
    },
    onError: (response) => {
      swal("Cronograma no creado",
        "El periodo en el que se requiere esta grua no está totalmente disponible.", "error");
    }
  })

  const [callDelete, reqDelete] = useDelete(DELETE_SCHEDULE, {
    onCompleted: () => {
      swal("Cronograma eliminado",
        "El cronograma fue elimando correctamente",
        "success").then((response) => {
          window.location.replace("/commercial/schedule")
        });
    },
    onError: (error) => {
      console.log(error)
      swal("No es posible eliminar el cronograma",
        "Ha ocurrido un error al eliminar el cronograma, por favor intente de nuevo mas tarde",
        "error");
    }
  });

  const reqSchedule = useDetail(`{
    schedule {
      building
      client
      state
      months
      status
      startMonth
      startYear
      crane {
        series
        model
        number
      }
      elevations {
        months
        startMonth
        startYear
      }
    }
  }`, scheduleId, {
    onCompleted: (data) => {
      setSearch(data.schedule.crane.id);
      const auxElevations = data.schedule.elevations;
      const newElevations = [...elevations];
      for (let i = 0; i < auxElevations.length; i++) {
        newElevations.push({
          months: auxElevations[i].months,
          start_date: dayjs(`${auxElevations[i].startYear}-${auxElevations[i].startMonth}-01`)
        })
      }
      setElevations(newElevations)
    }
  });

  const qCranes = useQuery(`{
    craneCommercials {
      series
      number
      model
    }
  }`, "in_use=true");

  if (reqSchedule.loading || qCranes.loading) return <Loading />;
  if (reqSchedule.error || qCranes.error) return <Error />;

  const { schedule = {} } = reqSchedule.data;
  const cranes = qCranes.data.craneCommercials;

  const formData = {
    client: schedule.client,
    building: schedule.building,
    status: schedule.status,
    state: schedule.state,
    start_date: dayjs(`${schedule.startYear}-${schedule.startMonth}-01`),
    months: schedule.months
  };

  const handleChange = (value) => setSearch(value);

  const addElevation = () => {
    const newElevations = [...elevations];
    newElevations.push({
      months: null,
      start_date: null
    })
    setElevations(newElevations)
  }

  const onChangeElevation = (value, idx, type = "") => {
    let newElevations = [...elevations];
    if (type == "start_date") {
      newElevations[idx][type] = value;
    } else if (type == "months") {
      newElevations[idx][type] = parseInt(value);
    }
    setElevations(newElevations);
  }

  const onDeleteElevation = (idx) => {
    const newElevations = [...elevations];
    newElevations.splice(idx, 1);
    setElevations(newElevations);
  }

  const onDeleteSchedule = () => {
    swal({
      title: "Eliminar cronograma",
      icon: "warning",
      text: "El cronograma logístico sera eliminado  " +
        "¿Está seguro que desea continuar?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-primary",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callDelete({ id: scheduleId })
      }
    })
  }

  const onSubmit = (values) => {
    if (search == "" || search == null) {
      swal("Grúa no seleccionada", "Debe seleccionar una grúa para registrar un cronograma.", "error");
      return;
    }

    if (values.months <= 0) {
      swal("Duración inválida", "Los meses de duración debe ser un número mayor que cero.", "error");
      return;
    }

    if (values.months > 120) {
      swal("Duración inválida", "Los meses de duración no pueden supererar los 10 años.", "error");
      return;
    }

    delete values.id;
    delete values.startDate;
    delete values.elevations;

    const start_date = values.start_date.toISOString().split('T')[0];
    values.start_month = parseInt(start_date.split("-")[1]);
    values.start_year = parseInt(start_date.split("-")[0]);

    callUpdate({
      schedule_id: scheduleId,
      crane_id: search,
      elevations: elevations.map((elevation) => {
        const date = elevation.start_date.toISOString().split('T')[0];
        const year = date.split("-")[0];
        const month = date.split("-")[1];
        return {
          "start_month": parseInt(month),
          "start_year": parseInt(year),
          "months": elevation.months
        }
      }),
      ...values
    })
  }

  return (
    <View
      edit={true}
      cranes={cranes}
      search={search}
      isMobile={isMobile}
      schedule={formData}
      elevations={elevations}
      searchRef={searchRef}
      formikRef={formikRef}
      onSubmit={onSubmit}
      handleChange={handleChange}
      addElevation={addElevation}
      onDeleteSchedule={onDeleteSchedule}
      onChangeElevation={onChangeElevation}
      onDeleteElevation={onDeleteElevation}
    />
  );
}

FormSet.propTypes = {
  match: PropTypes.object.isRequired
};

export default FormSet;