import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/es';
import swal from "sweetalert";
import { usePost } from "seed/api";
import { downloadMonthlyBinnaclesExcel, downloadWeeklyBinnaclesExcel } from "components/utils/file";
import View from "components/arris_binnacle/reports/Download.view";
moment.locale('es');


function Download(props) {

  const { craneId = null } = props.match.params;
  const frecuency = props.frecuency;

  const [callMonthly, reqMonthly] = usePost('/arris_binnacles/get_monthly_report', {
    onCompleted: (response) => {
      downloadMonthlyBinnaclesExcel({ ...response });
    },
    onError: (error) => {
      swal("Error al descargar el reporte",
        "Error al procesar la solicitud, por favor intente de nuevo mas tarde.", "error");
      console.log(error)
    }
  })

  const [callWeekly, reqWeekly] = usePost('/arris_binnacles/get_weekly_report', {
    onCompleted: (response) => {
      downloadWeeklyBinnaclesExcel({ ...response });
    },
    onError: (error) => {
      swal("Error al descargar el reporte",
        "Error al procesar la solicitud, por favor intente de nuevo mas tarde.", "error");
      console.log(error)
    }
  })

  const generateWeekOfMonth = (startDate) => {

    if (!startDate) return [];

    let dates = [];
    const start_date = startDate.toISOString().split('T')[0];
    const month = parseInt(start_date.split("-")[1]);
    const year = parseInt(start_date.split("-")[0]);

    let startMonth = moment.utc(`01-${month}-${year}`, "DD-MM-YYYY");
    let endMonth = moment.utc(startMonth).endOf('month');

    let daysToMonday = startMonth.day() - 1;
    let actualDate = startMonth.clone().subtract(daysToMonday, 'days');

    while (actualDate <= endMonth) {
      const start = actualDate.format("DD MMM")
      actualDate.add(6, 'days');
      const end = actualDate.format("DD MMM")
      actualDate.add(1, 'days');
      dates.push(`${start} - ${end}`)
    }

    return dates;

  }

  const onSubmit = (values) => {

    if (!values.start_date) {
      alert("Debe seleccionar una fecha válida");
      return;
    }

    const start_date = values.start_date.toISOString().split('T')[0];
    const month = parseInt(start_date.split("-")[1]);
    const year = parseInt(start_date.split("-")[0]);

    if (frecuency == "monthly") {

      callMonthly({
        month: month,
        year: year,
        crane_id: craneId
      });

    } else if (frecuency == "weekly") {
      if (!values.week || values.week == "") {
        alert("Debe seleccionar una semana válida");
        return;
      }

      callWeekly({
        month: month,
        year: year,
        week: parseInt(values.week),
        crane_id: craneId
      });

    }

  }


  return (
    <View
      frecuency={frecuency}
      onSubmit={onSubmit}
      generateWeekOfMonth={generateWeekOfMonth}
    />
  );
}

Download.propTypes = {};

export default Download;