import React, { useState, useRef } from "react";
import View from "components/arris/Arris.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";

const Arris = () => {

  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [created, setCreated] = useState(false);

  const craneSet = new Set();
  const uniqueCranes = [];

  const qOperators = useQuery(`{
    arrisOperators{
      order{
        crane{
          series
          number
          model
        }
      }
    }
  }`);

  if(qOperators.loading) return <Loading/>;
  if(qOperators.error) return <Error/>;

  const handleChange = (value) => 
    setSearch(value);

  for (const operator of qOperators.data.arrisOperators) {
    const crane = operator?.order?.crane;
    if (!crane) continue;
    const craneKey = `${crane?.series}-${crane?.number}`;

    if (!craneSet.has(craneKey)) {
      craneSet.add(craneKey);
      uniqueCranes.push(crane);
    }
  }

  return (
    <View
      searchRef={searchRef}
      cranes={uniqueCranes}
      search={search}
      handleChange={handleChange}
      setCreated={setCreated}
      created={created}
    />
  );

}

Arris.propTypes = {};

export default Arris;
