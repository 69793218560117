import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDetail } from "seed/gql";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error"
import View from "components/maintenances/MaintenanceReport.view";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function MaintenanceReport({ maintenanceId }) {

  const toPrintRef = useRef();

  const reqMaintenance = useDetail(`{    
    maintenance {
      createdAt
      updatedAt
      start
      end
      crane {
        model
        craneId
        number
        series
      }
    } 
  }`, maintenanceId)

  const reqMaintenanceCategories = useGet("/maintenance_files/get_files_of_maintenance",
    { "maintenance_id": maintenanceId }, {
    onCompleted: (data) => { }
  })

  if (reqMaintenance.loading || reqMaintenanceCategories.loading) return <Loading />;
  if (reqMaintenance.error || reqMaintenanceCategories.error) return <Error />;

  const { maintenance = {} } = reqMaintenance.data;
  const maintenanceCategories = reqMaintenanceCategories?.data?.maintenanceCategories;

  const downloadPDF = async () => {
    const input = toPrintRef.current;
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;

    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const imgProps = pdf.getImageProperties(imgData);

    const imgWidth = pdfWidth - 2 * margin;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    while (heightLeft > 0) {
      pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
      position -= pdfHeight;

      if (heightLeft > 0) {
        pdf.addPage();
      }
    }

    pdf.save("maintenance_report.pdf");
  };

  return <View 
            toPrintRef={toPrintRef} 
            maintenance={maintenance} 
            downloadPDF={downloadPDF}
            maintenanceCategories={maintenanceCategories}
          />;
}

MaintenanceReport.propTypes = {};

export default MaintenanceReport;