import React from "react";
import PropTypes from "prop-types";
import View from "components/information/Stores.view";

function Stores() {
  return <View />;
}

Stores.propTypes = {};

export default Stores;