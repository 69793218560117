import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import ModalRoute from "components/helpers/ModalRoute";
import { Typeahead } from "react-bootstrap-typeahead";
import { craneFamilies } from "components/utils/constants"
import CraneList from "components/comercial/cranes/List";
import CraneFormSave from "components/comercial/cranes/FormSave";
import CraneFormSet from "components/comercial/cranes/FormSet";

const Orders = ({
  cranes,
  search,
  filters,
  onChangeOwners,
  handleChange,
  onClose,
  searchRef,
  downloadReport,
  onSelectFamilyTab,
  qCranes
}) =>
  <BrowserRouter basename="/commercial/cranes">
    <div class="content container-fluid p-7">

      {/* Header */}
      <div class="page-header pt-4">
        <div class="row align-items-end">
          <div class="col-sm">
            <h1 class="page-header-title">Grúas comerciales</h1>
          </div>
          <div class="col-sm-auto">
            <div class="btn-group" role="group">
              <Link to="/create" className="btn btn-primary mx-3">
                <i class="tio-add mr-1"></i>Nueva Grúa
              </Link>
              <a className="btn btn-primary" role="button" onClick={downloadReport}>
                Descargar Reporte
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 mx-1">
        <div className="row">
          <div className="col-md-9 d-flex align-items-center">
            <div className="d-flex flex-wrap">
              {craneFamilies.map((family, idx) => {
                const checked = filters.family == family || (family == "Todos" && filters.family == "all");
                return (
                  <button type="button" key={idx}
                    onClick={() => onSelectFamilyTab(family)}
                    className={`btn btn-sm btn-${checked ? "" : "outline-"}primary mx-1 py-1`}>
                    {family}</button>
                );
              })}
            </div>
          </div>
          <div className="col-md-3">
            <div className="float-right">

              <Typeahead
                id="menu"
                ref={searchRef}
                placeholder="Buscar Grúas"
                onInputChange={handleChange}
                labelKey={(crane) => `${crane.series} - ${crane.model} ${crane.number}`}
                onChange={(selected) => selected.length > 0 ? handleChange(selected[0].id) : ""}
                options={cranes}
              >
                <div className="rbt-aux">
                  {search.length == 0 && <i className="fa fa-search rbt-aux"></i>}
                  {search.length > 0 && <i className="fa fa-times rbt-close text-danger"
                    style={{ paddingTop: "4px" }} role="button" onClick={() => {
                      searchRef.current.clear()
                      handleChange("")
                    }}></i>}
                </div>
              </Typeahead>
            </div>
          </div>
        </div>
      </div>

      <div className="row border font-weight-bold mx-1">

        <div className="col-md-4 p-2">
          Grúa
        </div>

        <div className="col-md-4 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              Propietario
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                <div class="dropdown-item">
                  <input type="checkbox" id="owner_filter1"
                    name="owner_filter1" defaultChecked={filters["owner"]["GROKE"]}
                    onChange={(e) => onChangeOwners("GROKE", e.target.checked)} />
                  <label className="px-2 align-middle" for="owner_filter1">
                    <h5>Groke</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input type="checkbox" id="owner_filter2"
                    name="owner_filter2" defaultChecked={filters["owner"]["RENTED"]}
                    onChange={(e) => onChangeOwners("RENTED", e.target.checked)} />
                  <label className="px-2 align-middle" for="owner_filter2">
                    <h5>Rentada</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input type="checkbox" id="owner_filter3"
                    name="owner_filter3" defaultChecked={filters["owner"]["SOLD"]}
                    onChange={(e) => onChangeOwners("SOLD", e.target.checked)} />
                  <label className="px-2 align-middle" for="owner_filter3">
                    <h5>Vendida</h5>
                  </label>
                </div>

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2">
          Acciones
        </div>

      </div>

      {/* List */}
      <CraneList
        search={search}
        qCranes={qCranes}
        filters={filters} />

      <ModalRoute
        path="/:craneId(\d+)/edit"
        width={700}
        height={410}
        onClose={onClose}
        component={CraneFormSet} />

      <ModalRoute
        path="/create"
        width={700}
        height={410}
        onClose={onClose}
        component={CraneFormSave} />

    </div>
  </BrowserRouter>;

Orders.propTypes = {
  searchRef: PropTypes.object,
  cranes: PropTypes.array,
  search: PropTypes.string,
  filters: PropTypes.object,
  inUseFilters: PropTypes.object,
  setInUseFilters: PropTypes.func,
  statusFilters: PropTypes.object,
  setStatusFilters: PropTypes.func,
  onChangeOwners: PropTypes.func,
  handleChange: PropTypes.func,
  created: PropTypes.bool,
  setCreated: PropTypes.func,
  onClose: PropTypes.func,
  orderStatusFilters: PropTypes.object,
  setOrderStatusFilters: PropTypes.func,
  commercialCount: PropTypes.number,
  closedCount: PropTypes.number,
  relocatedCount: PropTypes.number,
  mountingCount: PropTypes.number,
  disassemblyCount: PropTypes.number,
  downloadReport: PropTypes.func,
  onSelectFamilyTab: PropTypes.func,
  qCranes: PropTypes.object,
};

export default Orders;