import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import SignatureCanvas from 'react-signature-canvas'

const ValidationView = ({
  arrisBinnacles,
  onSubmit,
  handleButtonClick,
  changeCheck,
  check,
  allApprovedOrRejected,
  binnacleReviewer,
}) => (
  <main id="content" role="main" class="main pl-0">
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <img
          class="z-index-2"
          src="/theme/img/groke.png"
          alt="Logo"
          style={{ width: "12rem" }}
        />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-7">
          <div class="card card-lg mb-5 pt-4 pb-4">
            {!allApprovedOrRejected ? (
              <div class="card-body" style = {{maxHeight:"50vh", overflowY:"auto"}}>
                <div className = "text-center">
                  <h1 class="card-title">Validación horas trabajadas</h1>
                  <p>
                    Operador: {arrisBinnacles[0].operator.user.firstName}
                    <br />
                    Grúa: {arrisBinnacles[0].operator.order.crane.model} - 
                    {arrisBinnacles[0].operator.order.crane.series}
                    <br />
                    Cliente: {arrisBinnacles[0].operator.order.clientName}
                    <br />
                    Obra: {arrisBinnacles[0].operator.order.buildingName}
                  </p>

                  <div className="mt-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Horas Trabajadas</th>
                          <th scope="col">Horas de Comida</th>
                          <th scope="col">Horas en Falla</th>
                        </tr>
                      </thead>
                      <tbody>
                        {arrisBinnacles.map((arrisBinnacle, index) => (
                          <tr key={index}>
                            <td>{arrisBinnacle.date}</td>
                            <td>{arrisBinnacle.hoursWorked}</td>
                            <td>{arrisBinnacle.eatHours}</td>
                            <td>{arrisBinnacle.failuresHours ? arrisBinnacle.failuresHours : "-"}</td>
                          </tr>
                        ))}
                          <tr>
                            <td>Total</td>
                            <td>
                              {arrisBinnacles.reduce(
                                (total, binnacle) => total + parseInt(binnacle.hoursWorked),
                                0
                              )}
                            </td>
                            <td>
                              {arrisBinnacles.reduce(
                                (total, binnacle) => total + parseInt(binnacle.eatHours),
                                0
                              )}
                            </td>
                            <td>
                              {arrisBinnacles.reduce(
                                (total, binnacle) => total + (binnacle.failuresHours ? parseInt(binnacle.failuresHours) : 0),
                                0
                              ) || "-"}
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                <Formik
                  initialValues={{
                    ...arrisBinnacles,
                    signature: null,
                  }}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="row justify-content-center">
                        <div className="border border-secondary border-right-0 col-md-5">
                          <SignatureCanvas penColor='blue' ref={(ref) => values.signature = ref} 
                            canvasProps={{ height: 150, width:270}}/>
                        </div>
                        <div className = "border border-secondary border-left-0">
                          <button
                            type="button"
                            className = "border border-0 bg-transparent"
                            onClick={() => {
                              setFieldValue("signature", "");
                              values.signature.clear();
                            }}
                          >
                            <i className="fas fa-eraser"></i>
                          </button>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <div>
                            <Field
                              type="text"
                              name="reviewer_name"
                              className="form-control border border-top-0 border-secondary rounded-0"
                              placeholder="Nombre de la persona que valida"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-5 mx-auto col-md-10 text-center">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => changeCheck()}
                          />
                          <label
                            className="form-check-label"
                            style={{ fontSize: "12px" }}
                          >
                            Acepto que la información enviada es correcta y
                            certifico que las horas trabajadas indicadas por el
                            operador en este formulario reflejan con precisión
                            el tiempo real dedicado a las labores en la obra.
                          </label>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        <button
                          type="submit"
                          className="btn btn-success mr-2"
                          onClick={() => handleButtonClick(true)}
                          disabled={!check}
                        >
                          Aprobar
                        </button>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          disabled={check}
                        >
                          Rechazar
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <div class="card-body text-center">
                <h1 class="card-title">Información revisada por {binnacleReviewer}</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </main>
);

ValidationView.propTypes = {
  arrisBinnacles: PropTypes.array,
  onSubmit: PropTypes.func,
  handleButtonClick: PropTypes.func,
  changeCheck: PropTypes.func,
  check: PropTypes.bool,
  allApprovedOrRejected: PropTypes.bool,
  binnacleReviewer: PropTypes.string,
};

export default ValidationView;
