import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import View from "components/shippings/List.view";
import Error from "components/helpers/Error";
import { formatEnumFilters, formatFilters, formatSearchFilters } from "components/utils/filters";

const getTypeShipping = (path) => {
  if (path === "/shippings/enabled") return "ENABLED";
  if (path === "/shippings/departures") return "DEPARTURE";
  if (path === "/shippings/entries") return "ENTRY";
  return null;
}

const ShippingList = ({ search, statusFilters, isReadOnly, orderDate }) => {

  const pageSize = 40;
  const [pageNum, setPageNum] = useState(1);

  const statusTranslations = {
    "CREATED": "Creada",
    "SECURITY_REVISION": "Revisión de seguridad",
    "SECURITY_COMPLETED": "Revisión completada",
    "IN_RECOLLECTION": "En recolección",
    "RECOLLECTED": "Recolectado",
    "SUPERVISOR_REVIEW": "Revisión de supervisor",
    "REVIEWED": "Revisado",
    "IN_PROCESS": "En proceso de carga",
    "COMPLETED": "Carga completada",
    "REVIEWED_WITH_ERRORS": "Revisado con errores",
    "FINISHED": "En destino",
    "CREATED_ENTRY": "Creada para entrada",
    "IN_PROCESS_ENTRY": "En proceso de entrada",
    "COMPLETED_ENTRY": "Entrada completada"
  };

  const typeShipping = getTypeShipping(window.location.pathname);
  const filters = [
    formatEnumFilters(statusFilters, "status"),
    formatSearchFilters(search, ["order.crane.series", "order.crane.model", "order.buildingName", "transportCarrierName"]),
  ];

  if (typeShipping !== null) {
    if (typeShipping === "ENABLED") {
      filters.push("is_enabled = true");
    } else if (typeShipping === "DEPARTURE") {
      filters.push("type = TO_BUILDING AND is_enabled = false");
    } else if (typeShipping === "ENTRY") {
      filters.push("status = CREATED_ENTRY OR status = IN_PROCESS_ENTRY OR status = COMPLETED_ENTRY");
    }
  }

  const reqShippings = usePagination(`
  {
    shippingPagination {
      totalPages 
      shippings {
        truckPlate
        transportCompany
        isEnabled
        type
        contents {
          id
          suggestRef
          partId
          partName
          quantity
          isRecollection
          isSupervision
          operations{
            checked
          }
          images {
            id
          }
          items
          um
          manual
          elevationNumber
        }
        order {
          crane {
            id
            craneId
            model
            number
            series
          }
          clientName
          buildingName
          transportNumber
          buildingStreet
          buildingCity
          buildingState
          metadata
        }
        transportPlate
        transportCarrierName
        transportPhone
        transportOtherPhone
        deliveryDate
        status
        company{
          name
          rfc
        }
      }
    }
  }`, 
  pageNum, 
  pageSize,
  formatFilters(filters),
  {orderBy: orderDate + "created_at"});

  const [callPost, qPost] = usePost("/shippings/delete_shipping", {
    onCompleted: () => {
      window.location.reload();  
    }
  });

  const [callSetStatusShipping, reqSetStatusShipping] = usePost("/shippings/update_status", {
    onCompleted: () => {
      setPageNum(pageNum)
      reqShippings.refetch();
    }
  });

  if(reqShippings.loading) return <Loading />;
  if(reqShippings.error || reqSetStatusShipping.error) return <Error />;

  const { shippings = [], totalPages = 0 } = reqShippings.data.shippingPagination;
  console.log(shippings);
  if(totalPages != 0 && pageNum > totalPages) 
    setPageNum(totalPages);

  const onClickPage = (pageNum) =>
    setPageNum(pageNum);

  const onDelete = (shippings) => {
    let confirm = window.confirm("¿Está seguro de eliminar el empaque? todos los contenidos" 
      + " y evidencias serán eliminados.");

    if(confirm)
      callPost({shipping_id: shippings.id});
  };

  const calculateCompletionPercentage = (contents) => {
    const totalOperations = contents.reduce((acc, content) => acc + content.operations.length, 0);
    const completedOperations = contents.reduce((acc, content) => 
      acc + content.operations.filter(operation => operation.checked).length, 0);
    return totalOperations === 0 ? 0 : (completedOperations / totalOperations) * 100;
  };

  const onSetStatus = (shipping, status) =>
    callSetStatusShipping({shipping_id: shipping.id, status: status});

  return <View
    shippings={shippings}
    statusFilters={statusFilters}
    statusTranslations={statusTranslations}
    isReadOnly={isReadOnly}
    pageNum={pageNum}
    totalPages={totalPages}
    onClickPage={onClickPage}
    onDelete={onDelete}
    onSetStatus={onSetStatus}
    calculateCompletionPercentage={calculateCompletionPercentage}
  />;
  
}

ShippingList.propTypes = {
  search: PropTypes.string,
  statusFilters: PropTypes.string,
  orderDate: PropTypes.string
};

export default ShippingList;