import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import NotResults from "components/helpers/NotResults";
import PaginationFooter from "components/helpers/PaginationFooter";
import { mapCommercialCrmStatusColor } from "components/utils/enum_mapper";

const ListView = ({ crms, pageNum, totalPages, onChangePageNum, updateCrmStatus }) => (
  <>
    {crms.length == 0 ? <NotResults /> : (
      <div style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
        {
          crms.map((crm, idx) => (
            <div className="row border mx-1 text-reset" key={crm.id}
              style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}
            >

              <div className="col-md-1 p-2">
                {crm.craneModel}
              </div>

              <div className="col-md-1 p-2">
                {crm.crmId}
              </div>

              <div className="col-md-2 p-2">
                {crm.clientName}
              </div>

              <div className="col-md-2 p-2">
                {crm.buildingName}
              </div>

              <div className="col-md-1 p-2">
                {crm.buildingState}
              </div>

              <div className="col-md-1 p-2">
                {moment.utc(crm.priceDate).format("DD/MM/YYYY")}
              </div>

              <div className="col-md-2 p-2">
                <div className="d-flex justify-content-center">
                  <div className="text-center">
                    <select
                      className="custom-badge-select border-0"
                      value={crm.status}
                      style={mapCommercialCrmStatusColor(crm.status)}
                      onChange={(event) => updateCrmStatus(event, crm.id)}
                    >
                      <option value="CLOSED">Cerrado</option>
                      <option value="WAITING">En espera</option>
                      <option value="NEGOTIATION">En negociación</option>
                      <option value="SYNC">Enviado a cronograma</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-2 p-2">
                {
                  crm.status == "CLOSED" && (
                    <Link className="btn btn-outline-primary" to={`/sync/${crm.id}`}>
                      Enviar a cronógrama
                    </Link>
                  )
                }
              </div>

            </div>
          ))
        }
      </div>
    )}
    <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onChangePageNum} />
  </>
);

ListView.propTypes = {
  crms: PropTypes.array.isRequired
};

export default ListView;