import React from "react";
import PropTypes from "prop-types";
import { Modal } from "seed/helpers";
import { addMonths } from "components/comercial/utils/dates";
import { months } from "components/utils/constants";
import ResultSync from "components/comercial/crm/ResultSync";

const StateFilter = ({ name, onChange }) => {
  return (
    <select name="state" class="form-control w-50" required onChange={onChange}>
      <option value="all">Todos los estados</option>
      <option value="AGUASCALIENTES">AGUASCALIENTES</option>
      <option value="BAJA CALIFORNIA">BAJA CALIFORNIA</option>
      <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
      <option value="CAMPECHE">CAMPECHE</option>
      <option value="CHIAPAS">CHIAPAS</option>
      <option value="CHIHUAHUA">CHIHUAHUA</option>
      <option value="CIUDAD DE MEXICO">CIUDAD DE MEXICO</option>
      <option value="COAHUILA">COAHUILA</option>
      <option value="COLIMA">COLIMA</option>
      <option value="DURANGO">DURANGO</option>
      <option value="ESTADO DE MEXICO">ESTADO DE MEXICO</option>
      <option value="GUANAJUATO">GUANAJUATO</option>
      <option value="GUERRERO">GUERRERO</option>
      <option value="HIDALGO">HIDALGO</option>
      <option value="JALISCO">JALISCO</option>
      <option value="MICHOACAN">MICHOACAN</option>
      <option value="MORELOS">MORELOS</option>
      <option value="NAYARIT">NAYARIT</option>
      <option value="NUEVO LEON">NUEVO LEON</option>
      <option value="OAXACA">OAXACA</option>
      <option value="PUEBLA">PUEBLA</option>
      <option value="QUERETARO">QUERETARO</option>
      <option value="QUINTANA ROO">QUINTANA ROO</option>
      <option value="SAN LUIS POTOSI">SAN LUIS POTOSI</option>
      <option value="SINALOA">SINALOA</option>
      <option value="SONORA">SONORA</option>
      <option value="TABASCO">TABASCO</option>
      <option value="TAMAULIPAS">TAMAULIPAS</option>
      <option value="TLAXCALA">TLAXCALA</option>
      <option value="VERACRUZ">VERACRUZ</option>
      <option value="YUCATAN">YUCATAN</option>
      <option value="ZACATECAS">ZACATECAS</option>
    </select>
  )
}

const SyncView = ({
  crm,
  logs,
  filters,
  cranes,
  onClose,
  showResultSync,
  onChangeFilter,
  onSelectCrane,
  onChangeSelect
}) => (
  <>
    <div class="card h-100 px-3 py-2">

      <div class="card-header">
        <div className="d-flex align-items-center justify-content-start w-100">
          <h3 class="card-header-title">
            Enviar a cronograma logístico (UT) en la fecha {months[crm.startMonth-1]} {" "}
            {crm.startYear.toString().substring(2,4)} con duración de {crm.months} meses
          </h3>
        </div>
      </div>

      <div class="card-body pt-2" style={{ maxHeight: "1000px", overflowY: "auto" }}>

        <div className="d-flex my-4 justify-content-between">
          <h3>Grúas disponibles</h3>
          <StateFilter name="free_states" onChange={(event) => onChangeFilter("free", event.target.value)} />
        </div>
        <div className="my-5">
          <b className="h5 text-danger ml-1">*</b> Los meses son respecto a la fecha actual
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Grúa</th>
                <th scope="col">Cliente</th>
                <th scope="col">Obra</th>
                <th scope="col">Estado</th>
                <th scope="col">Meses desde última liberación <b className="h5 text-danger ml-1">*</b></th>
                <th scope="col">Tipo de cronograma</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {cranes.filter(item => item.status == "FREE")
                .filter(item => filters.free == "all" ? item : item?.schedule?.state == filters.free)
                .map((item) => (
                  <tr key={item.crane.id}>
                    <td>{item.crane.model} - {item.crane.series}</td>
                    <td>{item.schedule?.client ?? "---"}</td>
                    <td>{item.schedule?.building ?? "---"}</td>
                    <td>{item.schedule?.state ?? "---"}</td>
                    <td>{item.time ?? "---"}</td>
                    <td>
                      <select id="statusSelect" class="form-control"
                        onChange={(event) => onChangeSelect(item.crane.id, event.target.value)}>
                        <option value="BUSY">Ocupado</option>
                        <option value="TO_USE">Por utilizar</option>
                      </select>
                    </td>
                    <td>
                      <button type="button" className="btn btn-primary" onClick={() => onSelectCrane(item.crane.id)}>
                        <i className="far fa-check-circle mr-3"></i> Seleccionar
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>

          </table>
        </div>

        <div className="d-flex my-4 justify-content-between">
          <h3>Grúas por desocuparse</h3>
          <StateFilter name="dismounting_states"
            onChange={(event) => onChangeFilter("dismounting", event.target.value)} />
        </div>
        <div className="my-5">
          <b className="h5 text-danger ml-1">*</b> Los datos son respecto a la fecha de este CRM
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Grúa</th>
                <th scope="col">Cliente</th>
                <th scope="col">Obra</th>
                <th scope="col">Estado</th>
                <th scope="col">Meses para liberación <b className="h5 text-danger ml-1">*</b></th>
                <th scope="col">Tipo de cronograma</th>
                <th scope="col">Salida</th>
              </tr>
            </thead>
            <tbody>
              {cranes.filter(crane => crane.status == "DISMOUNTING")
                .filter(item => filters.dismounting == "all" ? item : item?.schedule?.state == filters.dismounting)
                .map((item) => (
                  <tr key={item.crane.id}>
                    <td>{item.crane.model} - {item.crane.series}</td>
                    <td>{item.schedule?.client ?? "---"}</td>
                    <td>{item.schedule.building}</td>
                    <td>{item.schedule.state}</td>
                    <td>{item.time ?? "---"}</td>
                    <td>{item.schedule.status == "BUSY" ? "Ocupado" : "Por utilizar"}</td>
                    <td>{function () {
                      const startDate = { "month": item.schedule.start_month, "year": item.schedule.start_year };
                      const endDate = addMonths(startDate, item.schedule.months - 1);
                      return `${months[endDate.month - 1]} ${endDate.year.toString().substring(2, 4)}`;
                    }()
                    }</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex mt-4 justify-content-between">
          <h3>Grúas ocupadas</h3>
          <StateFilter name="busy_states" onChange={(event) => onChangeFilter("busy", event.target.value)} />
        </div>
        <div className="mt-5">
          <b className="h5 text-danger ml-1">*</b> Los datos son respecto a la fecha de este CRM
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Grúa</th>
                <th scope="col">Cliente</th>
                <th scope="col">Obra</th>
                <th scope="col">Estado</th>
                <th scope="col">Meses para liberación<b className="h5 text-danger ml-1">*</b></th>
                <th scope="col">Tipo de cronograma</th>
                <th scope="col">Salida</th>
              </tr>
            </thead>
            <tbody>
              {cranes.filter(crane => crane.status == "BUSY")
                .filter(item => filters.busy == "all" ? item : item?.schedule?.state == filters.busy)
                .map((item) => (
                  <tr key={item.crane.id}>
                    <td>{item.crane.model} - {item.crane.series}</td>
                    <td>{item.schedule?.client ?? "---"}</td>
                    <td>{item.schedule.building}</td>
                    <td>{item.schedule.state}</td>
                    <td>{item.time ?? "---"}</td>
                    <td>{item.schedule.status == "BUSY" ? "Ocupado" : "Por utilizar"}</td>
                    <td>{function () {
                      const startDate = { "month": item.schedule.start_month, "year": item.schedule.start_year };
                      const endDate = addMonths(startDate, item.schedule.months - 1);
                      return `${months[endDate.month - 1]} ${endDate.year.toString().substring(2, 4)}`;
                    }()
                    }</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

      </div>

    </div>
    {showResultSync && (
      <Modal
        component={ResultSync}
        logs={logs}
        onClose={onClose}
      />
    )}
  </>
)

StateFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

SyncView.propTypes = {
  crm: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  cranes: PropTypes.array.isRequired,
  showResultSync: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onSelectCrane: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired
};

export default SyncView;