import React from "react";
import PropTypes from "prop-types";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/security_stock/Details.view";

const SecurityStockDetails = ({ groupId }) => {

    const qStockGroup = useDetail(`{
        stockGroup {
            name
            description
            createdAt
            crane {
                series
                number
                model
            }
            items{
                item{
                    url
                }
            }
        }
    }`, groupId);

    if (qStockGroup.loading) return <Loading />;
    if (qStockGroup.error) return "Error";

    const { stockGroup } = qStockGroup.data;

    return <View
        stockGroup={stockGroup}
    />
}

SecurityStockDetails.propTypes = {
    group_id: PropTypes.number.isRequired
};

export default SecurityStockDetails;