import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { useHistory } from "react-router";
import View from "components/comercial/schedule/Sync.view";

function Sync() {

  const history = useHistory();
  const [logs, setLogs] = useState([]);
  const [step, setStep] = useState(1);

  const [callUpload, reqCallUpload] = usePost("/schedules/upload", {
    onCompleted: (data) => {
      setLogs(data.logs);
      setStep(4);
    },
    onError: (error) => { }
  });

  const [callDownload, reqCallDownload] = usePost("/schedules/download_template", {
    onCompleted: (data) => {
      const elem = window.document.createElement('a');
      elem.href = data.url;
      elem.download = "schedule_template.xlsx";
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      setStep(2);
    },
    onError: (error) => {
      console.error(error);
      alert("Error al crear el formato de carga masiva")
    }
  });

  const onDownloadExcel = () => callDownload();
  const onLoadTemplate = () => setStep(3);
  const onSubmit = (values) => callUpload({ file_id: values.template_id })
  

  const onClose = () => {
    history.goBack();
    window.location.replace("/commercial/schedule")
  }

  const loading = reqCallUpload.loading;


  return (
    <View
      logs={logs}
      step={step}
      loading={loading}
      onClose={onClose}
      onLoadTemplate={onLoadTemplate}
      onDownloadExcel={onDownloadExcel}
      onSubmit={onSubmit}
    />
  );
}

Sync.propTypes = {};

export default Sync;