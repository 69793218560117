import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import View from "components/comercial/analytics/Dashboard.view";
import { usePost } from "seed/api";

function Dashboard({ filters }) {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 );
  const [isMobileHorizontal, setIsMobileHorizontal] =
    useState(window.innerWidth <= 1000 && window.innerHeight <= 500);

  const [craneOcupationLineChartData, setCraneOcupationLineChartData] = useState({
    labels: [], datasets: []
  });
  const [craneOcupationPieChartData, setCraneOcupationPieChartData] = useState({
    labels: [], datasets: []
  });
  const [craneFamiliesOcupationStackedBarChartData, setCraneFamiliesOcupationStackedBarChartData] = useState({
    labels: [], datasets: []
  });
  const [craneStatesOcupationBarChartData, setCraneStatesOcupationBarChartData] = useState({
    labels: [], datasets: []
  });
  const [craneOcupationTableAnalytic, setCraneOcupationTableAnalytic] = useState([]);

  const [callCraneOcupationLineChart, reqCraneOcupationLineChart] =
    usePost("/schedules/dashboard_crane_ocupation_line_chart", {
      onCompleted: (data) => {
        setCraneOcupationLineChartData({
          labels: data.labels,
          datasets: [
            {
              label: 'Grúas libres',
              data: data.free_cranes,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Grúas ocupadas',
              data: data.busy_cranes,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        })
      },
      onError: (error) => {
        console.log("Error: " + error)
      }
    })

  const [callCraneOcupationPieChart, reqCraneOcupationPieChart] =
    usePost("/schedules/dashboard_crane_ocupation_pie_chart", {
      onCompleted: (data) => {
        setCraneOcupationPieChartData({
          labels: ["Grúas libres", "Grúas ocupadas"],
          datasets: [{
            label: "Grúas",
            data: [data.free_cranes, data.busy_cranes],
            backgroundColor: [
              'rgba(255, 99, 132, 0.7)',
              'rgba(53, 162, 235, 0.7)',
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(53, 162, 235)',
            ]
          }]
        })
      },
      onError: (error) => {
        console.log("Error: " + error)
      }
    })

  const [callCraneFamiliesOcupationStackedBarChart, reqCraneFamiliesOcupationStackedBarChart] =
    usePost("/schedules/dashboard_crane_families_ocupation_stackedbar_chart", {
      onCompleted: (data) => {
        setCraneFamiliesOcupationStackedBarChartData({
          labels: data.labels,
          datasets: [
            {
              label: "Grúas libres",
              data: data.free_cranes,
              backgroundColor: 'rgb(255, 99, 132)'
            },
            {
              label: "Grúas ocupadas",
              data: data.busy_cranes,
              backgroundColor: 'rgb(53, 162, 235)'
            }
          ]
        })
      },
      onError: (error) => {
        console.log("Error: " + error)
      }
    })

  const [callCraneStatesOcupationBarChart, reqCraneStatesOcupationBarChart] =
    usePost("/schedules/dashboard_crane_state_ocupation_bar_chart", {
      onCompleted: (data) => {
        setCraneStatesOcupationBarChartData({
          labels: data.labels,
          datasets: [{
            label: "Grúas ocupadas",
            data: data.data,
            backgroundColor: 'rgb(53, 162, 235)'
          }]
        })
      },
      onError: (error) => {
        console.log("Error: " + error)
      }
    })

  const [callCraneOcupationTableAnalytics, reqCraneOcupationTableAnalytics] = usePost("/schedules/analytics", {
    onCompleted: (data) => {
      setCraneOcupationTableAnalytic(data)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  useEffect(() => {
    callCraneOcupationLineChart({
      size: 12,
      filters: filters,
      actual_month: new Date().getMonth() + 1,
      actual_year: new Date().getFullYear()
    })

    callCraneOcupationPieChart({
      filters: filters,
      actual_month: new Date().getMonth() + 1,
      actual_year: new Date().getFullYear()
    })

    callCraneFamiliesOcupationStackedBarChart({
      filters: filters,
      actual_month: new Date().getMonth() + 1,
      actual_year: new Date().getFullYear()
    })

    callCraneStatesOcupationBarChart({
      filters: filters,
      actual_month: new Date().getMonth() + 1,
      actual_year: new Date().getFullYear()
    })

    callCraneOcupationTableAnalytics({
      filters: filters,
      start_month: new Date().getMonth() + 1,
      start_year: new Date().getFullYear(),
      size: 12
    })

  }, [filters])


  const loadingCraneOcupationLineChart = reqCraneOcupationLineChart.loading;
  const loadingCraneOcupationPieChart = reqCraneOcupationPieChart.loading;
  const loadingCraneFamiliesOcupationStackedBarChart = reqCraneFamiliesOcupationStackedBarChart.loading;
  const loadingCraneStatesOcupationBarChart = reqCraneStatesOcupationBarChart.loading;
  const loadingCraneOcupationTableAnalytics = reqCraneOcupationTableAnalytics.loading;

  return (
    <View
      isMobile={isMobile}
      isMobileHorizontal={isMobileHorizontal}
      craneOcupationTableAnalytic={craneOcupationTableAnalytic}
      craneOcupationLineChartData={craneOcupationLineChartData}
      craneOcupationPieChartData={craneOcupationPieChartData}
      craneFamiliesOcupationStackedBarChartData={craneFamiliesOcupationStackedBarChartData}
      craneStatesOcupationBarChartData={craneStatesOcupationBarChartData}
      loadingCraneOcupationLineChart={loadingCraneOcupationLineChart}
      loadingCraneOcupationPieChart={loadingCraneOcupationPieChart}
      loadingCraneFamiliesOcupationStackedBarChart={loadingCraneFamiliesOcupationStackedBarChart}
      loadingCraneStatesOcupationBarChart={loadingCraneStatesOcupationBarChart}
      loadingCraneOcupationTableAnalytics={loadingCraneOcupationTableAnalytics}
    />
  );
}

Dashboard.propTypes = {
  filters: PropTypes.object.isRequired
};

export default Dashboard;