import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/arris_binnacle/analytics/Analytics.view";

function Analytics({ grouped }) {

  const rol = sessionStorage.getItem("rol")
  if (!rol.startsWith("ADMIN")) window.location.replace("/login");

  const [filters, setFilters] = useState({
    family: "all"
  });

  const onSelectFamilyTab = (family) => {
    let newFilters = { ...filters };
    newFilters.family = family == "Todos" ? "all" : family
    setFilters(newFilters);
  }

  return <View filters={filters} grouped={grouped} onSelectFamilyTab={onSelectFamilyTab}/>;
}

Analytics.propTypes = {
  grouped: PropTypes.string.isRequired
};

export default Analytics;