import React, { useState, useRef } from "react";
import View from "components/comercial/cranes/Cranes.view";
import { useHistory } from "react-router";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import { downloadCSV } from "components/utils/file";
import { getCraneFamily } from "components/utils/constants";
import moment from "moment";

const Cranes = () => {

  const rol = sessionStorage.getItem("rol")
  if (!rol.startsWith("ADMIN")) window.location.replace("/login");

  const searchRef = useRef(null);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    family: "all",
    owner: {
      "GROKE": true,
      "RENTED": true,
      "SOLD": true
    }
  });

  const qCranes = useQuery(`{
    craneCommercials {
      inUse
      status
      model
      series
      number
    }
  }`, "in_use=true");

  if (qCranes.loading) return <Loading />;
  if (qCranes.error) return <Error />;

  const cranes = qCranes.data.craneCommercials;

  const handleChange = (value) => {
    setSearch(value);
  }

  const onSelectFamilyTab = (family) => {
    let newFilters = { ...filters };
    newFilters.family = family == "Todos" ? "all" : family;
    setFilters(newFilters);
  }

  const onChangeOwners = (owner, checked) => {
    let newFilters = { ...filters };
    newFilters.owner[owner] = checked;
    setFilters(newFilters);
  }

  const onClose = () => {
    document.location.href = "/commercial/cranes";
  };

  const downloadReport = () => {

    let notes = [];
    let headers = ["Familia", "Modelo", "Serie", "Numero"];

    let rows = cranes.map((crane) => [
      getCraneFamily(crane.model),
      crane.model,
      crane.series,
      crane.number
    ]);

    downloadCSV(notes, headers, rows, "reporte_semanal_" + moment().format("DD_MM_YYYY"));

  };

  return <View
    cranes={cranes}
    search={search}
    qCranes={qCranes}
    filters={filters}
    onChangeOwners={onChangeOwners}
    handleChange={handleChange}
    onClose={onClose}
    searchRef={searchRef}
    onSelectFamilyTab={onSelectFamilyTab}
    downloadReport={downloadReport}
  />;

}

Cranes.propTypes = {};

export default Cranes;