import React from "react";
import PropTypes from "prop-types";
import { useDetail, useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import View from "components/preventive_maintenances/pending/FormAssign.view";

const FormSet = ({ preventiveMaintenanceId }) => {

    const history = useHistory();

    const [reassignUser, setReassignUser] = usePost("/preventive_maintenances/reassign_technician", {
        onCompleted: () => {
            history.goBack();
            swal("¡Éxito!", "Técnico reasignado correctamente.", "success").then(() => {
                window.location.reload();
            });
        },
        onError: () => {
            swal("Error", "Error al asignar el técnico", "error");
        }
    });

    const qUsers = useQuery(`{
        users{
            firstName
            lastName
        }
    }`, "rol=TECHNICIAN");

    const qPreventiveMaintenance = useDetail(`
    {
        preventiveMaintenance{
            technician{
                id
                firstName
                lastName
            }
        }
    }`, preventiveMaintenanceId);

    if (qPreventiveMaintenance.loading) return <Loading />;
    if (qPreventiveMaintenance.error) return <Error />;
    if (qUsers.loading) return <Loading />;
    if (qUsers.error) return <Error />;

    const { preventiveMaintenance } = qPreventiveMaintenance.data;
    const { users } = qUsers.data;

    const onSubmit = (values) => {
        const { technician } = values;

        let confirmMessage = `¿Estás seguro de reasignar a ${preventiveMaintenance?.technician?.firstName} ${preventiveMaintenance?.technician?.lastName}?`;

        swal({
            title: "Reasignar técnico",
            text: confirmMessage,
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        }).then((willReassign) => {
            if (willReassign) {
                reassignUser({ maintenance_id: preventiveMaintenanceId, technician_id: technician.id });
            }
        });

    }

    return <View technician={preventiveMaintenance.technician} users={users} onSubmit={onSubmit} />;
};

FormSet.propTypes = {

};

export default FormSet;