import React from "react";
import PropTypes from "prop-types";
import { FileField } from "seed/helpers";
import { Formik, Form } from "formik";

const UploadView = ({ logs, step, loading, onClose, onSubmit, onDownloadExcel, onLoadTemplate }) => (
  <div class="card h-100 px-3 py-2">

    <div class="card-header">
      <div className="d-flex align-items-center justify-content-start w-100">
        <h3 class="card-header-title">
          Cargar datos a CRM
        </h3>
      </div>
    </div>

    <div class="card-body pt-2" style={{ maxHeight: "1000px", overflowY: "auto" }}>
      <div class="row">
        <div class="col">

          <Formik
            initialValues={{}}
            onSubmit={onSubmit}>
            {({ values, setFieldValue }) =>
              <Form>

                <div className="row my-3">
                  <div className="col-md-12">
                    <h4> 1.- Descargue la plantilla en Excel </h4>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12">
                    <button
                      className="btn btn-secondary btn-block"
                      type="button"
                      onClick={onDownloadExcel}
                    >
                      Descargar
                    </button>
                  </div>
                </div>

                {
                  step >= 2 && (
                    <>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <h4> 2.- Llene la información que desea capturar y suba el archivo modificado</h4>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-md-12">
                          <FileField accept=".xlsx" setFieldValue={setFieldValue} 
                            onCompleted={onLoadTemplate} name="template"/>
                        </div>
                      </div>
                    </>
                  )
                }

                {
                  step >= 3 && (
                    <>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <h4> 3.- Suba la información al sistema</h4>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-md-12">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            <i class="fas fa-arrow-alt-circle-up mr-1"></i>
                            {
                              loading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>) : "Subir información"
                            }
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }

                {
                  step >= 4 && (
                    <>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <h4> 4.- Tu información ha sido subida al sistema</h4>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          {
                            logs.length > 0 ? (
                              <>
                                <h4>Hubo problemas con los siguientes registros, corrigelos y vuelvelo a intentar</h4>
                                <ul>
                                  {
                                    logs.map((log, idx) => (
                                      <li key={idx}>{log?.message}</li>
                                    ))
                                  }
                                </ul>
                              </>
                            ) : (
                              <div className="row">
                                <div className="col-md-12 text-center my-4">
                                  <h4>Todos los datos fueron cargados exitosamente</h4>
                                </div>
                              </div>
                            )
                          }
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={onClose}
                          >
                            <i class="fas fa-check mr-1"></i> Finalizar
                          </button>
                        </div>
                      </div>

                    </>


                  )
                }

              </Form>
            }</Formik>


        </div>
      </div>
    </div>

  </div>
)

UploadView.propTypes = {
  logs: PropTypes.array.isRequired,
  step: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  excelData: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLoadTemplate: PropTypes.func.isRequired,
  onDownloadExcel: PropTypes.func.isRequired,
};

export default UploadView;