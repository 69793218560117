import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import SignatureCanvas from 'react-signature-canvas';

const ResidentValidationView = ({ 
    preventiveMaintenance,  
    onSubmit,
    type_link
}) => {
  const reviewCategories = preventiveMaintenance.maintenancePreventiveMaintenanceEvidences.filter(
    (evidence) => evidence.category.typeQuestion === "REVIEW"
  );

  const isSupervisor = type_link === "SUPERVISOR";

  const groupedCategories = reviewCategories.reduce((acc, evidence) => {
    const { title, subtitle } = evidence.category;
    const key = `${title} ${subtitle}`;
    
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(evidence);
    
    return acc;
  }, {});

  return (
    <main id="content" role="main" className="main pl-0">
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>

      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="index.html">
          <img
            className="z-index-2"
            src="/theme/img/groke.png"
            alt="Logo"
            style={{ width: "12rem" }}
          />
        </a>

        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8">
            <div className="card card-lg mb-5 pt-4 pb-4">
              <div className="card-body" style={{ maxHeight: "50vh", overflowY: "auto" }}>
                <div className="text-center">
                  <h1 className="card-title">Detalles de Mantenimiento</h1>
                  <p>
                    {preventiveMaintenance?.order?.crane?.series} / {preventiveMaintenance?.order?.crane?.model}{" "}{preventiveMaintenance?.order?.crane?.number}
                    <br />
                    Cliente: {preventiveMaintenance?.order?.clientName}
                    <br />
                    Obra: {preventiveMaintenance?.order?.buildingName}
                    <br />
                    Técnico: {preventiveMaintenance?.technician?.firstName}{" "}{preventiveMaintenance?.technician?.lastName}
                    <br />
                    Operador: {preventiveMaintenance?.operator?.user?.firstName}{" "}{preventiveMaintenance?.operator?.user?.lastName}
                  </p>

                  {(preventiveMaintenance?.report?.residentCheck && type_link === "RESIDENT") || 
                   (preventiveMaintenance?.report?.supervisorCheck && type_link === "SUPERVISOR") ? (
                    <div className="alert alert-success" role="alert">
                      El mantenimiento ya fue aprobado.
                    </div>
                  ) : (
                    <div className="mt-3">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Trabajos realizados</th>
                            {isSupervisor && <th scope="col">Evidencias</th>}
                            {isSupervisor && <th scope="col">Comentarios</th>}
                            {!isSupervisor && <th scope="col">Realizado por</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(groupedCategories).map((categoryKey, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{categoryKey}</td>
                              {isSupervisor && (
                                <>
                                  <td>
                                    {groupedCategories[categoryKey].map((evidence, evidenceIndex) => (
                                      <a
                                        key={evidenceIndex}
                                        href={evidence.file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-outline-primary btn-sm mb-2"
                                        style={{ display: 'block', textAlign: 'center', width: '100%' }}
                                      >
                                        Ver evidencia {evidenceIndex + 1}
                                      </a>
                                    ))}
                                  </td>
                                  <td>
                                    {groupedCategories[categoryKey].map((evidence, evidenceIndex) => (
                                      <div key={evidenceIndex} className="mb-2">
                                        {evidence.value || "Sin comentarios"}
                                      </div>
                                    ))}
                                  </td>
                                </>
                              )}
                              {!isSupervisor && index === 0 && (
                                <td rowSpan={Object.keys(groupedCategories).length} className="text-center align-middle">
                                  {preventiveMaintenance.signature.url ? (
                                    <>
                                      <img
                                        src={preventiveMaintenance.signature.url}
                                        alt="Firma"
                                        style={{ width: "100px" }}
                                      />
                                      <p>
                                        <strong>
                                          {preventiveMaintenance?.technician?.firstName}{" "}{preventiveMaintenance?.technician?.lastName}
                                        </strong>
                                      </p>
                                    </>
                                  ) : (
                                    <div>
                                      Imagen no disponible
                                    </div>
                                  )}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {(preventiveMaintenance?.report?.residentCheck == false && !isSupervisor) 
                    || (preventiveMaintenance?.report?.supervisorCheck == false && isSupervisor) ? (
                    <Formik
                      initialValues={{
                        signature: null,
                        reviewer_name: '',
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <div className="row justify-content-center mt-4">
                            {!isSupervisor && (
                              <div className="col-md-6 col-lg-5">
                                <div style={{ position: "relative", border: "2px solid black", borderRadius: "4px", padding: "5px" }}>
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent"
                                    style={{ position: "absolute", top: "5px", right: "5px" }}
                                    onClick={() => {
                                      setFieldValue("signature", "");
                                      values.signature.clear();
                                    }}
                                  >
                                    <i className="fas fa-eraser"></i>
                                  </button>
                                  <SignatureCanvas
                                    penColor="black"
                                    ref={(ref) => values.signature = ref}
                                    canvasProps={{ width: 270, height: 150 }}
                                  />
                                </div>
                                <Field
                                  type="text"
                                  name="resident_name"
                                  className="form-control mt-2"
                                  placeholder="Nombre de la persona que valida"
                                  required
                                />
                                <Field 
                                  type="email"
                                  name="email_resident"
                                  className="form-control mt-2"
                                  placeholder="Correo electrónico para copia de reporte"
                                  />
                              </div>
                            )}
                          </div>
                          <div className="form-group mt-5 mx-auto col-md-10 text-center">
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="accept"
                                className="form-check-input"
                                required
                              />
                              <label
                                className="form-check-label"
                                style={{ fontSize: "12px" }}
                              >
                                Acepto que la información proporcionada es precisa y certifico que las actividades 
                                de mantenimiento preventivo se han realizado correctamente. Mi aprobación indica 
                                conformidad con las labores realizadas y su cumplimiento según lo establecido.
                              </label>
                            </div>
                          </div>

                          <div className="mt-4">
                            <button
                              type="submit"
                              className="btn btn-success mr-2 col-md-12"
                            >
                              Aprobar
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

ResidentValidationView.propTypes = {
  preventiveMaintenance: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  type_link: PropTypes.string.isRequired,
};

export default ResidentValidationView;
