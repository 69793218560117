import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import View from "components/shippings/Form.view";
import { usePost } from "seed/api";

const ShippingFormSave = ({ setCreated, onClose }) => {

  const formRef = useRef(null);
  const [step, setStep] = useState(1);
  const [hideModal, setHideModal] = useState(true);
  const [hideEditModal, setHideEditModal] = useState(true);
  const [order, setOrder] = useState(undefined);
  const [content, setContent] = useState(undefined);
  const [selectedParts, setSelectedParts] = useState([]);
  const [isEnabled, setIsEnabled] = useState(window.location.pathname == "/shippings/enabled/create" ? "true" : "false");

  const qOrders = useQuery(`{ 
    orders {
      id
      crane {
        id
        craneId
        model
        number
        series
        number
      }
      clientName
      buildingAddress
      status
      metadata
    } 
  }`, "status<>FINISHED");

  const qSuggestions = useQuery(`{
    suggestions {
      order {}
      shipping {}
      quantity
      elevationNumber
      part {
        partId
        name
        um
        images {}
        items {
          name
          description
          comment
          quantity
          number
          reference
        }
      }
    }
  }`, "order.id=" + order?.id, {orderBy: "id"});

  const qCompanies = useQuery(`{
    transportCompanies {
      id
      name
    }
  }`);

  const [callPost, qPost] = usePost("/shippings/create_shipping", {
    onCompleted: () => {
      setCreated(true);
      setStep(3);
      window.location.replace("/shippings");
    },
    onError: () => {
      setCreated(true);
      setStep(3);
    }
  });

  const { orders = [] } = qOrders.data;
  const { suggestions = [] } = qSuggestions.data;
  const { transportCompanies = [] } = qCompanies.data;

  const suggestionsTarget = window.location.pathname.includes("departures") ? "departures" : "entries";
  const orderSuggestions = order?.metadata[suggestionsTarget] ?? [];
  const usedSuggestions = [
    ...new Set(
      orderSuggestions.concat(
        suggestions?.filter(suggestion => selectedParts
          .some(part => suggestion.part.partId == part.partId && suggestion.elevationNumber == part.elevationNumber))
            .map(suggestion => suggestion.part.partId + "|" + suggestion.elevationNumber)??[])
              .filter(Boolean)
    )
  ];

  const onSubmit = (values) => {

    var type = window.location.pathname == "/shippings/entries/create" ? "FROM_BUILDING" : 
        window.location.pathname == "/shippings/enabled/create" ? "TO_BUILDING" : window.location.pathname == "/shippings/departures/create" ? "TO_BUILDING" 
        : values.type;

    if(step === 1) {
      if(!order) alert("Seleccione una orden primero");
      else {
        if(isEnabled === "false") {
          if (window.location.pathname.includes("/shippings/entries")) {
            console.log("ENTRIES");
            const allParts = suggestions.filter(suggestion => !usedSuggestions.includes(suggestion.part.partId + "|" + suggestion.elevationNumber))
              .map(suggestion => ({
                partId: suggestion.part.partId,
                partName: suggestion.part.name,
                um: suggestion.part.um,
                quantity: suggestion.quantity,
                images: suggestion.part.images,
                manual: false,
                suggestRef: suggestion.id,
                elevationNumber: suggestion.elevationNumber,
                items: suggestion.part.items.map(item =>
                  `${item.quantity}|${item.name}|${item.description}|${item.comment}|${item.number}|${item.reference}|${item.id}`).join(";")
            }));
          
            setSelectedParts(allParts);
          }

          setStep(2);
        
        } else if(isEnabled === "true") {
          const allParts = suggestions.map(suggestion => ({
            partId: suggestion.part.partId,
            partName: suggestion.part.name,
            um: suggestion.part.um,
            quantity: suggestion.quantity,
            images: suggestion.part.images,
            manual: false,
            suggestRef: suggestion.id,
            elevationNumber: suggestion.elevationNumber,
            items: suggestion.part.items.map(item =>
              `${item.quantity}|${item.name}|${item.description}|${item.comment}|${item.number}|${item.reference}|${item.id}`).join(";")
          }));
        
          setSelectedParts(allParts);
          setStep(2);
        }
      }
    }

    if(selectedParts.length === 0 && step === 2) {
      alert("No puede haber un embarque sin contenido");
      return;
    }

    if(isEnabled === "false" && step === 2) {
      var entry = window.location.pathname == "/shippings/entries/create" ? true : false;
      callPost({
        ...values,
        contents: selectedParts,
        order_id: order.id,
        is_enabled: false, 
        is_entry: entry,
        type: type,
        used_suggestions: usedSuggestions,
        suggestions_target: suggestionsTarget
      });
    } else if(isEnabled === "true" && step === 2) {
      delete values.company;
      callPost({
        ...values,
        contents: selectedParts,
        order_id: order.id,
        is_enabled: true,
        is_entry: false,
        type: type,
        used_suggestions: usedSuggestions,
        suggestions_target: suggestionsTarget
      });
    }
      
  };

  const onClickRetry = () => {
    setStep(step - 1);
    setCreated(false);
  };
  
  const onPrev = () => 
    setStep(step - 1);

  const onAddPart = (suggestion) => {
    setSelectedParts([...selectedParts, {
      partId: suggestion.part.partId,
      partName: suggestion.part.name,
      um: suggestion.part.um,
      quantity: suggestion.quantity,
      images: suggestion.part.images,
      manual: false,
      suggestRef: suggestion.id,
      elevationNumber: suggestion.elevationNumber,
      items: suggestion.part.items.map(item =>
        item.quantity + "|" + item.name + "|" + item.description + "|" + item.comment + "|" + item.number 
          + "|" + item.reference + "|" + item.id).join(";")
    }]);
  };

  const onRemovePart = (index) =>
    setSelectedParts(selectedParts.filter((_, i) => i !== index));

  const error = qPost.error || qSuggestions.error ? "An error has occurred" : null;

  const cleanedOrders = orders.filter(order => order.crane != null);

  const formattedCranes = cleanedOrders.map((order) => 
    order.crane.model + "" + order.crane.number +"/ " + order.crane.series);

  const filteredSuggestions = suggestions?.filter(suggestion => usedSuggestions
    .every(used => used !== suggestion.part.partId + "|" + suggestion.elevationNumber));

  return <View
    formRef={formRef}
    orders={cleanedOrders}
    order={order}
    currentParts={selectedParts}
    setCurrentParts={setSelectedParts}
    companies={transportCompanies}
    suggestions={filteredSuggestions}
    selectedParts={selectedParts}
    setSelectedParts={setSelectedParts}
    content={content}
    setContent={setContent}
    hideModal={hideModal}
    setHideModal={setHideModal}
    hideEditModal={hideEditModal}
    setHideEditModal={setHideEditModal}
    onAddPart={onAddPart}
    onRemovePart={onRemovePart}
    setOrder={setOrder}
    cranes={formattedCranes}
    error={error}
    onSubmit={onSubmit}
    onClose={onClose}
    step={step}
    setStep={setStep}
    onClickRetry={onClickRetry}
    onPrev={onPrev}
    isEnabled={isEnabled}
    setIsEnabled={setIsEnabled}
  />;

}

ShippingFormSave.propTypes = {
  setCreated: PropTypes.func,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,
};
  
export default ShippingFormSave;