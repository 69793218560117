import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import swal from "sweetalert";
import { usePost } from "seed/api";
import View from "components/arris_binnacle/analytics/ExtraWorkedHoursLineChart.view";

function ExtraWorkedHoursLineChart({ filters, grouped }) {
  const [monthSelected, setMonthSelected] = useState(0);
  const [monthOptions, setMonthOptions] = useState([]);

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const generateMonthOptions = (startMonth, startYear) => {
    const options = [];
    let monthMoment = moment(`${startYear}-${startMonth}-01`, "YYYY-MM-DD");
    for (let i = 0; i < 10; i++) {
      let formattedMonth = monthMoment.format("MMMM [de] YYYY");
      formattedMonth = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
      options.push(formattedMonth);
      monthMoment.add(1, 'months');
    }
    setMonthOptions(options);
  };

  const onMonthChange = (index) => {
    setMonthSelected(parseInt(index, 10));
  };

  const [callData, reqData] = usePost('/arris_binnacles/get_extra_worked_hours', {
    onCompleted: (data) => {
      const activeItems = data.data ? data.data.filter((item) => item.data[0] > 0) : [];
      let labels = [];
      if (grouped == "crane") {
        labels = activeItems.map((item) => item.crane.series + " / " + item.crane.model + "" + item.crane.number)
      } else if (grouped == "order") {
        labels = activeItems.map((item) => item.building)
      } else if (grouped == "client") {
        labels = activeItems.map((item) => item.client)
      }

      setData({
        labels: labels,
        datasets: [{
          label: 'Horas extras trabajadas',
          data: activeItems.map((item) => item.data.length > 0 ? item.data[0] : 0),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }],
      });

      if (monthOptions.length === 0) {
        generateMonthOptions(data.labels[0].month, data.labels[0].year);
      }
    },
    onError: (err) => {
      console.error(err);
      swal("Error", "Error al obtener la informacion, intente de nuevo mas tarde", "error")
    }
  });

  useEffect(() => {
    const now = moment().add(monthSelected, "months");
    callData({
      size: 1,
      filters: filters,
      actual_month: now.month() + 1,
      actual_year: now.year(),
      grouped_by: grouped
    });
  }, [filters, monthSelected, grouped]);

  const loading = reqData.loading;
  return <View 
          data={data} 
          loading={loading} 
          monthOptions={monthOptions} 
          onMonthChange={onMonthChange} 
          initialMonth={monthSelected}/>;
}

ExtraWorkedHoursLineChart.propTypes = {
  filters: PropTypes.object.isRequired,
  grouped: PropTypes.string.isRequired,
};

export default ExtraWorkedHoursLineChart;
