import React, { useState } from "react";
import { usePagination, useDelete } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePut } from "seed/api";
import { DELETE_SECURITY_REVISION } from "seed/gql/queries";
import View from "components/security_revisions/List.view";
import { formatFilters, formatSearchFilters } from "components/utils/filters";
import PropTypes from "prop-types";
import swal from "sweetalert";

const SecurityRevisionList = ({ search, orderDate }) => {

  const pageSize = 18;
  const [pageNum, setPageNum] = useState(1);

  const [ callDelete, reqDelete ] = useDelete(DELETE_SECURITY_REVISION, {
    onCompleted: () => {}
  });

  const securityRevisionQuery =`
  {
    securityRevisionPagination {
      totalPages
      securityRevisions {
        type
        createdAt
        status
        bothId
        securityChecks{
          isDeviation
          files{
            url
          }
        }
        order {
          buildingName
          modelReference
          crane {
            model
            craneId
            series
            number
          }
        }
        crane{
          model
          series
          number
          craneId
        }
      }
    }
  }
  `;

  const reqSecurityRevisions = usePagination(securityRevisionQuery,
    pageNum,
    pageSize,
    formatFilters([
      formatSearchFilters(search, ["order.crane.series", "order.crane.model", "order.buildingName", "crane.series", "crane.model", "crane.number"]),
    ]),
    { orderBy: orderDate + "created_at" }
  );

  if (reqSecurityRevisions.loading) return <Loading />;
  if (reqSecurityRevisions.error) return "Error";

  const { securityRevisions = [], totalPages = 0 } = reqSecurityRevisions.data.securityRevisionPagination;

  const onClickPage = (pageNum) =>
    setPageNum(pageNum);

  const deleteSecurityRevision = (securityId) => {
    let confirm = window.confirm("¿Está seguro de eliminar la revisión? Se eliminarán los, " 
      + "contenidos y evidencias que pertenezcan a esta revisión.");
    if(confirm)
      if(securityId.includes("-")){
        let bothIds = securityId.split("-");
        callDelete({ id: bothIds[0] });
        callDelete({ id: bothIds[1] });
        swal("Revisión eliminada", "Se ha eliminado la revisión", "success").then(() => {
          window.location.reload();
        });
      } else{
        callDelete({ id: securityId });
        swal("Revisión eliminada", "Se ha eliminado la revisión", "success").then(() => {
          window.location.reload();
        });
      }
  } 
  
  const groupedRevisions = securityRevisions.reduce((acc, revision) => {
    const bothId = revision.bothId;
    if (bothId && (bothId !== "" && bothId !== "now")) {
      if (!acc[bothId]) {
        acc[bothId] = { INITIAL: null, FINAL: null };
      }
      acc[bothId][revision.type] = revision;
    } else {
      acc[revision.id] = { [revision.type]: revision };
    }

    return acc;
  }, {});

  const getPercentageColor = (percentage) => {
    const red = Math.min(255, 255 - (percentage * 2.55));
    const green = Math.min(255, (percentage * 2.55));
    return `rgb(${red}, ${green}, 0)`;
  };

  const calculatePercentage = (revision) => {
    const totalChecks = revision?.securityChecks?.filter(check => !check.isDeviation).length || 0;
    const checksWithFiles = revision?.securityChecks?.filter(check => check.files.length > 0).length || 0;
    console.log("revision", revision, "totalChecks", totalChecks, "checksWithFiles", checksWithFiles);
    return totalChecks === 0 ? 0 : Math.round((checksWithFiles / totalChecks) * 100);
  };

  return <View
    pageNum={pageNum}
    orderDate={orderDate}
    totalPages={totalPages}
    onClickPage={onClickPage}
    groupedRevisions={groupedRevisions}
    getPercentageColor={getPercentageColor}
    calculatePercentage={calculatePercentage}
    deleteSecurityRevision={deleteSecurityRevision}
  />;

}

SecurityRevisionList.propTypes = {
  search: PropTypes.string,
  orderDate: PropTypes.string
};

export default SecurityRevisionList;