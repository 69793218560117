import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Sync from "components/comercial/crm/Sync";
import Upload from "components/comercial/crm/Upload";
import ModalRoute from "components/helpers/ModalRoute";
import List from "components/comercial/crm/List";
import { Typeahead } from "react-bootstrap-typeahead";

const CrmView = ({
  models,
  filters,
  onClose,
  orderDate,
  setOrderDate,
  handleChange,
  onChangeStatus
}) => (
  <BrowserRouter basename="/commercial/crm">

    <div class="content container-fluid p-7" style={{ maxHeight: "100vh", overflow: "auto" }}>

      {/* Header */}
      <div class="page-header pt-4">
        <div class="row align-items-end">
          <div class="col-sm">
            <h1 class="page-header-title">Módulo CRM</h1>
          </div>
          <div class="col-sm-auto">
            <div class="btn-group" role="group">
              <Link to="/create" className="btn btn-primary mx-3">
                <i class="fas fa-arrow-alt-circle-up mr-1"></i> Sincronizar datos
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 mx-1">
        <div className="d-flex justify-content-between">

          <div style={{ width: "35%" }}>
            <h3>Grúas</h3>
          </div>

          <div className="d-flex justify-content-end" style={{ width: "65%" }}>
            <div className="form-group mr-3">
              <select
                id="searchBySelect"
                className="form-control"
                onChange={(event) => handleChange(event.target.value, "model")}
              >
                <option value="">Todos los modelos</option>
                {
                  models.map((model, idx) => (
                    <option key={idx} value={model}>{model}</option>
                  ))
                }
              </select>
            </div>
          </div>

        </div>
      </div>

      <div className="row border font-weight-bold mx-1">

        <div className="col-md-1 p-2">
          Modelo de grúa
        </div>

        <div className="col-md-1 p-2">
          Identificador CRM
        </div>

        <div className="col-md-2 p-2">
          Cliente
        </div>

        <div className="col-md-2 p-2">
          Obra
        </div>

        <div className="col-md-1 p-2">
          Estado
        </div>

        <div className="col-md-1 p-2">
          <div className="d-flex flex-row align-items-center" style={{ marginTop: "-10px", marginBottom: "-10px" }}>
            <span>Fecha</span>
            <div className="mx-1">
              <button type="button" className={`btn btn-text mx-1 px-0 ${orderDate == "" ? "text-primary" : ""}`}
                onClick={() => setOrderDate("")}
              >
                <i className="fa fa-arrow-up"></i>
              </button>
              <button type="button" className={`btn btn-text mx-1 px-0 ${orderDate == "-" ? "text-primary" : ""}`}
                onClick={() => setOrderDate("-")}
              >
                <i className="fa fa-arrow-down"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-2 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}
            >
              Estatus
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                <div class="dropdown-item">
                  <input type="checkbox" id="status_closed" name="status_closed"
                    defaultChecked={filters?.status?.["CLOSED"] ?? false}
                    onChange={(e) => onChangeStatus("CLOSED", e.target.checked)} />
                  <label className="px-2 align-middle" for="status_closed">
                    <h5>Cerrado</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input type="checkbox" id="status_waiting" name="status_waiting"
                    defaultChecked={filters?.status?.["WAITING"] ?? false}
                    onChange={(e) => onChangeStatus("WAITING", e.target.checked)} />
                  <label className="px-2 align-middle" for="status_waiting">
                    <h5>En espera</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input type="checkbox" id="status_negotiation" name="status_negotiation"
                    defaultChecked={filters?.status?.["NEGOTIATION"] ?? false}
                    onChange={(e) => onChangeStatus("NEGOTIATION", e.target.checked)} />
                  <label className="px-2 align-middle" for="status_negotiation">
                    <h5>En negociación</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input type="checkbox" id="status_sync" name="status_sync"
                    defaultChecked={filters?.status?.["SYNC"] ?? false}
                    onChange={(e) => onChangeStatus("SYNC", e.target.checked)} />
                  <label className="px-2 align-middle" for="status_sync">
                    <h5>Sincronizado</h5>
                  </label>
                </div>

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2">
          Acciones
        </div>

      </div>

      <List
        filters={filters}
        orderDate={orderDate}
      />

    </div>

    <ModalRoute
      path="/sync/:crmId"
      width="1000"
      height="600"
      onClose={onClose}
      component={Sync}
    />

    <ModalRoute
      path="/create"
      width="800"
      height="600"
      onClose={onClose}
      component={Upload}
    />

  </BrowserRouter>
);

CrmView.propTypes = {
  models: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  orderDate: PropTypes.string.isRequired,
  setOrderDate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired
};

export default CrmView;