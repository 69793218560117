import React from "react";
import PropTypes from "prop-types";
import NotResults from "components/helpers/NotResults";
import { Link } from "react-router-dom";
import { mapCraneOwner, } from "components/utils/enum_mapper";

const CraneList = ({ cranes }) =>
  <>
    {
      cranes.length == 0
        ? <NotResults />
        : <>
          <div style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }}>
            {cranes.map((crane, idx) =>
              <div
                key={crane.id}
                className="row border mx-1"
                style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}
              >
                <div className="col-md-3 p-2">
                  <b>{crane.series}</b>{" / " + crane.model + "" + crane.number}
                </div>

                <div className="col-md-3 p-2 text-center">
                  {mapCraneOwner(crane.owner)}
                </div>

                <div className="col-md-6 p-2">
                  <Link className="btn btn-primary" to={`/download/${crane.id}`}>
                    Descargar Reporte
                  </Link>
                </div>

              </div>
            )}
          </div>
        </>
    }
  </>;

CraneList.propTypes = {
  cranes: PropTypes.array,
  onDelete: PropTypes.func,
};

export default CraneList;