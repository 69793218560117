import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import View from "components/arris/binnacles_validation/Validation.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost, useGetCall } from "seed/api";

function Validation() {

    const[validation, setValidation] = useState(false)
    const[check, setCheck] = useState(false)
    const[isAuth, setIsAuth] = useState(false);

    const searchParams = new URLSearchParams(document.location.search)
    var validation_id = searchParams.get('validation_id')
    
    const [callPost, qPost] = usePost("/binnacle_tokens/validate_hours", {
        onCompleted: () => { 
            swal("¡Listo!", "Horas revisadas, gracias por verificar la información.", "success").then(() => {
                window.location.reload();
            });
        },
        onError: (error) => { },
    });
    
    const qBinnacleInfo = useQuery(
        `{
            arrisBinnacles{
                date
                hoursWorked
                eatHours
                failuresHours
                status
                operator{
                    user{
                        firstName
                    }
                    order{
                        buildingName
                        clientName
                        crane{
                            model
                            number
                            series
                        }
                    }
                }
            }
        }`,
        "token_id = " + validation_id
    );

    const qBinnacleReviewer = useQuery(
        `{
            binnacleTokens{
                reviewerName
            }
        }`,
        "id = " + validation_id
    );

    if(searchParams.has("token") && searchParams.has("user_id")) {
        sessionStorage.setItem("token", searchParams.get("token"));
        sessionStorage.setItem("id", searchParams.get("user_id"));
    }

    const [callAuth, reqCall] = useGetCall("/auth/user", "", {
        onCompleted: (data) => setIsAuth(true),
        onError: () => {}
    });

    useEffect(() => {
        callAuth();
    }, []);

    if(!isAuth) return null;

    if (qBinnacleInfo.loading) return <Loading />;
    if (qBinnacleInfo.error) return "Error";

    if (qBinnacleReviewer.loading) return <Loading />;
    if (qBinnacleReviewer.error) return "Error";
    
    const { arrisBinnacles = [] } = qBinnacleInfo.data;
    const binnacleReviewer = qBinnacleReviewer.data.binnacleTokens[0].reviewerName;

    const allApprovedOrRejected = arrisBinnacles.every(
        (arrisBinnacle) =>
          arrisBinnacle.status === "APPROVED" || arrisBinnacle.status === "REJECTED"
    );

    const handleButtonClick = (value) => {
        setValidation(value);
    };

    const changeCheck = () => {
        setCheck(!check);
    };

    const onSubmit = (values, actions) => {
        const signatureDataUrl = values.signature ? values.signature.toDataURL() : null;
      
        callPost(
          {
            token_id: validation_id,
            status: validation,
            reviewer_name: values.reviewer_name,
            signature: signatureDataUrl,  
          },
        );
      }

    return <View 
                binnacleReviewer={binnacleReviewer}
                arrisBinnacles={arrisBinnacles}
                check={check}
                onSubmit={onSubmit}
                changeCheck={changeCheck}
                handleButtonClick={handleButtonClick}
                allApprovedOrRejected={allApprovedOrRejected}
            />;
}

Validation.propTypes = {};

export default Validation;