import React, { useState, useRef } from "react";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/preventive_maintenances/PreventiveMaintenances.view";

const PreventiveMaintenances = () => {

    const pathname = window.location.pathname;
    
    const searchRef = useRef(null);
    const [search, setSearch] = useState("");
    const [searchBy, setSearchBy] = useState("Grua");

    const qCranes = useQuery(`{
        cranes {
          series
          number
          model
        }
    }`, "in_use=true");

    const qOrders = useQuery(`{
        orders {
          buildingName
          clientName
          applicant {
            id
            firstName
            lastName
          }
        }
    }`);

    if(qCranes.loading) return <Loading/>;
    if(qCranes.error) return <Error/>;

    if(qOrders.loading) return <Loading/>;
    if(qOrders.error) return <Error/>;
    
    const { cranes } = qCranes.data;
    const { orders } = qOrders.data;

    const handleChange = (value) =>
        setSearch(value);

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
        handleChange("");
    }

    return <View
        cranes={cranes}
        orders={orders}
        search={search}
        searchBy={searchBy}
        pathname={pathname}
        searchRef={searchRef}
        handleChange={handleChange}
        handleSearchByChange={handleSearchByChange}
    />;
}

PreventiveMaintenances.propTypes = {};

export default PreventiveMaintenances;
