import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import View from "components/shippings/Form.view";
import { usePost } from "seed/api";
import NotFound from "components/helpers/NotFound";
import { Loading } from "seed/helpers";

const ShippingFormSet = ({
  onCompleted = () => null,
  onError = () => null,
  onClose,
  setCreated,
  location,
}) => {

  const [step, setStep] = useState(1);
  const [hideModal, setHideModal] = useState(true);
  const [hideEditModal, setHideEditModal] = useState(true);
  const [content, setContent] = useState(undefined);
  const [isEnabled, setIsEnabled] = useState("false");
  const [selectedParts, setSelectedParts] = useState(
    location.state.shipping.contents
  );

  const [callPost, qPost] = usePost("/shippings/update_shipping", {
    onCompleted: (data) => {
      setCreated(true);
      setStep(3);
      window.location.href = "/shippings";
    },
    onError: (error) => {
      setCreated(true);
      setStep(3);
    },
  });

  const qCompanies = useQuery(`{
    transportCompanies {
      id
      name
    }
  }`);

  const qOrders = useQuery(`{
    orders {
      suggestions {
        id
        elevationNumber
        shipping {
          id
        }
        quantity
        part {
          partId
          name
          um
          images {
            id
          }
          items {
            name
            description
            comment
            quantity
          }
        }
      }
      crane {
        id
        craneId
        model
        number
        series
        number
      }
      clientName
      buildingAddress
      metadata
    }
  }`, "id = " + location.state.shipping.order.id);

  if (qOrders.loading) return <Loading />;
  if (qOrders.error) return "Error";

  if (qCompanies.loading) return <Loading />;
  if (qCompanies.error) return "Error";

  const order = qOrders.data.orders[0];
  const transportCompanies = qCompanies.data.transportCompanies;

  const suggestionsTarget = window.location.pathname.includes("departures") ? "departures" : "entries";
  const orderSuggestions = order?.metadata[suggestionsTarget] ?? [];
  const usedSuggestions = [
    ...new Set(
      orderSuggestions.concat(
        order?.suggestions?.filter(suggestion => selectedParts
          .some(part => suggestion.part.partId == part.partId && suggestion.elevationNumber == part.elevationNumber))
            .map(suggestion => suggestion.part.partId + "|" + suggestion.elevationNumber)??[])
              .filter(Boolean)
    )
  ];

  if (!location.state || !location.state.shipping) {
    return <NotFound />;
  }

  const error = qPost.error ? "An error has occurred" : null;

  const onSubmit = (values) => {

    if(selectedParts.length === 0 && step === 2) {
      alert("No puede haber un embarque sin contenido");
      return;
    }

    if (step === 1) {
      setStep(2);
      return;
    }

    if (step === 2)
      callPost({
        ...values,
        contents: selectedParts,
        order_id: location.state.shipping.order.id,
        shipping_id: location.state.shipping.id,
        used_suggestions: usedSuggestions,
        suggestions_target: suggestionsTarget
      });
  };

  const onClickRetry = () => {
    setStep(step - 1);
    setCreated(false);
  };

  const onPrev = () => setStep(step - 1);

  const onAddPart = (suggestion) => {
    setSelectedParts([
      ...selectedParts,
      {
        partId: suggestion.part.partId,
        partName: suggestion.part.name,
        um: suggestion.part.um,
        quantity: suggestion.quantity,
        images: suggestion.part.images,
        suggestRef: parseInt(suggestion.id),
        elevationNumber: suggestion.elevationNumber,
        manual: false,
        items: suggestion.part.items
          .map(
            (item) =>
              item.quantity +
              "|" +
              item.name +
              "|" +
              item.description +
              "|" +
              item.comment
          )
          .join(";"),
      },
    ]);
  };

  const onRemovePart = (index) =>
    setSelectedParts(selectedParts.filter((_, i) => i !== index));

  
  const filteredSuggestions = order?.suggestions?.filter(suggestion => usedSuggestions
    .every(used => used !== suggestion.part.partId + "|" + suggestion.elevationNumber));

  const cleanedOrders = [order].filter((order) => order.crane != null);

  const formattedCranes = cleanedOrders.map(
    (order) =>
      order.crane.model + "" + order.crane.number + "/ " + order.crane.series
  );

  return (
    <View
      orders={cleanedOrders}
      order={order}
      shipping={location.state.shipping}
      currentParts={selectedParts}
      setCurrentParts={setSelectedParts}
      suggestions={filteredSuggestions}
      selectedParts={selectedParts}
      setSelectedParts={setSelectedParts}
      content={content}
      setContent={setContent}
      hideModal={hideModal}
      setHideModal={setHideModal}
      hideEditModal={hideEditModal}
      setHideEditModal={setHideEditModal}
      onAddPart={onAddPart}
      onRemovePart={onRemovePart}
      cranes={formattedCranes}
      companies={transportCompanies}
      error={error}
      onSubmit={onSubmit}
      onClose={onClose}
      step={step}
      setStep={setStep}
      onClickRetry={onClickRetry}
      onPrev={onPrev}
      isEnabled={isEnabled}
    />
  );
};

ShippingFormSet.propTypes = {
  setCreated: PropTypes.func,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  location: PropTypes.object,
  onClose: PropTypes.func,
};

export default ShippingFormSet;
