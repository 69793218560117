import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const getActualWeek = () => {

  const now = new Date();
  const startDate = dayjs(now);

  let dates = [];
  const start_date = startDate.toISOString().split('T')[0];
  const month = parseInt(start_date.split("-")[1]);
  const year = parseInt(start_date.split("-")[0]);

  let startMonth = moment.utc(`01-${month}-${year}`, "DD-MM-YYYY");
  let endMonth = moment.utc(startMonth).endOf('month');

  let daysToMonday = startMonth.day() - 1;
  let actualDate = startMonth.clone().subtract(daysToMonday, 'days');

  while (actualDate <= endMonth) {
    const start = actualDate.clone();
    actualDate.add(6, 'days');
    const end = actualDate.clone();
    actualDate.add(1, 'days');
    dates.push({start: start, end: end});
  }

  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    if (moment(now) >= date.start && moment(now) <= date.end) {
      return `${i}`;
    }
  }

  return "";
}


const DownloadView = ({ frecuency, onSubmit, generateWeekOfMonth }) =>
  <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
    <div class="card h-100 px-3 py-2">

      <div class="card-header">
        <div className="d-flex align-items-center justify-content-start w-100">
          <h3 class="card-header-title">
            Descargar reporte {frecuency == "monthly" ? "mensual" : "semanal"}
          </h3>
        </div>
      </div>

      <div class="card-body pt-2">
        <div class="row">
          <div class="col">

            <Formik
              initialValues={{ start_date: dayjs(new Date()), week: getActualWeek() }}
              onSubmit={onSubmit}>
              {({ values, setFieldValue }) =>
                <Form>

                  <div class="mb-3 w-100">
                    <div class="form-group form-group-default required mt-3 mb-3">
                      <label class="control-label">Selecciona el mes</label>
                      <DatePicker
                        views={['month', 'year']}
                        format="MM/YYYY"
                        value={values.start_date}
                        clearable={true}
                        style={{ width: '100%' }}
                        onChange={(date) => setFieldValue("start_date", date)}
                        sx={{
                          width: '95%',
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            margin: '-10px',
                            padding: '-10px'
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            margin: '0',
                            padding: '0'
                          },
                          '& .MuiInputBase-input': {
                            fontSize: '15px'
                          }
                        }}
                      />
                      <div class="invalid-feedback">Required</div>
                    </div>
                  </div>

                  {frecuency == "weekly" && (
                    <div class="mb-5 w-100">
                      <div class="form-group form-group-default required">
                        <label class="control-label mb-2">Seleccione la semana</label>
                        <Field as="select" className="form-control mb-2" name="week" required>
                          <option value="">Seleccione una semana</option>
                          {generateWeekOfMonth(values.start_date).map((item, idx) => (
                            <option key={idx} value={idx}>{item}</option>
                          ))}
                        </Field>
                        <div class="invalid-feedback">Required</div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-block btn-primary">
                        Descargar
                      </button>
                    </div>
                  </div>

                </Form>}
            </Formik>

          </div>
        </div>
      </div>

    </div>
  </LocalizationProvider>;

DownloadView.propTypes = {};

export default DownloadView;