/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import moment from "moment";
import { MultiField, FileField } from "seed/helpers";
import { Typeahead } from "react-bootstrap-typeahead";

const MaintenanceFormView = ({ group={}, cranes = [], onSubmit, error }) =>
    <div class="card">

        {/* Header */}
        <div class="card-header">
            <h3 class="card-header-title">Nuevo grupo de piezas</h3>
        </div>

        {/* Body */}
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <Formik
                        initialValues={group}
                        onSubmit={onSubmit}>
                        {({ values, setFieldValue }) =>
                            <Form>
                                <div class="mb-3">
                                    {/* Crane */}
                                    <div class="form-group">
                                        <div>
                                            <label class="input-label">Modelo de Grúa</label>
                                            {/* The React Typeahead library require selected[0] */}
                                            <Typeahead
                                                id="menu"
                                                placeholder="Buscar Grúas"
                                                labelKey={(crane) => `${crane?.series} - ${crane.model} ${crane.number}`}
                                                onChange={(selected) => selected.length > 0 ? setFieldValue("crane", selected[0].id) : ""}
                                                options={cranes}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div class="row mb-3">

                                        <div className="col-md-12">
                                            <div class="form-group form-group-default required">
                                                <label class="control-label">Nombre</label>
                                                <Field type="text" name="name" class="form-control" required />
                                                <div class="invalid-feedback">Required</div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div class="form-group form-group-default required">
                                                <label class="control-label">Descripción</label>
                                                <textarea type="textarea" required name="description" class="form-control" 
                                                    onChange={(event) => setFieldValue("description", event.target.value)}/>
                                                <div class="invalid-feedback">Required</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                                <button type="submit" class="btn btn-block btn-primary">Crear grupo de piezas</button>
                            </Form>}
                    </Formik>
                </div>
            </div>
        </div>

    </div>;

MaintenanceFormView.propTypes = {
    maintenance: PropTypes.object,
    cranes: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
};

export default MaintenanceFormView;