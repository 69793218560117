import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import View from "components/security_stock/Form.view";
import swal from 'sweetalert';

const FormSave = () => {

    const [callSave, qSave] = usePost("/stock_groups/create_stock_group", {
        onCompleted: () => {
            swal("¡Éxito!", "Grupo de piezas creado", "success").then(() => window.location.href = "/security_stock");
        },
        onError: () => {
            swal("¡Error!", "Ocurrió un error al crear el grupo de piezas", "error");
        }
      });

    const qCranes = useQuery(`{
        cranes {
            id
            series
            number
            model
        }
    }`, "in_use=true");

    if(qCranes.loading) return <Loading/>;
    if(qCranes.error) return "Error";

    const { cranes } = qCranes.data;

    const onSubmit = (values) => {

        if (!values.crane) swal("¡Advertencia!", "Debe seleccionar una grúa", "warning");
        else {
            callSave(values);
        }

    }

    return <View cranes={cranes} onSubmit={onSubmit} />;
}

FormSave.propTypes = {};

export default FormSave;