import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/arris_binnacle/reports/Report.view";

const Report = ({ frecuency }) => {

  const rol = sessionStorage.getItem("rol")
  if (!rol.startsWith("ADMIN")) window.location.replace("/login");

  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    family: "all",
    owner: {
      "GROKE": true,
      "RENTED": true,
      "SOLD": true
    }
  });

  const qCranes = useQuery(`{
    cranes {
      inUse
      status
      model
      series
      number
    }
  }`, "in_use=true");

  if (qCranes.loading) return <Loading />;
  if (qCranes.error) return <Error />;

  const cranes = qCranes.data.cranes;

  const handleChange = (value) => {
    setSearch(value);
  }

  const onSelectFamilyTab = (family) => {
    let newFilters = { ...filters };
    newFilters.family = family == "Todos" ? "all" : family;
    setFilters(newFilters);
  }

  const onChangeOwners = (owner, checked) => {
    let newFilters = { ...filters };
    newFilters.owner[owner] = checked;
    setFilters(newFilters);
  }

  return <View
    cranes={cranes}
    search={search}
    filters={filters}
    frecuency={frecuency}
    onChangeOwners={onChangeOwners}
    handleChange={handleChange}
    searchRef={searchRef}
    onSelectFamilyTab={onSelectFamilyTab}
  />;

}

Report.propTypes = {
  frecuency: PropTypes.string.isRequired
};

export default Report;