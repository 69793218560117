import React from "react";
import PropTypes from "prop-types";
import View from "components/information/Information.view";

function Information() {
  return <View />;
}

Information.propTypes = {};

export default Information;