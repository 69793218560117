import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PaginationFooter } from "seed/helpers";
import { mapPreventiveMaintenanceColor } from "components/utils/enum_mapper";

const ListDoneView = ({ 
    preventiveMaintenances, 
    orderDate, 
    pageNum,
    totalPages,
    onClickPage,
    setOrderDate,
    generatePDF,
    zoneFilters,
    setZoneFilters
 }) => (
    <div className = "col-md-12">
        <div className="row border font-weight-bold mx-1">
            <div className="col-md-2 p-2">
                Grúa
            </div>
            <div className="col-md-2 p-2">
                Obra
            </div>
            <div className="col-md-1 p-2">
                <div className="d-flex flex-row align-items-center" style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                    <span>Fecha</span>
                    <div className="mx-1">
                        <button type="button" className={`btn btn-text mx-1 px-0 ${orderDate == "" ? "text-primary" : ""}`}
                            onClick={() => setOrderDate("")}>
                            <i className="fa fa-arrow-up"></i>
                        </button>
                        <button type="button" className={`btn btn-text mx-1 px-0 ${orderDate == "-" ? "text-primary" : ""}`}
                            onClick={() => setOrderDate("-")}>
                            <i className="fa fa-arrow-down"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-2 p-2 text-center">
                Técnico
            </div>
            <div className="col-md-2 p-2 text-center">
                <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px" }}>

                    <a class="btn btn-link dropdown-toggle"
                        href="#"
                        role="button"
                        id="statusFilterDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: "#677788" }}
                    >
                        Zona
                    </a>
                    <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
                        <form>
                            <div class="dropdown-item">
                                <input type="checkbox" id="filter1" name="filter1" defaultChecked={zoneFilters["NORTH"]}
                                    onChange={(e) => setZoneFilters({ ...zoneFilters, "NORTH": e.target.checked })} />
                                <label for="filter1">Norte</label>
                            </div>
                            <div class="dropdown-item">
                                <input type="checkbox" id="filter2" name="filter2" defaultChecked={zoneFilters["SOUTH"]}
                                    onChange={(e) => setZoneFilters({ ...zoneFilters, "SOUTH": e.target.checked })} />
                                <label for="filter2">Sur</label>
                            </div>
                            <div class="dropdown-item">
                                <input type="checkbox" id="filter3" name="filter3" defaultChecked={zoneFilters["SHALLOWS"]}
                                    onChange={() => setZoneFilters({ ...zoneFilters, "SHALLOWS": !zoneFilters["SHALLOWS"] })} />
                                <label for="filter3">Bajío</label>
                            </div>
                            <div class="dropdown-item">
                                <input type="checkbox" id="filter4" name="filter4" defaultChecked={zoneFilters["CENTRE"]}
                                    onChange={() => setZoneFilters({ ...zoneFilters, "CENTRE": !zoneFilters["CENTRE"] })} />
                                <label for="filter4">Centro</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-md-2 p-2 text-center">
                Estado
            </div>
            <div className="col-md-1 p-2">
            </div>
        </div>

        {preventiveMaintenances.length == 0 ? <div className="col-md-12 text-center h5 mt-2"> No hay resultados </div> 
        :
            <div style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                {preventiveMaintenances.map((preventiveMaintenance, idx) =>
                    <div className="row border mx-1 text-reset" key={preventiveMaintenance.id}
                        style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}
                    >
                        <div className="col-md-2 p-2">
                            {preventiveMaintenance.order.crane.series + " / " + preventiveMaintenance.order.crane.model + preventiveMaintenance.order.crane.number}
                        </div>
                        <div className="col-md-2 p-2">
                            {preventiveMaintenance.order.buildingName}
                        </div>
                        <div className="col-md-1 p-2">
                            {(new Date(preventiveMaintenance.finishDate)).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                        </div>
                        <div className="col-md-2 p-2" align="center">
                            {preventiveMaintenance.technician.firstName + " " + preventiveMaintenance.technician.lastName}
                        </div>
                        <div className="col-md-2 p-2" align="center">
                            <select
                                className="custom-badge-select border-0"
                                style={mapPreventiveMaintenanceColor(preventiveMaintenance.zone)}
                                defaultValue={preventiveMaintenance.zone}
                            >
                                {preventiveMaintenance.zone == "NORTH" && <option value="NORTH">Norte</option>}
                                {preventiveMaintenance.zone == "SOUTH" && <option value="SOUTH">Sur</option>}
                                {preventiveMaintenance.zone == "SHALLOWS" && <option value="SHALLOWS">Bajío</option>}
                                {preventiveMaintenance.zone == "CENTRE" && <option value="CENTRE">Centro</option>}
                            </select>
                        </div>
                        <div align="center" className={preventiveMaintenance.status == "FINISHED" ? "col-md-2 p-2 text-warning" : "col-md-2 p-2 text-success"}>
                            {preventiveMaintenance.status == "FINISHED" ? "Finalizado" : "Aprobado"}
                        </div>
                        <div className="col-md-1 p-2" align="center">
                            <div className="d-flex justify-content-center">
                                <div className="text-info" onClick={() => generatePDF(preventiveMaintenance)} style={{ cursor: "pointer" }}>
                                    <i className="fa fa-file-download"></i>
                                </div>
                                <Link to={{ pathname: `/${preventiveMaintenance.id}` }} className="text-warning ml-2">
                                    <i className="fa fa-clipboard"></i>
                                </Link>
                                <a href={preventiveMaintenance?.onedriveUrl} 
                                    className={preventiveMaintenance?.onedriveUrl ? "text-primary ml-2" : "text-secondary ml-2"} 
                                    target="_blank" rel="noopener noreferrer" style={preventiveMaintenance?.onedriveUrl ? {} : { pointerEvents: "none", cursor: "not-allowed" }}>
                                    <i className="fas fa-cloud" />
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
        <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />
    </div>
);

ListDoneView.propTypes = {
    preventiveMaintenances: PropTypes.array.isRequired
};

export default ListDoneView;