import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetCall } from "seed/api";
import View from "components/comercial/schedule/ScheduleEmbedded.view";

function ScheduleEmbedded(props) {

  const [isAuth, setIsAuth] = useState(false);
  const params = new URLSearchParams(props.location.search);
  
  if(params.has("token") && params.has("user_id")) {
    sessionStorage.setItem("token", params.get("token"));
    sessionStorage.setItem("id", params.get("user_id"));
  }

  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => setIsAuth(true),
    onError: () => {}
  });

  useEffect(() => {
    callAuth();
  }, []);

  if(!isAuth) return null;
  return <View />
}

ScheduleEmbedded.propTypes = {
  location: PropTypes.object.isRequired
};

export default ScheduleEmbedded;