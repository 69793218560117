import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/comercial/analytics/List.view";

// function List({ data, isMobile }) {

//   const [showDetails, setShowDetails] = useState(false);
//   const [cranes, setCranes] = useState([]);
//   const [type, setType] = useState("");
//   const [actualMonth, setActualMonth] = useState(1);
//   const [actualYear, setActualYear] = useState(1);

//   const onClose = () => {
//     setShowDetails(false);
//     setCranes([]);
//   }

//   const openModal = (selectedCranes, selectedType, month, year) => {
//     setCranes(selectedCranes);
//     setType(selectedType);
//     setActualMonth(month);
//     setActualYear(year)
//     setShowDetails(true);
//   }

//   return (
//     <View
//       data={data}
//       type={type}
//       cranes={cranes}
//       isMobile={isMobile}
//       actualMonth={actualMonth}
//       actualYear={actualYear}
//       showDetails={showDetails}
//       onClose={onClose}
//       openModal={openModal}
//     />
//   );
// }

function List({ filters = {}, isMobile = false }) {

  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState([]);
  const [cranes, setCranes] = useState([]);
  const [type, setType] = useState("");
  const [actualMonth, setActualMonth] = useState(1);
  const [actualYear, setActualYear] = useState(1);

  const [callAnalytics, reqAnalytics] = usePost("/schedules/analytics", {
    onCompleted: (data) => {
      setData(data)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  useEffect(() => {
    callAnalytics({
      filters: filters,
      start_month: new Date().getMonth() + 1,
      start_year: new Date().getFullYear(),
      size: 12
    })
  }, [filters])

  if (reqAnalytics.loading) return <Loading />;
  if (reqAnalytics.error) return <Error />;

  const onClose = () => {
    setShowDetails(false);
    setCranes([]);
  }

  const openModal = (selectedCranes, selectedType, month, year) => {
    setCranes(selectedCranes);
    setType(selectedType);
    setActualMonth(month);
    setActualYear(year)
    setShowDetails(true);
  }

  return (
    <View
      data={data}
      type={type}
      cranes={cranes}
      isMobile={isMobile}
      actualMonth={actualMonth}
      actualYear={actualYear}
      showDetails={showDetails}
      onClose={onClose}
      openModal={openModal}
    />
  );
}

List.propTypes = {
  data: PropTypes.array.isRequired
};

export default List;